import { BEFORE_SETTINGS, SET_SUBSCRIBE, GET_SETTINGS } from '../../../redux/types';

const initialState = {
    settings: null,
    settingsAuth: false,
    subscribe: null,
    subscribeAuth: false
}

export default function footerRed(state = initialState, action) {
    switch (action.type) {
        case GET_SETTINGS:
            return {
                ...state,
                settings: action.payload,
                settingsAuth: true
            }
        case SET_SUBSCRIBE:
            return {
                ...state,
                subscribe: action.payload,
                subscribeAuth: true
            }
        case BEFORE_SETTINGS:
            return {
                ...state,
                settings: null,
                settingsAuth: false,
                subscribe: null,
                subscribeAuth: false
            }
        default:
            return {
                ...state
            }
    }
}