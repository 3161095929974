import { combineReducers } from 'redux'
import homeReducer from './../components/homepage/home.reducer'
import auctionReducer from './../components/auctions/auctions.reducer'
import nftReducer from './../components/nfts/nfts.reducer'
import userReducer from './../components/user/user.reducer'
import chatReducer from './../components/conversation/chat.reducer'
import walletReducer from './../components/wallet/wallet.reducer'
import categoryReducer from './../components/categories/categories.reducer'
import collectionReducer from './../components/collections/collections.reducer'
import errorReducer from './shared/error/error.reducer'
import settingsReducer from '../components/home/footer/footer.reducer'
import faqReducer from '../components/faq/faq.reducer'
import offersReducer from '../components/offers/offers.reducer'
import bidsReducer from '../components/bids/bids.reducer'
// import navBarReducer from '../components/home/navBar/navBar.reducer'
// import ImagibleItemsReducer from '../components/home/ImagibleItems/ImagibleItems.reducer'
import contactsReducer from '../components/contact/contact.reducer'
import activityReducer from '../components/activity/activity.reducer'
import rankingsReducer from '../components/rankings/rankings.reducer'
import earningsReducer from '../components/earnings/earnings.reducer'
import appReducer from '../App.reducer'
// import bannerReducer from '../components/home/bannersection/BannerSection.reducer'
import integrationReducer from '../components/integration/integration.reducer'
import nftHistoryReducer from '../components/history/history.reducer'
import attributesReducer from '../components/attributes/attributes.reducer'
import listingsReducer from '../components/listings/listings.reducer'

export default combineReducers({
    home: homeReducer,
    auction: auctionReducer,
    nft: nftReducer,
    user: userReducer,
    chat: chatReducer,
    wallet: walletReducer,
    category: categoryReducer,
    collection: collectionReducer,
    error: errorReducer,
    settings: settingsReducer,
    faqs: faqReducer,
    offer: offersReducer,
    bid: bidsReducer,
    // navBar: navBarReducer,
    // explore: ImagibleItemsReducer,
    contact: contactsReducer,
    activities: activityReducer,
    rankings: rankingsReducer,
    earnings: earningsReducer,
    app: appReducer,
    // banner: bannerReducer,
    integration: integrationReducer,
    nftHistory: nftHistoryReducer,
    attributes: attributesReducer,
    listings: listingsReducer
})