/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import FullPageLoader from '../../components/full-page-loader/full-page-loader';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { beforeAuction, getLiveAuctions } from './auctions.action';
import { ENV } from '../../config/config';
import Countdown from 'react-countdown';
import ReactTooltip from 'react-tooltip'
import { ipfsToUrl } from "../../utils/functions";
import {
    getChainId,
} from './../../utils/web3'
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-solid-svg-icons'
import bnb from '../../assets/images/binance.svg';
import pixul from '../../assets/images/pixul.svg';
import cro from '../../assets/images/cronos.svg';

const { countDownRenderer, globalPlaceholderImage } = ENV

const initData = {
    pre_heading: "Auctions",
    heading: "Live Auctions",
    content: "NFT art is sold, and buyers can purchase it using cryptocurrency",
    btn_1: "Load More"
}

const LiveAuctions = (props) => {
    const [pagination, setPagination] = useState(null);
    const [auctions, setAuctions] = useState(null)
    const [loader, setLoader] = useState(true) // auctions loader
    const [loadMoreBtn, setLoadMoreBtn] = useState(false);
    const [change, setChange] = useState(false);
    const [chainId, setChainId] = useState('');

    useEffect(() => {
        getChainId().then(setChainId)
    }, [])

    useEffect(() => {
        if (chainId)
            initData.content += ` such as ${ENV.currency[chainId]}.`
    }, [chainId])

    useEffect(() => {
        window.scroll(0, 0)
        // get live auctions
        getLiveAuctions()
        setChange(prevState => !prevState)
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    // set live auctions
    useEffect(() => {
        if (props.auction.getAuth) {
            const { auctions, pagination } = props.auction
            if (auctions) {
                setAuctions(auctions)
                setPagination(pagination)
                props.beforeAuction()
                setLoader(false)
            }
        }
    }, [props.auction.getAuth, change]) // eslint-disable-line react-hooks/exhaustive-deps

    // handle show load more button state when pagination is set
    useEffect(() => {
        if (pagination)
            setLoadMoreBtn((pagination.total > 0 && pagination.total > auctions.length && auctions.length > 0))
    }, [pagination]) // eslint-disable-line react-hooks/exhaustive-deps

    const loadMore = () => {
        const { page, limit } = pagination
        setLoader(true)

        // get more live auctions
        getLiveAuctions(1, limit * (page + 1))
    }

    const getLiveAuctions = (page = 1, limit = 12) => {
        const qs = ENV.objectToQueryString({ page, limit })
        props.getLiveAuctions(qs)
    }

    const getLeftDate = (date) => {
        let time = moment(date).fromNow(true);
        return (time + ' Left')
    }

    return (
        <section className="live-auctions-area load-more">
            {
                loader ?
                    <FullPageLoader /> :
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12 col-md-8 col-lg-7">
                                {/* Intro */}
                                <div className="intro text-center">
                                    {/* <span>{initData.pre_heading}</span> */}
                                    <h3 className="mt-3 mb-0">{initData.heading}</h3>
                                    <p>{initData.content}</p>
                                </div>
                            </div>
                        </div>
                        <div className="row items">
                            {props.auction.auctions && props.auction.auctions.length > 0 ?
                                props.auction.auctions.map((item, idx) => {
                                    // NOT NEEDED ANYMORE, WE HAD ALREADY HANDLED IT THROUGH BACKEND
                                    // if (new Date() > (Date.parse(item.auctionEndDate) + 10000)) {
                                    //     return null
                                    // }
                                    return (
                                        <div className="col-md-6 col-lg-4" key={`auc_${idx}`} id={`#auc-${idx}`}>
                                            <div className="card m-3">
                                                <div className="image-over">
                                                    <Link to={`/item-details/${window.btoa(item._id)}`}>
                                                        <img className="card-img-top" src={item.image ? ipfsToUrl(item.image) : globalPlaceholderImage} width="600" height="600" alt="img" />
                                                    </Link>
                                                </div>
                                                <div className="card-caption col-12 p-0">
                                                    <div className="card-body">
                                                        <div className="mb-3">
                                                            <Countdown
                                                                date={Date.parse(item.auctionEndDate) + 10000}
                                                                renderer={countDownRenderer}
                                                            />
                                                        </div>
                                                        <Link to={`/item-details/${window.btoa(item._id)}`}>
                                                            <h5 className="mb-0">{item.name}</h5>
                                                        </Link>
                                                        <Link className="seller d-flex align-items-center my-3" to={`/artist/${item.owner._id}`}>
                                                            <img className="avatar-sm rounded-circle" src={item.owner.profilePhoto} alt="User Avatar" />
                                                            <span className="ml-2" data-effect="float" data-tip={item.owner.username}>{item.owner.username}</span>
                                                            <ReactTooltip />
                                                        </Link>
                                                        <div className="card-bottom d-flex justify-content-end">
                                                            {/* <span>{item.currentPrice} {item.currency}</span> */}
                                                            <div>
                                                                {
                                                                    item.topBid ?
                                                                        <>
                                                                            <div>
                                                                                Top Bid
                                                                            </div>
                                                                            <div>
                                                                                {<img className="mr-1" src={item.currency === 'BNB' ? bnb : item.currency === 'PIXUL' ? pixul : item.currency === 'CRO' ? cro : ''} width="25" height={"25"} />} {item.topBid} {item.currency}
                                                                            </div>
                                                                        </>
                                                                        :
                                                                        item.currentPrice ?
                                                                            <>
                                                                                <div>
                                                                                    Price
                                                                                </div>
                                                                                <div>
                                                                                    {<img src={item.currency === 'BNB' ? bnb : item.currency === 'PIXUL' ? pixul : item.currency === 'CRO' ? cro : ''} width="25" height={"25"} />} {item.currentPrice} {item.currency}
                                                                                </div>
                                                                            </>
                                                                            : ''
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="card-bottom d-flex justify-content-end">
                                                            <div className='pt-1'>
                                                                {
                                                                    item.currentPrice || item.topBid ?
                                                                        <>
                                                                            {
                                                                                item.lastPrice ?
                                                                                    <>
                                                                                        <div>
                                                                                            Last {<img src={item.currency === 'BNB' ? bnb : item.currency === 'PIXUL' ? pixul : item.currency === 'CRO' ? cro : ''} width="25" height={"25"} />} {item.lastPrice.price} {item.lastPrice.currency}
                                                                                        </div>
                                                                                    </>
                                                                                    :
                                                                                    <>
                                                                                        <div>
                                                                                            {Date.parse(item.auctionEndDate) > new Date() ? <><FontAwesomeIcon icon={faClock} /> {getLeftDate(item.auctionEndDate)} </> : ''}
                                                                                        </div>
                                                                                    </>
                                                                            }
                                                                        </>
                                                                        : ''
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })
                                : <div className="no-data border"><p className="text-center ml-3">No Live Auctions Found</p></div>
                            }
                        </div>
                        {
                            loadMoreBtn &&
                            <div className="row">
                                <div className="col-12 text-center">
                                    <a id="load-btn" className="btn btn-danger offer mt-5" onClick={() => loadMore()}>{initData.btn_1}</a>
                                </div>
                            </div>
                        }
                    </div>
            }
        </section>
    );
}

const mapStateToProps = state => ({
    auction: state.auction,
    error: state.error
});

export default connect(mapStateToProps, { beforeAuction, getLiveAuctions })(LiveAuctions);