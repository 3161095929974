import React, { useEffect } from 'react';
import AOS from 'aos';
import './full-page-loader.css';
import gifLoader from '../../assets/images/elumntloadingicon.gif'
import $ from 'jquery'
function FullPageLoader(props) {
    useEffect(() => {
        AOS.init();
        $("#root").addClass("loader-is-visible")
        console.log("MOUNT")

        return () => {
            console.log("UNMOUNT")
            $("#root").removeClass("loader-is-visible")
        }
    }, []);

    return (
        <React.Fragment>
            <div className="fullpage-loader-holder">
                <div className="fullpage-loader loader-image loading">
                    <div className="loading"><img src={gifLoader} alt="loader"/></div>
                </div>
                {
                    props.loadingMessage && 
                    <div>
                        <h3 className='text-after-loader'>{props.loadingMessage}</h3>
                    </div>
                }
            </div>
        </React.Fragment>
    );
};

export default FullPageLoader;