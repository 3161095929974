import React, { useEffect, useState, useRef } from "react";
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { beforeHome, getStats } from '../../homepage/home.action';
import {  Container, Row ,Col} from "react-bootstrap";
import '../statistics/statistics.css';

const Statistics = (props) => {
  const [stats, setStats] = useState(undefined)

    useEffect(() => {
      props.getStats();
    }, [])

    useEffect(() => {
      if(props.home.statsAuth){
        setStats(props.home.stats)
      }
    }, [props.home.statsAuth])
  return (
    <>
      {stats && 
        <section className="Statistics">
          <Container fluid className="custom-container">
          <h2 className='text-center'>elumnt statistics</h2>
            <Row className="justify-content-center">
              <Col lg={8} md={10}>
              <div className='d-flex align-item-center justify-content-around Statistics-content'>
              <div className='values'>
              <h4>{stats?.Total_Sales}+</h4>
              <p>Total Sales</p>
              </div>
              <div  className='values'>
              <h4>{stats?.Collectibles}+</h4>
              <p>Collectibles</p>
              </div>
              <div  className='values'>
              <h4>{stats?.Auctions}+</h4>
              <p>Auctions</p>
              </div>
              <div  className='values'>
              <h4>{stats?.Artists}+</h4>
              <p>Artists</p>
              </div>
              {/* <ul className='value'>
                  <li>$30m+</li>
                  <li>43k+</li>
                  <li>20k+</li>
                  <li>10k+</li>
              </ul>
              <ul className='category'>
                  <li>Total Sales</li>
                  <li>Collectibles</li>
                  <li>Auctions</li>
                  <li>Artists</li>
              </ul> */}
          </div>
              </Col>
        
            </Row>
          </Container>
        </section>
      }
    </>
  )
}

const mapStateToProps = state => ({
	home: state.home,
});

export default connect(mapStateToProps, { beforeHome, getStats })(Statistics);