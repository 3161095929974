import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import Select from 'react-select'
import { beforeCollection, getCollections } from '../collections/collections.actions'
import { beforeCategory, getCategories } from '../categories/categories.action'
import { beforeUser, getCreators } from '../user/user.action'
import { ENV } from '../../config/config'
import {
  getChainId,
} from './../../utils/web3'
const { decimalNumberValidator, globalPlaceholderImage, currencyFilters } = ENV

function FilterSearch(props) {
  const [statusFilters, setStatusFilters] = useState(true);
  const [mintTypeFilters, setMintTypeFilters] = useState(false);
  const [activityFilters, setActivityFilters] = useState(true);
  const [priceFilters, setPriceFilters] = useState(false);
  const [colFilters, setColFilters] = useState(false);
  const [catFilters, setCatFilters] = useState(false);
  const [authorFilters, setAuthorFilters] = useState(false);
  const [collections, setCollections] = useState(null);
  const [categories, setCategories] = useState(null);
  const [authors, setAuthors] = useState(null);
  const [currencies, setCurrencies] = useState([]);
  const [status, setStatus] = useState(null); // 1 = on auction, 2 = has offer, 3 = new
  const [mintType, setMintType] = useState(null); // 1 = on auction, 2 = has offer, 3 = new
  const [currency, setCurrency] = useState(null);
  const [minPrice, setMinPrice] = useState('');
  const [maxPrice, setMaxPrice] = useState('');
  const [filter, setFilter] = useState(null);
  const [chainId, setChainId] = useState('')

  const _nftMintFilters = {
    singleMint: false,
    multipleMint: false,
    allMint: false
  }
  const [nftFilters, setNFTMintFilters] = useState(_nftMintFilters);

  const _nftStatusFilters = {
    onAuctionStatus: false,
    hasOfferStatus: false,
    newSatus: false
  }
  const [nftStausFilters, setNFTStatusFilters] = useState(_nftStatusFilters);

  useEffect(() => {
    getChainId().then(setChainId)
  }, [])

  useEffect(() => {
    if (props.blockChain) {
      const chainBasedCur = currencyFilters.find(elem => elem?.chainIds?.includes(Number(props.blockChain)))
      if (chainBasedCur) {
        setCurrency(chainBasedCur.symbol)
        setCurrencies([chainBasedCur, currencyFilters[currencyFilters.length - 1]])
      }
    }
  }, [props.blockChain])

  useEffect(() => {
    // get collections only if collection filters are visible
    if (props.showColFilters)
      getCollections()
  }, [props.showColFilters, props.blockChain]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // get collections only if collection filters are visible
    if (props.resetFilters) {
      setFilter(null)
      setNFTMintFilters(_nftMintFilters)
      setNFTStatusFilters(_nftStatusFilters)
      props.applyFilters({})
    }
  }, [props.resetFilters]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // get categories only if category filters are visible
    if (props.showCatFilters)
      getCategories()
  }, [props.showCatFilters]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // get authors only if author filters are visible
    if (props.showAuthorFilters)
      getAuthors()
  }, [props.showAuthorFilters]) // eslint-disable-line react-hooks/exhaustive-deps

  // apply status filter
  const applyStatusFilter = (value) => {
    setStatus(value)
    const filterData = { ...filter, status: value }
    if (!filterData.status)
      delete filterData.status
    setFilter(filterData)
    props.resetAllFilters(false)
    props.applyFilters(filterData)
  }

  // apply status filter
  const applyMintTypeFilter = (value) => {
    setMintType(value)
    const filterData = { ...filter, mintType: value }
    if (!filterData.mintType)
      delete filterData.mintType
    setFilter(filterData)
    props.resetAllFilters(false)
    props.applyFilters(filterData)
  }

  const handleChange = selectedOption => {
    setCurrency(selectedOption?.value || null)
  }

  const onChange = (e) => {
    const { name, value } = e.target
    if (name === 'minPrice')
      setMinPrice(value)
    else if (name === 'maxPrice')
      setMaxPrice(value)
  }

  // apply price filter
  const applyPriceFilter = () => {
    const filterData = { ...filter }
    if (minPrice)
      filterData.minPrice = minPrice
    else
      delete filterData.minPrice

    if (maxPrice)
      filterData.maxPrice = maxPrice
    else
      delete filterData.maxPrice

    if (currency)
      filterData.currency = currency
    else
      delete filterData.currency

    setFilter(filterData)
    props.resetAllFilters(false)
    props.applyFilters(filterData)
  }

  // clear price filter
  const clearPriceFilter = () => {
    // clear fields
    setCurrency(null)
    setMinPrice('')
    setMaxPrice('')

    const filterData = { ...filter }
    delete filterData.minPrice
    delete filterData.maxPrice
    delete filterData.currency
    setFilter(filterData)
    props.resetAllFilters(false)
    props.applyFilters(filterData)
  }

  const getCollections = () => {
    const filter = {
      all: true,
      ...(props.blockChain && { blockChain: props.blockChain })
    }

    const qs = ENV.objectToQueryString(filter)
    props.getCollections(chainId, qs)
  }

  const getCategories = () => {
    const filter = {
      all: true,
    }

    const qs = ENV.objectToQueryString(filter)
    props.getCategories(qs)
  }

  const getAuthors = () => {
    props.getCreators()
  }

  // set authors
  useEffect(() => {
    if (props.user.creatorsAuth) {
      setAuthors(props.user.creators?.creators)
      props.beforeUser()
    }
  }, [props.user.creatorsAuth]) // eslint-disable-line react-hooks/exhaustive-deps

  // set collections
  useEffect(() => {
    if (props.collection.getDetailAuth) {
      const { collections } = props.collection
      setCollections(collections)
      props.beforeCollection()
    }
  }, [props.collection.getDetailAuth]) // eslint-disable-line react-hooks/exhaustive-deps

  // set collections
  useEffect(() => {
    if (props.collection.getAuth) {
      const { collections } = props.collection
      setCollections(collections)
      props.beforeCollection()
    }
  }, [props.collection.getAuth]) // eslint-disable-line react-hooks/exhaustive-deps

  // set categories
  useEffect(() => {
    if (props.category.getAuth) {
      const { categories } = props.category
      setCategories(categories)
      props.beforeCategory()
    }
  }, [props.category.getAuth]) // eslint-disable-line react-hooks/exhaustive-deps

  // get collection NFTs
  const getColNFTs = (collectionId, name = "") => {
    const filterData = { ...filter }

    if (collectionId) {
      filterData.collectionId = collectionId
      filterData.collectionName = name
    }

    if (collectionId === 'all') {
      delete filterData.collectionId
      delete filterData.collectionName
    }
    setFilter(filterData)
    props.resetAllFilters(false)
    props.applyFilters(filterData)
  }

  // get categories NFTs
  const getCatNFTs = (categoryId, name = "") => {
    const filterData = { ...filter }

    if (categoryId) {
      filterData.categoryId = categoryId
      filterData.categoryName = name
    }

    if (categoryId === 'all') {
      delete filterData.categoryId
      delete filterData.categoryName
    }

    setFilter(filterData)
    props.resetAllFilters(false)
    props.applyFilters(filterData)
  }

  // get author / creators NFTs
  const getAuthorNFTs = (creatorId, name = "") => {
    const filterData = { ...filter }
    if (creatorId) {
      filterData.creatorId = creatorId
      filterData.creatorName = name
    }
    if (creatorId === 'all') {
      delete filterData.creatorId
      delete filterData.creatorName
    }
    setFilter(filterData)
    props.resetAllFilters(false)
    props.applyFilters(filterData)
  }

  const handleCheckBox = (e) => {
    const { name, checked } = e.target
    let filters = props.filters;
    filters[name] = checked;
    props.setFilters({ ...filters })
  }

  const handleNFTMintFilters = (e) => {
    const filterData = { ...filter }

    const { name, checked } = e.target
    if (checked) {
      if (name === "allMint" && checked) {
        delete filterData.mintType //= null
        setNFTMintFilters({ singleMint: false, multipleMint: false, allMint: true })
      }
      else if (name === "singleMint" && checked) {
        filterData.mintType = 1
        setNFTMintFilters({ multipleMint: false, allMint: false, singleMint: true })
      }
      else if (name === "multipleMint" && checked) {
        filterData.mintType = 2
        setNFTMintFilters({ singleMint: false, allMint: false, multipleMint: true })
      }
    }
    else {
      delete filterData.mintType //= null
      setNFTMintFilters({ [name]: checked })
    }



    setFilter(filterData)
    props.resetAllFilters(false)
    props.applyFilters(filterData)
  }

  const handleNFTStatusFilters = (e) => {
    const filterData = { ...filter }

    const { name, checked } = e.target
    if (checked) {
      if (name === "newStatus" && checked) {
        filterData.status = 3 //= null
        setNFTStatusFilters({ onAuctionStatus: false, hasOfferStatus: false, newStatus: true })
      }
      else if (name === "onAuctionStatus" && checked) {
        filterData.status = 1
        setNFTStatusFilters({ hasOfferStatus: false, newStatus: false, onAuctionStatus: true })
      }
      else if (name === "hasOfferStatus" && checked) {
        filterData.status = 2
        setNFTStatusFilters({ onAuctionStatus: false, newStatus: false, hasOfferStatus: true })
      }
    }
    else {
      delete filterData.status //= null
      setNFTStatusFilters({ [name]: checked })
    }



    setFilter(filterData)
    props.resetAllFilters(false)
    props.applyFilters(filterData)
  }
  return (

    <React.Fragment>
      <div className='collection-tabs'>
        <div className='items-sidebar'>
          <div className="filter-wrapper">

            {props.items ?
              <div>
                <div className="sidebar-border">
                  <div onClick={() => setStatusFilters(!statusFilters)} className={`${statusFilters ? 'open' : ''} status-wrapper d-flex`}>
                    <div><span><b>Status</b></span></div>
                    <div className="tab-icon" data-toggle="tooltip" title="Icon">
                      <i className={`fas fa-chevron-${statusFilters ? 'down' : 'up'}`} />
                    </div>
                  </div>
                  {
                    statusFilters &&
                    <div className="statuses-boxex" style={{ border: "none" }}>
                      <div className={`on-auction d-flex align-items-center justify-content-between ${'active'}`} >
                        <span>On Auction</span>
                        <label className="check-container">
                          <input type="checkbox" name="onAuctionStatus" value={1} onChange={handleNFTStatusFilters} checked={nftStausFilters.onAuctionStatus} />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                      <div className={`on-auction d-flex align-items-center justify-content-between ${'active'}`} >
                        <span>Has Offer</span>
                        <label className="check-container">
                          <input type="checkbox" name="hasOfferStatus" value={2} onChange={handleNFTStatusFilters} checked={nftStausFilters.hasOfferStatus} />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                      <div className={`on-auction d-flex align-items-center justify-content-between ${'active'}`} >
                        <span>New</span>
                        <label className="check-container">
                          <input type="checkbox" name="newStatus" value={3} onChange={handleNFTStatusFilters} checked={nftStausFilters.newStatus} />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    </div>
                  }
                </div>
                <div className="sidebar-border">
                  <div onClick={() => setPriceFilters(!priceFilters)} className={`${priceFilters ? 'open' : ''} status-wrapper d-flex`}>
                    <div><span><b>Price</b></span></div>
                    <div className="tab-icon" data-toggle="tooltip" title="Icon">
                      <i className={`fas fa-chevron-${priceFilters ? 'up' : 'down'}`} />
                    </div>
                  </div>
                  {
                    priceFilters &&
                    <div className="statuses-boxex2">
                      <div className="d-flex justify-content-center align-items-center price-flex mb-3">
                        <Select
                          options={currencies}
                          value={currency ? currencies.filter(option => option.value === currency) : currencies[0]}
                          onChange={handleChange}
                          className="select-custom-style"
                          placeholder="ETH"
                          classNamePrefix="custom-select"
                          styles={{
                            menu: (provided, state) => ({
                              ...provided,
                              color: state.isDisabled ? 'grey' : 'hsl(0,0%,20%)',
                              cursor: state.isDisabled ? 'not-allowed' : 'pointer'
                            })
                          }}
                          // isClearable={true}
                          isSearchable={false}
                        />
                        <div className="d-flex align-items-center">
                          <input type="text" placeholder="min" className="min-max mr-2" name="minPrice" onChange={(e) => onChange(e)} onKeyDown={(e) => decimalNumberValidator(e)} value={minPrice} /> to <input type="text" placeholder="max" className="min-max ml-2" name="maxPrice" onChange={(e) => onChange(e)} onKeyDown={(e) => decimalNumberValidator(e)} value={maxPrice} />
                        </div>
                      </div>
                      <div className="d-flex justify-content-between align-items-center">
                        <button className="login-btn w-100" onClick={() => applyPriceFilter()} disabled={!(minPrice || maxPrice)}>Apply</button>
                        {/* <button className="apply-btn grey-btn" onClick={() => clearPriceFilter()}>Clear</button> */}
                      </div>
                    </div>
                  }
                </div>
                <div className="sidebar-border">
                  <div onClick={() => setMintTypeFilters(!mintTypeFilters)} className={`${mintTypeFilters ? 'open' : ''} status-wrapper d-flex`}>
                    <div><span><b>Mint Type</b></span></div>
                    <div className="tab-icon" data-toggle="tooltip" title="Icon">
                      <i className={`fas fa-chevron-${mintTypeFilters ? 'up' : 'down'}`} />
                    </div>
                  </div>
                  {
                    mintTypeFilters &&
                    <div className="statuses-boxex" style={{ border: "none" }}>
                      <div className={`on-auction d-flex align-items-center justify-content-between ${mintType === 0 && 'active'}`} >
                        <span>All items</span>
                        <label className="check-container">
                          <input type="checkbox" name="allMint" onChange={handleNFTMintFilters} checked={nftFilters?.allMint} data="saqib" />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                      <div className={`on-auction d-flex align-items-center justify-content-between ${mintType === 1 && 'active'}`} >
                        <span>Single Mint</span>
                        <label className="check-container">
                          <input type="checkbox" name="singleMint" onChange={handleNFTMintFilters} checked={nftFilters?.singleMint} />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                      <div className={`on-auction d-flex align-items-center justify-content-between ${mintType === 2 && 'active'}`} >
                        <span>Multiple Mint</span>
                        <label className="check-container">
                          <input type="checkbox" name="multipleMint" onChange={handleNFTMintFilters} checked={nftFilters?.multipleMint} />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    </div>
                  }
                </div>
                {
                  props.showColFilters && collections &&
                  <div className="sidebar-border custom-collection-filters">
                    <div onClick={() => setColFilters(!colFilters)} className={`${colFilters ? 'open' : ''} status-wrapper d-flex`}>
                      <div><span><b>Collection</b></span></div>
                      <div className="tab-icon" data-toggle="tooltip" title="Collection">
                        <i className={`fas fa-chevron-${colFilters ? 'up' : 'down'}`} />
                      </div>
                    </div>
                    {
                      colFilters &&
                      <>
                        <div className="d-flex flex-column justify-content-start statuses-boxex" style={{ border: "none" }}>
                          <div className={`d-flex align-items-center filter-item ${!filter?.collectionId && 'active'}`} onClick={() => getColNFTs('all')}>
                            <div className="collection-img rounded"></div><span className="ml-2">All</span>
                          </div>
                          {
                            collections.map((collection, index) => {
                              return (
                                <div key={index} className={`d-flex align-items-center filter-item ${collection._id === filter?.collectionId && 'active'}`} onClick={() => getColNFTs(collection._id, collection.name)}>
                                  <div className="filter-img"><img className="rounded" src={collection.logo} alt="img" /></div><span className="ml-2">{collection.name}</span>
                                </div>
                              )
                            })
                          }
                        </div>
                      </>
                    }
                  </div>
                }
                {
                  props.showCatFilters && categories &&
                  <div className="sidebar-border ">
                    <div onClick={() => setCatFilters(!catFilters)} className={`${catFilters ? 'open' : ''} status-wrapper d-flex`}>
                      <div><span><b>Category</b></span></div>
                      <div className="tab-icon" data-toggle="tooltip" title="Filter">
                        <i className={`fas fa-chevron-${catFilters ? 'up' : 'down'}`} />
                      </div>
                    </div>
                    {
                      catFilters &&
                      <>
                        <div className="d-flex justify-content-start statuses-boxex flex-column" style={{ border: "none" }}>
                          <div className={`d-flex align-items-center filter-item ${!filter?.categoryId && 'active'}`} onClick={() => getCatNFTs('all')}>
                            <div className="collection-img rounded"></div><span className="ml-2">All</span>
                          </div>
                          {
                            categories.map((category, index) => {
                              return (
                                <div key={index} className={`d-flex align-items-center filter-item ${category._id === filter?.categoryId && 'active'}`} onClick={() => getCatNFTs(category._id, category.name)}>
                                  <div className="filter-img"><img className="rounded" src={category.image} alt="img" /></div><span className="ml-2">{category.name}</span>
                                </div>
                              )
                            })
                          }
                        </div>
                      </>
                    }
                  </div>
                }
                {
                  props.showAuthorFilters && authors &&
                  <div className="sidebar-border">
                    <div onClick={() => setAuthorFilters(!authorFilters)} className={`${authorFilters ? 'open' : ''} status-wrapper d-flex`}>
                      <div><span><b>Author</b></span></div>
                      <div clmint={+true} typeassname="tab-icon" data-toggle="tooltip" title="Filter">
                        <i className={`fas fa-chevron-${authorFilters ? 'up' : 'down'}`} />
                      </div>
                    </div>
                    {
                      authorFilters &&
                      <>
                        <div className="d-flex flex-column justify-content-start statuses-boxex" style={{ border: "none" }}>
                          <div className={`d-flex align-items-center filter-item ${!filter?.creatorId && 'active'}`} onClick={() => getAuthorNFTs('all')}>
                            <div className="collection-img rounded"></div><span className="ml-2">All</span>
                          </div>
                          {
                            authors.map((author, index) => {
                              return (
                                <div key={index} className={`d-flex align-items-center filter-item ${author._id === filter?.creatorId && 'active'}`} onClick={() => getAuthorNFTs(author._id, author.username)}>
                                  <div className="filter-img rounded">
                                    <img className="rounded" src={author.profileImage ? author.profileImage : globalPlaceholderImage} alt="img" />
                                  </div>
                                  <span className="ml-2">{author.username}</span>
                                </div>
                              )
                            })
                          }
                        </div>
                      </>
                    }
                  </div>
                }
              </div>
              :
              ""
            }

            {props.activity ?
              <div>
                <div className="sidebar-activity">
                  <div onClick={() => setActivityFilters(!activityFilters)} className={`${activityFilters ? 'open' : ''} status-wrapper d-flex`}>
                    <div><span><b>Filters</b></span></div>
                    <div className="tab-icon" data-toggle="tooltip" title="Icon">
                      <i className={`fas fa-chevron-${activityFilters ? 'up' : 'down'}`} />
                    </div>
                  </div>
                  {
                    activityFilters &&
                    <div className="statuses-boxex" style={{ border: "none" }}>
                      <div className={`on-auction d-flex align-items-center justify-content-between ${'active'}`} >
                        <span>Sales</span>
                        <label className="check-container">
                          <input type="checkbox" name="sales" onChange={handleCheckBox} defaultChecked={props?.filters?.sales} />
                          <span className="checkmark"
                          ></span>
                        </label>
                      </div>
                      <div className={`on-auction d-flex align-items-center justify-content-between ${'active'}`} >
                        <span>NFT Created</span>
                        <label className="check-container">
                          <input type="checkbox" name="creation" onChange={handleCheckBox} defaultChecked={props?.filters?.creation} />
                          <span className="checkmark"
                          >
                          </span>
                        </label>
                      </div>
                      <div className={`on-auction d-flex align-items-center justify-content-between ${'active'}`} >
                        <span>Listings</span>
                        <label className="check-container">
                          <input type="checkbox" name="listings" onChange={handleCheckBox} defaultChecked={props?.filters?.listings} />
                          <span className="checkmark"
                          >
                          </span>
                        </label>
                      </div>
                      <div className={`on-auction d-flex align-items-center justify-content-between ${'active'}`} >
                        <span>Offers</span>
                        <label className="check-container">
                          <input type="checkbox" name="offers" onChange={handleCheckBox} defaultChecked={props?.filters?.offers} />
                          <span className="checkmark"
                          ></span>
                        </label>
                      </div>
                      <div className={`on-auction d-flex align-items-center justify-content-between ${'active'}`} >
                        <span>Bids</span>
                        <label className="check-container">
                          <input type="checkbox" name="bids" onChange={handleCheckBox} defaultChecked={props?.filters?.bids} />
                          <span className="checkmark"
                          ></span>
                        </label>
                      </div>
                      <div className={`on-auction d-flex align-items-center justify-content-between ${'active'}`} >
                        <span>Accept Offer</span>
                        <label className="check-container">
                          <input type="checkbox" name="acceptOffer" onChange={handleCheckBox} defaultChecked={props?.filters?.acceptOffer} />
                          <span className="checkmark"
                          ></span>
                        </label>
                      </div>
                      <div className={`on-auction d-flex align-items-center justify-content-between ${'active'}`} >
                        <span>Accept Bid</span>
                        <label className="check-container">
                          <input type="checkbox" name="acceptBid" onChange={handleCheckBox} defaultChecked={props?.filters?.acceptBid} />
                          <span className="checkmark"
                          ></span>
                        </label>
                      </div>
                      <div className={`on-auction d-flex align-items-center justify-content-between ${'active'}`} >
                        <span>Cancel Listing</span>
                        <label className="check-container">
                          <input type="checkbox" name="cancelListing" onChange={handleCheckBox} defaultChecked={props?.filters?.cancelListing} />
                          <span className="checkmark"
                          ></span>
                        </label>
                      </div>
                    </div>
                  }
                </div>
              </div>
              :
              ""
            }
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = state => ({
  collection: state.collection,
  category: state.category,
  user: state.user,
  error: state.error
});

export default connect(mapStateToProps, { beforeCollection, getCollections, beforeCategory, getCategories, beforeUser, getCreators })(FilterSearch)
