import React, { useEffect, useState, useRef } from "react";
import {  Container, Row } from "react-bootstrap";
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { beforeHome, getTopCategories } from '../../homepage/home.action';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import '../popular-cards/popular-cards.css'
import { ENV } from '../../../config/config';
const itemPlaceholderImg = ENV.collectionFeaturedImg

const PopularCards = (props) => {

      const [collection, setCollection] = useState(undefined)

      useEffect(() => {
        props.getTopCategories();
      }, [])

      useEffect(() => {
        if(props.home.topCategoriesAuth){
          setCollection(props.home.topCategories.collection)
        }
      }, [props.home.topCategoriesAuth])


      const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 5
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 4
        },
        tablet: {
          breakpoint: { max: 1024, min: 576 },
          items: 2
        },
        mobile: {
          breakpoint: { max: 575, min: 0 },
          items: 1
        }
      };

  return (
    <>
      {collection !== undefined && collection.length ?
        <section className="popular-cards">
          <Container fluid>
            <Carousel responsive={responsive}  showDots={true}>
            {collection.map((i, index)=>
              <Link key={index} to={`/category/${i.category?.slug}`}>
                <div className='popular-card'>
                  <div className='fade-overlay'></div>
                  <div className='popular-overlay'>
                    {/* <h3>{i.category.name}</h3> */}
                  </div>
                  <img src={i.category.image ? i.category.image : itemPlaceholderImg} className="card-img" alt="card"/>
                </div>
              </Link>
            )}
            </Carousel>
          </Container>
      </section>
      :
        <section className="popular-cards">
          <Container fluid> 
            <div className="no-data border"><p className="text-center">No Record Found</p></div>
          </Container>
        </section>
      }
    </>
  )
}

const mapStateToProps = state => ({
	home: state.home,
});

export default connect(mapStateToProps, { beforeHome, getTopCategories })(PopularCards);