import React, { useState, useEffect } from 'react'
import { Table } from 'react-bootstrap'
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from 'moment';

import profileImg from '../../assets/images/avatarplaceholder.jpg';
import timeIcon from '../../assets/images/time-icon.png';

import { beforeActivity, getActivities } from "../activity/activity.action";
import './activity-table.css';
import { ENV } from '../../config/config'
import Ethereum from '../../assets/images/elumntetherscanexplorer.webp'
import Binance from '../../assets/images/elumntetherscanexplorer.webp';
import Fantom from '../../assets/images/fantomelumntexplorer.png';
import Cronos from '../../assets/images/cronoselumntexplorer.svg';
const {chainsConfigs} = ENV;


const Activitytypes = {
    creation: 1,
    offers: 2,
    bids: 3,
    acceptOffer: 4,
    acceptBid: 5,
    listings: 6,
    sales: 7,
    cancelListing: 8
}

const ActivityTable = ({ selectedOption, collectionId, setGraph, setLoader, authorId, filters }) => {

    const [activityList, setActivityList] = useState([]);
    const dispatch = useDispatch();
    const activities = useSelector((state) => state.activities);

    const app = useSelector(state => state.app);
    //get Activities For AuthorId 
    useEffect(() => {
        let qs = 'null?' //'null?'
        if (authorId && filters) {
            qs += `authorId=${authorId}&types=`
            for (var key of Object.keys(filters)) {
                const value = filters[key]
                if (value === true)
                    qs += `${Activitytypes[key]},`
            }
        }
        if (setLoader) {
            setLoader(true)
        }
        dispatch(getActivities(qs));
    }, [authorId, filters])


    //purely for collectoin detail page
    //get Activities First Time and on Days Selection 
    useEffect(() => {
        let qs = 'null?' //'null?'
        if (collectionId && selectedOption.value && filters) {
            qs += `collectionId=${collectionId}&days=${selectedOption.value}&types=`
            for (var key of Object.keys(filters)) {
                const value = filters[key]
                if (value === true)
                    qs += `${Activitytypes[key]},`
            }
            dispatch(getActivities(qs));
        }
        if (setLoader) {
            setLoader(true)
        }
    }, [selectedOption && selectedOption.value, filters])

    //set the activities state and false the loader for parent component 
    useEffect(() => {
        if (activities.activitiesAuth) {
            setActivityList(activities.activities.activities)
            if (setGraph) {
                setGraph(activities.activities.graph)
            }
            dispatch(beforeActivity());
            if (setLoader) {
                setLoader(false)
            }
        }

    }, [activities])

    const types = { 1: "Created", 2: "Offer", 3: "Bid", 4: "Accept Offer", 5: "Accept Bid", 6: "Listing", 7: "Sale", 8: "Cancel Listing" }
    const chainIcons = {
        'ETH': Ethereum,
        'WETH': Ethereum,
        "WBNB": Binance,
        "BNB": Binance,
        'WCRO': Cronos,
        'CRO': Cronos,
        "WFTM": Fantom,
        "FTM": Fantom
    }


    return (
        <div className="activity-table">
            {activityList.length <= 0 && <p> No Acitivity Found!</p>}
            {activityList && activityList.length > 0 && <div className="table-responsive">
                <Table striped hover>
                    <thead>
                        <tr>
                            <th className="td-start"></th>
                            <th className="td-items">Item/Details</th>
                            <th className="td-price">Price</th>
                            <th className="text-center td-quantity">Quantity</th>
                            <th className="text-center td-from">From</th>
                            <th className="text-center td-to">To</th>
                            <th className="text-center td-time">Time</th>
                        </tr>
                    </thead>
                    <tbody>
                        {activityList.map(activity => {
                            return (<tr>
                                <td className="td-start">
                                    <div className="center-align d-flex align-items-center justify-content-center">{types[activity.type]}</div>
                                </td>
                                <td className="td-items">
                                    <div className="center-align activity-item d-flex align-items-center">
                                        <div className="activity-img">
                                            <Link to={`/item-details/${window.btoa(activity?.nft?._id)}`}>
                                                <img src={activity?.nft?.image ? activity?.nft?.image : profileImg} className="img-fluid" />
                                            </Link>
                                        </div>
                                        <div className="activity-data">

                                            <h3>{activity?.nft?.name}</h3>
                                            <div className="d-flex align-items-center username">
                                                {activity?.nft?.tokenId && <span>#{activity?.nft?.tokenId}</span>}
                                                <span className="mx-2">- from:</span>
                                                <Link to={`/artist/${activity?.user?.username}`}>{activity?.user?.username}</Link>
                                            </div>
                                            {activity?.nft?.createdAt && <span className="time">{moment(activity?.nft?.createdAt).format("DD/MM/yyyy ,HH:mm:ss")}</span>}
                                        </div>
                                    </div>
                                </td>
                                <td className="td-price">
                                    <div className="center-align d-flex flex-column justify-content-center">
                                        <div className="d-flex align-items-center">
                                            {(activity.txHash && activity.chainId) ?
                                                <a
                                                    className="eth-icon"
                                                    target="_blank"
                                                    href={`${chainsConfigs[activity.chainId].explorer}tx/${activity.txHash}`}
                                                >
                                                    <img src={chainIcons[activity?.currency ? activity?.currency : 'ETH']}
                                                        className="img-fluid" />
                                                </a>
                                                : <div className="eth-icon">
                                                    <img src={chainIcons[activity?.currency ? activity?.currency : 'ETH']}
                                                        className="img-fluid" />
                                                </div>
                                            }
                                            <h4 className="ml-2">{activity.price}</h4>
                                        </div>
                                        {(activity?.currency === ("WBNB" ?? "BNB")) && activity?.price && app?.rate && <div>
                                            <span className="time ml-3">${ENV.convertRateToUsd(activity?.price, app.rate)}</span>
                                        </div>}
                                        {(activity?.currency === "ETH") && activity?.price && app?.ethRate && <div>
                                            <span className="time ml-3">${ENV.convertRateToUsd(activity?.price, app.ethRate)}</span>
                                        </div>}
                                        {(activity?.currency === "CRO") && activity?.price && app?.croRate && <div>
                                            <span className="time ml-3">${ENV.convertRateToUsd(activity?.price, app.croRate)}</span>
                                        </div>}
                                        {(activity?.currency === "FTM") && activity?.price && app?.fantomRate && <div>
                                            <span className="time ml-3">${ENV.convertRateToUsd(activity?.price, app.fantomRate)}</span>
                                        </div>}
                                    </div>
                                </td>
                                <td className="td-quantity">
                                    <div className="center-align d-flex align-items-center justify-content-center">1</div>
                                </td>
                                <td className="td-from">
                                    <div className="center-align d-flex align-items-center justify-content-center"><Link to={`/artist/${activity?.user?.username}`}>{activity?.user?.username}</Link></div>
                                </td>
                                <td className="td-to">
                                    <div className="center-align d-flex align-items-center justify-content-center"><Link to={`/artist/${activity?.username}`}>{activity?.toUserName ? activity?.toUserName : "N/A"}</Link></div>
                                </td>
                                <td className="td-time">
                                    <div className="center-align d-flex align-items-center justify-content-center">
                                        <h4>{moment(activity?.createdAt).fromNow()}</h4>
                                        <span className='ml-2'><img src={timeIcon} className="img-fluid" /></span>
                                    </div>
                                </td>
                            </tr>)
                        })
                        }
                    </tbody>
                </Table>
            </div>}
        </div >
    )
}

export default ActivityTable