import React, { Suspense ,useState} from 'react'
import routes from './routes'
import { PrivateRoute } from './privateRoute'
import { useNavigate, useParams, Route, Routes } from "react-router-dom";
import CookieConsent, { Cookies } from "react-cookie-consent";
import cookieImage from '../src/assets/images/cookiesicon.svg'

import Getter from './Getter';
import './App.css';

function App(props) {
  const navigate = useNavigate()
  const params = useParams()
  const [cookie, setCookie] = useState(true)
  const cookieHandler = () =>{
    setCookie(false)
  }
  return (
    <div className="App">
      <Getter />
      <Routes>
        {
          routes.map((route, index) => {
            return (
              <Route
                key={index}
                path={route.path}
                exact={route.exact}
                element={
                  <route.layout {...props} title={route.title} customBg={route.customBg}>
                    <route.component {...props} navigate={navigate} params={params} />
                  </route.layout>
                }
              />
            )
          })
        }
      </Routes>
      {cookie && <CookieConsent 
            disableStyles={true}
            location={"bottom"}
            buttonClasses="btn btn-danger accept-cookies-btn"
            buttonText="Accept Cookies"
            containerClasses="custom-cookie-alert col-lg-12 col-sm-12 d-flex align-items-center justify-content-between"
          >
          <div className='cookies-image'><img src={cookieImage} className=" img-fluid" alt='Coookie-icon'/></div>
           <div> <span className='about-cookies'> <b className='notice'>Cookies Notice:</b> We use cookies to give you the best online experience.Please let us know if you agree to all of these cookies.<a href='https://dev.elumnt.com/privacy-and-terms' target="_blank" className='cookies-privacy-link'>View Privacy policy</a></span>{" "}</div>
          </CookieConsent>}
    </div>
  );
}

export default App;
