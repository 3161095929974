
import React, { useEffect } from 'react';
import gifLoader from '../../../assets/images/elumntloadingicon.gif'
import './miniloader.css';

const MiniLoader = () => {
    return (
        <React.Fragment>
            <div className="mini-loader-holder">
                <div className="mini-loader mini-loader-image ">
                    <div className="mini-loading"><img src={gifLoader} alt="loader"/></div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default MiniLoader