import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { ENV } from '../../config/config';
import { beforeCollection, getCollections } from './collections.actions';
import ReactTooltip from 'react-tooltip'
import nextIcon from '../../assets/images/next-icon.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-solid-svg-icons'
import {
    getChainId,
} from './../../utils/web3'
const { collectionFeaturedImg } = ENV

const Collections = forwardRef((props, ref) => {
    const [collections, setCollections] = useState([])
    const [category, setCategory] = useState(null)
    const [appendData, setAppendData] = useState(false) // falg to append data or not
    const [source, setSource] = useState(1) // 1 for my collections, 2 for popular collections
    const [loadMoreBtn, setLoadMoreBtn] = useState(true);
    const [pagination, setPagination] = useState(null);
    const [chainId, setChainId] = useState('')
    const [selectedCat, setCategoryId] = useState('')

    useEffect(() => {
        if (window?.walletPO?.eth && !chainId)
            getChainId().then(setChainId)
    }, [window?.walletPO?.eth])


    useEffect(() => {
        getChainId().then(setChainId)
    }, [])

    useEffect(() => {
        if (chainId) {
            window.scroll(0, 0)
            if (props.source)
                setSource(props.source)

            if (props.setLoader)
                props.setLoader(true)

            const categoryId = props.exploreCat || selectedCat || null
            getCollections(categoryId)
        }
    }, [chainId, props.blockChain])


    useEffect(() => {
        //on Chain Changed WE have to reset previous Collection if any exist
        if (props.blockChain) {
            setCollections([])
        }
    }, [props.blockChain, selectedCat])

    const loadMore = () => {
        const { page, limit } = pagination
        props.setLoader(true)
        // get more collections
        getCollections(category, page + 1, limit)
    }

    useEffect(() => {
        if (props.collection.getAuth) {
            const colsData = props.collection
            props.beforeCollection()

            if (!props.all) {
                setCollections(appendData ? collections.concat(colsData.collections) : (colsData.collections || []))
                setPagination(colsData.pagination)

                if (!(colsData.collections?.length) || colsData.pagination && colsData.pagination.page === colsData.pagination.pages)
                    setLoadMoreBtn(false)
                else
                    setLoadMoreBtn(true)
            }
            else
                setCollections(colsData.collections)

            if (props.setLoader)
                props.setLoader(false)
        }
    }, [props.collection.getAuth])

    const getCollections = (categoryId = null, page = 1, limit = 8) => {
        setCategory(categoryId)
        setAppendData(categoryId === category)
        const filter = {
            page,
            ...(!!props.blockChain && { blockChain: props.blockChain }),  //conditionally Add BlockChain (alternative of if cond.)
            ...(!!props.noDefault && { noDefault: props.noDefault })

        }
        if (categoryId) {
            filter.categoryId = categoryId
            filter.noDefault = true
        }

        if (props.userId)
            filter.userId = props.userId

        if (props.popular)
            filter.popular = true

        // if not all then apply limit 
        if (!props.all)
            filter.limit = props.limit || limit
        else
            filter.all = true

        const qs = ENV.objectToQueryString(filter)
        props.getCollections(chainId, qs)
    }

    useImperativeHandle(ref, () => ({
        getCatCollections: (catId) => {
            if (props.setLoader)
                props.setLoader(true)

            //only api hit for unique selected Category ID
            if (catId != selectedCat) {
                setCategoryId(catId)
                getCollections(catId)
            }
        },
        colCount: collections?.length || 0
    }))

    return (
        <div className="popular-collections-area collection-main-page">
            <div className="container-fluid">
                <div className="items d-flex align-items-center flex-wrap">
                    <>
                        {
                            collections && collections.length ?
                                collections.map((item, idx) => {
                                    if (item === null) {
                                        return null
                                    }
                                    return (
                                        <div key={`cd_${idx}`} className="item">
                                            <div className="card no-hover">
                                                <div className="image-over">
                                                    {console.log(item)}
                                                    <Link to={`/collection/${item.url}`}>
                                                        <img className="card-img-top" src={item.logo ? item.logo : collectionFeaturedImg} alt="img" />
                                                    </Link>
                                                    {/* <Link className="seller" to={source === 1 ? `/collection/${item.url}` : `/artist/${item.user._id}`}>
                                                        <div className="seller-thumb avatar-lg">
                                                            <img className="rounded-circle" src={source === 1 ? item.logo : (item.user.profilePhoto ? item.user.profilePhoto : userDefaultImg)} alt={source === 1 ? 'Collection Logo' : 'User Avatar'} />
                                                        </div>
                                                    </Link> */}
                                                </div>
                                                <div className="card-caption">
                                                    <div className="card-body mt-4">
                                                        <Link to={`/collection/${item.url}`} data-effect="float" data-tip={item.name}>
                                                            <h5>{item.name}</h5>
                                                        </Link>
                                                        {/* <ReactTooltip /> */}
                                                        <div className="hidden-content justify-content-between align-items-center">
                                                            {/* <span>{item.category ? item.category.name : 'Category: N/A'}</span> */}
                                                            <span className="description-tag">{item.description}</span>
                                                            <span className="next-img"><img src={nextIcon} className="img-fluid" /></span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <span className='like-section {active}'><FontAwesomeIcon icon={faHeart} className="" /> </span>
                                            </div>
                                        </div>
                                    );
                                })
                                :
                                <div className="no-data border mb-4"><p className="text-center">No Collection Found</p></div>
                        }
                    </>
                </div>
                {loadMoreBtn && !props.all ?
                    <div className="row">
                        <div className="col-12 text-center mb-4">
                            <a id="load-btn" className="btn btn-danger offer mt-4" onClick={() => loadMore()}>Load More</a>
                        </div>
                    </div>
                    : ''
                }
            </div>
        </div>
    );
})

const mapStateToProps = state => ({
    collection: state.collection,
    error: state.error
});

export default connect(mapStateToProps, { beforeCollection, getCollections }, null, { forwardRef: true })(Collections)