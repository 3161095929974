import { useEffect, useState } from 'react'
import { ENV } from '../../config/config'
import { Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { beforeFaq, getFaqs } from '../faq/faq.action';
import { connect } from "react-redux";

const HowItWorks = (props) => {

    const [data, setData] = useState(null)

    useEffect(() => {
        window.scroll(0, 0)
        props.getFaqs()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (props.faqs.faqsAuth) {
            const { faqs } = props.faqs.faqs
            setData(faqs)
            props.beforeFaq()
        }
    }, [props.faqs.faqsAuth]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
       <>
        <section className='how-it-works'>
            <div className=" container">
                <div className="row">
                    <Col md={12}>
                        <div className="intro text-center intro-works">
                            <h3 className="mt-3 mb-0">How it works</h3>
                            <p>Learn how Elumnt works and frequently questions asked.</p>
                        </div>
                    </Col>
                    {
                        
                        <Col lg={6} md={12} className="mb-2">
                            <div className='works-custom-heading'>
                                <h2>Getting Started</h2>
                            </div>
                            <div className='how-works-link'>
                                <ul>
                                {data?.map((i) =>
                                    <>
                                        {
                                            i.type === 1 &&
                                            <li><Link to={i._id}>{i.title}</Link></li>
                                        }
                                    </>
                                )}
                                </ul>
                            </div>
                        </Col>
                    }
                    {
                        
                        <Col lg={6} md={12} className="mb-2">
                            <div className='works-custom-heading'>
                                <h2>Elumnt FAQs</h2>
                            </div>
                            <div className='how-works-link'>
                                <ul>
                                {data?.map((i) =>
                                    <>
                                        {
                                            i.type === 3 &&
                                            <li><Link to={i._id}>{i.title}</Link></li>
                                        }
                                    </>
                                )}
                                </ul>
                            </div>
                        </Col>
                    }
                    {
                        
                        <Col lg={6} md={12} className="mb-2">
                            <div className='works-custom-heading'>
                                <h2>Security, Policies, and Safety on Elumnt</h2>
                            </div>
                            <div className='how-works-link'>
                                <ul>
                                {data?.map((i) =>
                                    <>
                                        {
                                            i.type === 2 &&
                                            <li><Link to={i._id}>{i.title}</Link></li>
                                        }
                                    </>
                                )}
                                </ul>
                            </div>
                        </Col>
                    }
                </div>
            </div>
        </section>
       
       
       </>
    )
}

const mapStateToProps = (state) => ({
    faqs: state.faqs,
    error: state.error,
});

export default connect(mapStateToProps, {
    beforeFaq,
    getFaqs
})(HowItWorks);