/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { beforeNFT, getNFTs } from '../nfts/nfts.action';
import { ENV } from '../../config/config';
import FullPageLoader from '../full-page-loader/full-page-loader'
import { Link, useLocation } from 'react-router-dom';
import FilterSearch from "../filter-search/filtersearch";
import ReactTooltip from 'react-tooltip'
import { ipfsToUrl } from '../../utils/functions';
import moment from 'moment';
import SmallChecked from '../../assets/images/tik.png';
import EthIcon from '../../assets/images/icon-ethereum.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faHeart, faArrowRotateRight, faTableCells, faTableCellsLarge } from '@fortawesome/free-solid-svg-icons'
import SearchFilters from '../search-filters/search-filters';
import { useWindowSize } from '../../hooks/useWindowSize';
import { Tabs, Tab, Row, Col, Button, ButtonGroup } from "react-bootstrap"
import Select from 'react-select';
import { useNavigate } from "react-router-dom";

const initData = {
    pre_heading: "Explore",
    heading: "Explore NFTs",
    // content: "Explore the NFTs by professional filter options and top trending.",
    btn_1: "Load More"
}

function ExploreAll(props) {
    const [nftPagination, setNFTPagination] = useState(null);
    const [nfts, setNFTs] = useState([]); // NFTs for explore section
    const [loader, setLoader] = useState(true);
    const [loadMoreBtn, setLoadMoreBtn] = useState(false);
    const [isMoreClicked, setLoadMore] = useState(false); // flag to check if load more button clicked
    const [intro, setIntro] = useState(false);
    const [searchAndFilters, setSearchAndFilters] = useState(false);
    const [nftFilter, setNftFitler] = useState(null);
    const { pathname } = useLocation();
    const [Filter, setFilter] = useState(false);  // flag for responsive view only
    const [showFilter, setShowFilter] = useState(true);
    const { width } = useWindowSize() || {};
    const navigate =useNavigate();
    const userId = ENV.getUserKeys()?._id ? ENV.getUserKeys()?._id : undefined


    useEffect(() => {
        if (width <= 991) {
            setShowFilter(false);
        } else {
            setShowFilter(true);
        }

    }, [width]);

    useEffect(() => {
        window.scroll(0, 0)
        // set intro section
        if (pathname === '/explore-all') {
            setIntro(true)
            setSearchAndFilters(true)
        }

        // get NFTs for explore section
        getNFTs()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    // set NFTs for explore section
    useEffect(() => {
        if (props.nft.nftsAuth) {
            const { nftsData } = props.nft
            if (nftsData) {
                setNFTs(isMoreClicked ? nfts.concat(nftsData.nfts) : nftsData.nfts)
                setNFTPagination(nftsData.pagination)
                if (nftsData.nfts?.length) {
                    setLoadMoreBtn(true)
                }
                if (nftsData.pagination && nftsData.pagination.page === nftsData.pagination.pages) {
                    setLoadMoreBtn(false)
                    if (props.setCollectedNfts) {
                        props.setCollectedNfts(nftsData.pagination.total)
                        props.setCreatedNfts(nftsData.createdNfts)
                    } else if (props.setCreatedNfts)
                        props.setCreatedNfts(nftsData.pagination.total)
                }
                props.beforeNFT()
                setLoader(false)
                setLoadMore(false)
                if (props.setLoader)
                    props.setLoader(false)
            }
        }
    }, [props.nft.nftsAuth]) // eslint-disable-line react-hooks/exhaustive-deps

    const loadMore = () => {
        const { page } = nftPagination
        setLoader(true)
        setLoadMore(true)
        // get more NFTs for explore section
        getNFTs(page + 1, 40, nftFilter)
    }

    const getNFTs = (page = 1, limit = 40, filter = null) => {
        let nftQS = { page, limit, explore: true }

        if (filter)
            nftQS = { ...nftQS, ...filter }

        if (props.collectionId)
            nftQS.collectionId = props.collectionId

        if (props.authorId)
            nftQS.authorId = props.authorId

        if (props.creatorId)
            nftQS.creatorId = props.creatorId

        if (props.ownerId)
            nftQS.ownerId = props.ownerId

        if (props.collectorId)
            nftQS.collectorId = props.collectorId

        nftQS.isFavourite = true

        const qs = ENV.objectToQueryString(nftQS)
        props.getNFTs(qs)
    }

    // apply search & filters
    useEffect(() => {
        if (props.filter)
            applyFilters(props.filter)
    }, [props.filter]) // eslint-disable-line react-hooks/exhaustive-deps

    const applyFilters = (filter) => {
        if (filter) {
            setLoader(true)
            setNftFitler(filter)
            getNFTs(1, 40, filter)
        }
    }

    const getLeftDate = (date) => {
        let time = moment(date).fromNow(true);
        return (time + ' Left')
    }



    return (
        <section className="explore-area pt-0">
            {loader && <FullPageLoader />}
            <div className="container-fluid p-0">

                <Row className='explore-filters'>
                    <Col md={12}>
                        <SearchFilters setShowFilter={setShowFilter} showFilter={showFilter} filters={true} />
                    </Col>
                </Row>
                <div className={` ${Filter ? 'active' : ''} d-flex collection-wrapper`}>
                    {/* <button className="side-toggle-btn" onClick={() => setFilter(!Filter)}><i className="fas fa-sort-amount-down-alt" /></button> */}
                    {showFilter && <div className="items-sidebar explore-seidebar">
                        <FilterSearch applyFilters={applyFilters} items={true} activity={false} properties={true} />
                    </div>}

                    <div className={`items-nfts ${showFilter ? "" : "no-sidebar"} `}>
                        <div className="reverse-section d-flex justify-content-between align-items-center">
                            <div className="reset-div">
                                <span><FontAwesomeIcon icon={faArrowRotateRight} className="cursor-pointer" /></span>
                                <span className="ml-2">125 Items</span>
                            </div>
                            <div className='d-flex align-items-center grid-main'>
                                <span className="grid-section">
                                    <ButtonGroup aria-label="Basic example">
                                        <Button className='active'>
                                            <FontAwesomeIcon icon={faTableCellsLarge} />
                                        </Button>
                                        <Button>
                                            <FontAwesomeIcon icon={faTableCells} />
                                        </Button>
                                    </ButtonGroup>
                                </span>
                                <button className="login-btn">Make Collection Offer</button>
                            </div>
                        </div>
                        <div className="nfts-collection-wrapper w-100">
                            <div className="items explore-items">
                                {nfts && nfts.length > 0 ?
                                    nfts.map((item, idx) => {
                                        return (
                                            <div key={`edth_${idx}`} className={`item explore-item`} data-groups={item.group ? item.group : '["art","sports"]'}>
                                                <div className="card">
                                                    <div className="image-over">
                                                        <Link to={`/item-details/${window.btoa(item._id)}`}>
                                                            <img className="card-img-top" src={ipfsToUrl(item.image)} alt="img" />
                                                        </Link>
                                                    </div>
                                                    <div className="card-caption">
                                                        <div className="card-body">
                                                            <div className="nft-name d-flex justify-content-between align-items-center">
                                                                <Link to={`/item-details/${window.btoa(item._id)}`} className="d-flex align-items-center">
                                                                    <h5 className="mb-0">{item.name}</h5>
                                                                    <span className="number ml-2">#42</span>
                                                                </Link>
                                                                <div className="likes-section d-flex align-items-center">
                                                                    <span className='heart-icon {active}'><FontAwesomeIcon icon={faHeart} /></span>
                                                                    <span className='likes-count ml-1'>100</span>
                                                                </div>
                                                            </div>
                                                            <div className='nft-name d-flex align-items-center'>
                                                                <Link to="">Name</Link>
                                                                <span className='banner-checked ml-1'><img className="img-fluid" src={SmallChecked} /></span>
                                                            </div>
                                                            <div className="biding-section d-flex justify-content-between align-items-center">
                                                                <div className="biding-section-in d-flex align-items-center">
                                                                    <div className="biding-data">
                                                                        <span className="purple-span">Price</span>
                                                                        <span className="price">12 ETH</span>
                                                                    </div>
                                                                    <div className="biding-data ml-3">
                                                                        <span className="purple-span">Last</span>
                                                                        <span className="price">12 ETH</span>
                                                                    </div>
                                                                </div>
                                                                <div className="biding-section-in d-flex align-items-center">
                                                                    <div className="biding-data">
                                                                        <span className="purple-span">Highest Bid</span>
                                                                        <span className="price">12 ETH</span>
                                                                    </div>
                                                                    <div className="eth-img ml-2">
                                                                        <img className="img-fluid" src={EthIcon} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="buy-section text-center" onClick={() => navigate(`/item-details/${window.btoa(item._id)}`)}>
                                                                {item.sellingMethod ?
                                                                    (userId == item?.owner?._id
                                                                        ? <Link className="buy-btn" to={`/item-details/${window.btoa(item._id)}`}>{"View Details"}</Link>
                                                                        : <Link className="buy-btn" to={`/item-details/${window.btoa(item._id)}`}>{(item?.sellingMethod) === 1 ? "Buy Now" : (item?.sellingMethod) === 2 ? "Place A Bid" : ""}</Link>)
                                                                    :
                                                                    (userId == item?.owner?._id
                                                                        ? <Link className="buy-btn" to={`/item-details/${window.btoa(item._id)}`}>{"List to sale/bid"}</Link>
                                                                        : <Link className="buy-btn" to={`/item-details/${window.btoa(item._id)}`}>{"Not for sale"}</Link>)
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })
                                    : <div className="no-data border"><p className="text-center">No Favourite Items Found.</p></div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                {loadMoreBtn ?
                    <div className="row">
                        <div className="col-12 text-center mb-3">
                            <a id="load-btn" className="btn btn-danger offer  mt-4" onClick={() => loadMore()}>{initData.btn_1}</a>
                        </div>
                    </div>
                    : ''
                }


                {/* <ExploreItems class1="explore-items" class2="explore-item" key="edth" /> */}
            </div>
        </section>
    )
}

const mapStateToProps = state => ({
    nft: state.nft
});

export default connect(mapStateToProps, { beforeNFT, getNFTs })(ExploreAll);