import React from "react";
import CustomHeadingOne from "../custom-heading-one/CustomHeadingOne";
import { Container, Row, Col } from "react-bootstrap";
import collectionOne from '../../../assets/images/10.png';
import collectionTwo from '../../../assets/images/20.png';
import collectionThree from '../../../assets/images/30.png';
import collectionFour from '../../../assets/images/40.png';
import "../how-it-works/howitworks.css";

function HowItWorks(props) {
  const works = [
    {
      id: 1,
      title: "Set up your wallet",
      paragraph:
        "To begin your NFT journey, the first step is to set up your wallet of choice, connect it to [ elumnt ]by clicking the connect wallet icon inthe top right corner of the website.Learn about the wallets we  support on our platform.",
      image: collectionOne,
    },
    {
      id: 2,
      title: "Create your collections",
      paragraph:
        "To begin your NFT journey, the first step is to set up your wallet of choice, connect it to [ elumnt ]by clicking the connect wallet icon inthe top right corner of the website.Learn about the wallets we  support on our platform.",
      image: collectionTwo,
    },
    {
      id: 3,
      title: "Upload your work",
      paragraph:
        "To begin your NFT journey, the first step is to set up your wallet of choice, connect it to [ elumnt ]by clicking the connect wallet icon inthe top right corner of the website.Learn about the wallets we  support on our platform.",
      image: collectionThree,
    },
    {
      id: 4,
      title: "List your NFTs for sale",
      paragraph:
        "To begin your NFT journey, the first step is to set up your wallet of choice, connect it to [ elumnt ]by clicking the connect wallet icon inthe top right corner of the website.Learn about the wallets we  support on our platform.",
      image: collectionFour,
    },
  ];

  return (
    <>
      <CustomHeadingOne heading="How it works">
        How to create and sell your NFTs on [ elumnt ]
      </CustomHeadingOne>
      <section className="how-it-works">
        <Container fluid className="create-container container">
          <Row>
            {works.map((i, index) => (
              <Col key={index} xl={3} md={6}>
                <div className="setup-card">
                  <div >
                    <img src={i.image} className="card-img img-fluid" alt="card" />
                  </div>
                  <h2>{i.title}</h2>
                  <p>{i.paragraph}</p>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
    </>
  );
}

export default HowItWorks;
