import React, { useEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import { Link, useNavigate, createSearchParams } from 'react-router-dom';
import Ethereum from '../../assets/images/arow-icon.png';
import Binance from '../../assets/images/binancelogo.png';
import Fantom from '../../assets/images/fantomlogo.png';
import Cronos from '../../assets/images/cronoslogo.png';


const ChooseBlockchain = () => {
    const navigate = useNavigate();
    useEffect(() => {
        window.scroll(0, 0)
    }, [])
    return (
        <div className="explore-area">
            <Container>
                <Row>
                    <Col md={12}>
                        <div className="intro">
                            <div className="intro-content text-center">
                                <span className='mb-5'>Create. Mint. Sell.</span>
                                <h3>Choose Blockchain</h3>
                                <p>Choose the most suitable blockchain for your needs. You need to connect wallet for to mint an NFT.</p>
                                <Link to={'/how-it-works'}>Learn more about blockchains here.</Link>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <div className="blockchain-section d-flex align-items-center justify-content-center">
                            <div className="blockchains text-center"
                                onClick={
                                    () => navigate({
                                        pathname: "/choose-mint/1",
                                        // search: createSearchParams({
                                        //     blockChain: 1
                                        // }).toString()
                                    })
                                }
                            >
                                <div className="blockchain-img">
                                    <img className="img-fluid" src={Ethereum} />
                                </div>
                                <div className="blockchain-name">
                                    <span>Ethereum</span>
                                </div>
                            </div>
                            <div className="blockchains text-center" onClick={
                                () => navigate({
                                    pathname: "/choose-mint/2",
                                    // search: createSearchParams({
                                    //     blockChain: 2
                                    // }).toString()
                                })
                            }>
                                <div className="blockchain-img">
                                    <img className="img-fluid" src={Binance} />
                                </div>
                                <div className="blockchain-name">
                                    <span>Binance</span>
                                </div>
                            </div>
                            <div className="blockchains text-center" onClick={
                                () => navigate({
                                    pathname: "/choose-mint/3",
                                    // search: createSearchParams({
                                    //     blockChain: 3
                                    // }).toString()
                                })
                            }>
                                <div className="blockchain-img">
                                    <img className="img-fluid" src={Fantom} />
                                </div>
                                <div className="blockchain-name">
                                    <span>Fantom</span>
                                </div>
                            </div>
                            <div className="blockchains text-center" onClick={
                                () => navigate({
                                    pathname: "/choose-mint/4",
                                    // search: createSearchParams({
                                    //     blockChain: 4
                                    // }).toString()
                                })
                            }>
                                <div className="blockchain-img">
                                    <img className="img-fluid" src={Cronos} />
                                </div>
                                <div className="blockchain-name">
                                    <span>Cronos</span>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default ChooseBlockchain