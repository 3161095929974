import React, { useEffect, useSyncExternalStore } from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import { Link, useNavigate, createSearchParams, useSearchParams,useParams } from 'react-router-dom';
import singleMint from '../../assets/images/single-mint.png';
import multiMint from '../../assets/images/multi-mint.png';

const ChooseMint = () => {
    const navigate = useNavigate();
    // const params = new URLSearchParams(window.location.search)
    let params = useParams();

    useEffect(() => {
        window.scroll(0, 0)
    }, [])
    return (
        <div className="explore-area">
            <Container>
                <Row>
                    <Col md={12}>
                        <div className="intro">
                            <div className="intro-content text-center">
                                <span>Share to the world.</span>
                                <h3>Choose Mint Type</h3>
                                <p>Choose “Single Mint” for one of a kind or “Multiple Mints” if you want to sell one collectible multiple times</p>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <div className="mint-section d-flex align-items-center justify-content-center">
                            <div className="mints text-center"
                                onClick={
                                    () => navigate({
                                        pathname: `/create/${params.blockChain}/1`,
                                        // search: createSearchParams({
                                        //     blockChain: params.get('blockChain'),
                                        //     mintType: 1,
                                        // }).toString()
                                    })
                                }
                            >
                                <div className="mint-img">
                                    <img className="img-fluid" src={singleMint} />
                                </div>
                                <div className="mint-content">
                                    <span className="mint-name">Single Mint</span>
                                    <span className="mint-para">Create a unique 1 of 1 mint of your item.</span>
                                    <Link className="" to="" >Learn more</Link>
                                </div>
                            </div>
                            <div className="mints text-center"
                                onClick={
                                    () => navigate({
                                        pathname: `/create/${params.blockChain}/2`,
                                        // pathname: "/create",
                                        // search: createSearchParams({
                                        //     blockChain: params.get('blockChain'),
                                        //     mintType: 2,
                                        // }).toString()
                                    })
                                }
                            >
                                <div className="mint-img">
                                    <img className="img-fluid" src={multiMint} />
                                </div>
                                <div className="mint-content">
                                    <span className="mint-name">Multiple Mint</span>
                                    <span className="mint-para">Allow your NFT to be minted more than once, allowing multiple community members representing your NFT.</span>
                                    <Link className="" to="" >Learn more</Link>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default ChooseMint