import React, { useState } from 'react'
import Select from 'react-select';
import toggleImg from '../../assets/images/bars-toggle.png';
import searchIcon from '../../assets/images/search-icon.png';
import toggleBar from '../../assets/images/bars-icon.png';

import './search-filters.css';
const optionsFour = [
  { value: 'priceLow', label: 'Price: Low to High ' },
  { value: 'priceHigh', label: 'Price: High to Low' },
];

const SearchFilters = (props) => {
  const [selectedOptionFour, setSelectedOptionFour] = useState({ value: 'priceRange', label: 'Price: Low to High' });

  const resetFilters = () => {
    props.resetAllFilters(true)
  }

  return (
    <div className="search-filters mb-lg-5">
      <div className='d-flex align-items-center search-filters-in'>
        {props.draft ?
          <span className="toggle-button cursor-pointer">
            <img src={toggleBar} className="img-fluid" />
          </span>

          : <span className="toggle-button cursor-pointer" onClick={() => { props.setShowFilter(!props.showFilter) }}>
            {(props.showFilter) ?
              <img src={toggleImg} className="img-fluid" />
              :
              <img src={toggleBar} className="img-fluid" />
            }
          </span>}

        {props.filters ?
          <div className=''>
            <div className='d-flex justify-content-between align-items-center filters-one flex-wrap'>
              {
                props.applyFilterData &&
                <button
                  onClick={() => resetFilters()}
                  className='reset-btn selected-filters'>
                  Reset filters
                </button>
              }

              {
                props.applyFilterData && props.applyFilterData.map((item, index) => {
                  return (
                    <div className="selected-filters" key={index}>
                      <span>{item.title}</span>
                    </div>
                  )
                })
              }
            </div>
            {
              props.collection &&
              <div className='d-flex justify-content-between align-items-center filters-two'>
                <div className="search-section">
                  <input type="search" className="search-bar" placeholder="Search collection by name or attribute" />
                  <span className='search-icon'><img src={searchIcon} className="img-fluid" /></span>
                </div>
                <Select
                  defaultValue={selectedOptionFour}
                  onChange={setSelectedOptionFour}
                  options={optionsFour}

                />
              </div>
            }

          </div>
          :
          ""
        }

      </div>
    </div >
  )
}

export default SearchFilters