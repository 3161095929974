import React, { useEffect, useState, useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { beforeHome, getNotableCollections } from '../../homepage/home.action';
import FullPageLoader from "../../../components/full-page-loader/full-page-loader";
import tikIcon from '../../../assets/images/tik.png';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import '../collection-card/collection-cards.css'
import { ENV } from '../../../config/config';
const itemPlaceholderImg = ENV.collectionFeaturedImg

const CollectionCards = (props) => {
    const [collection, setCollection] = useState(undefined)

	useEffect(() => {
	  props.getNotableCollections();
	}, [])

	useEffect(() => {
		if(props.home.notableCollectionAuth){
			setCollection(props.home.notableCollection)
			props.beforeHome()
		}
	}, [props.home.notableCollectionAuth])


    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 4
        },
        tablet: {
            breakpoint: { max: 1024, min: 576 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 575, min: 0 },
            items: 1
        }
    };
    return (
        <>
			{collection === undefined
				&& 
					<FullPageLoader />}
            <section className="Statistics">
                <Container fluid>
                    {collection != undefined && collection.length ?
                        <Carousel responsive={responsive} showDots={true}>
                            {
                                collection.map((e, index) => {
                                    return (
                                        <Link to={`/collection/${e.url}`}>
                                            <div key={index} className='collection-card'>
                                                <div className='fade-overlay'></div>
                                                <div className='collection-overlay'>
                                                    <h3>{e?.name}</h3>
                                                    <div className='d-flex'>
                                                        <span >By:</span><Link className='pl-1' to={`/artist/${e.creator?._id}`}> {e.creator?.username}</Link>
                                                        {e.creator?.adminVerified && tikIcon ? <img className='tik-icon img-fluid' src={tikIcon} alt="card" /> : ''}
                                                    </div>
                                                </div>
                                                <img  src={e?.logo ? e?.logo: itemPlaceholderImg} className="card-img" alt="card" />
                                            </div>
                                        </Link>
                                    )
                                })
                            }
                        </Carousel>
                        :
                        <div className="no-data border"><p className="text-center">No Record Found</p></div>
                    }
                </Container>
            </section>
        </>
    )
}

const mapStateToProps = state => ({
	home: state.home,
});

export default connect(mapStateToProps, { beforeHome, getNotableCollections })(CollectionCards);