import React, { useEffect, useState, useRef } from "react";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Col, Container, Row, Form, Card } from "react-bootstrap";
import { connect } from 'react-redux';
import { beforeHome, getBannerNft } from '../../homepage/home.action';
import { favoriteToggle, beforeNFT } from '../../nfts/nfts.action'
import { ENV } from '../../../config/config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeart } from '@fortawesome/free-solid-svg-icons'
import FullPageLoader from "../../../components/full-page-loader/full-page-loader";
import Ethereum from '../../../assets/images/arow-icon.png';
import Binance from '../../../assets/images/binancelogo.png';
import Fantom from '../../../assets/images/fantom.png';
import arowImage from '../../../assets/images/arow-icon.png'
import Cronos from '../../../assets/images/cronos.png';
import '../custom-card/custom-card.css'
import checked from '../../../assets/images/tik.png';

const itemPlaceholderImg = ENV.userDefaultImg

const CustomCard = (props) => {

	const [nft, setNft] = useState(undefined)
	const [isFavourite, setIsFavourite] = useState(false);
	const [likesCount, setLikesCount] = useState(0);
	const [loader, setLoader] = useState(false)

	useEffect(() => {
		setLoader(true)
		props.getBannerNft();
	}, [])

	useEffect(() => {
		if (props.home.bannerNftAuth) {

			setNft(props.home.bannerNft)
			setIsFavourite(props.home.bannerNft.isFavourite)
			setLikesCount(props.home.bannerNft.likesCount)
			setLoader(false)
			props.beforeHome()
		}

	}, [props.home.bannerNftAuth])

	useEffect(() => {
		if (props.error) {
			setLoader(false)
		}
	}, [props.error])


	const handleFavouriteClick = (e) => {
		if (isFavourite === true) {
			setIsFavourite(false)
			setLikesCount(likesCount - 1)
		} else {
			setIsFavourite(true)
			setLikesCount(likesCount + 1)
		}

		const data = {
			nftId: nft._id
		}
		props.favoriteToggle(data)
	}

	return (
		<>
			{loader
				&&
				<FullPageLoader />}

			<div className='custom-card '>
				<Card className='shadow-lg'>
					{nft != undefined && nft !== null ?
						<>
							<Link to={`/item-details/${window.btoa(nft._id)}`}>
								<Card.Img variant="top" src={nft.image ? nft.image : itemPlaceholderImg} />
							</Link>
							<Card.Body>
								<div className='card-content'>
									<h2>{nft.name}</h2>
									<div className='d-flex align-items-center'>
										<div className="flex-grow-1 custom-card-link">
											By:&nbsp; <Link to={`/artist/${nft.creator?.username}`}> {nft.creator?.username}</Link>
											{nft.creator?.adminVerified && <span className='banner-checked-1 ml-1'><img className="img-fluid" src={checked} /></span>}
										</div>
										<div className='d-flex align-items-center'>
											<div className=" d-flex align-items-center pr-3">
												<span>{nft.currentPrice}</span>
												{nft.blockChain === 1 ? <img src={Ethereum} className="img-fluid " alt="Arow" /> : null}
												{nft.blockChain === 2 ? <img src={Binance} className="img-fluid " alt="Arow" /> : null}
												{nft.blockChain === 3 ? <img src={Fantom} className="img-fluid " alt="Arow" /> : null}
												{nft.blockChain === 4 ? <img src={Cronos} className="img-fluid " alt="Arow" /> : null}
											</div>
											<span className="icon-circle-heart d-flex justify-content-center align-items-center">
												{isFavourite ? <FontAwesomeIcon icon="fa-heart" onClick={handleFavouriteClick} style={{ "color": "red" }} /> : <FontAwesomeIcon icon="fa-heart" onClick={handleFavouriteClick} />}
											</span>
										</div>
									</div>
								</div>
							</Card.Body>
						</>
						:
						<>
							<div className="no-data border"><p className="text-center">No Record Found</p></div>
						</>
					}
				</Card>
			</div>
		</>
	)
}

const mapStateToProps = state => ({
	home: state.home,
	error: state.error
});

export default connect(mapStateToProps, { beforeHome, getBannerNft, favoriteToggle, beforeNFT })(CustomCard);