import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import CustomHeadingOne from "../custom-heading-one/CustomHeadingOne";
import learnMoreImage from "../../../assets/images/learnmoreaboutelumntphoto.jpg";
import CustomButton from "../custom-button/CustomButton";
import '../learn-more/learnmore.css'

function LearnMore() {
  return (
    <>
      <section className="learn-more">
        <Container fluid className="custom-container">
          <Row>
            <Col md={12} className="text-center">
              <div className="learn-more-heading"><CustomHeadingOne heading="Learn more about elumnt" /></div>
            </Col>
            <Col md={12}>
              <div><img src={learnMoreImage} className="card-img" alt="card" /></div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default LearnMore;
