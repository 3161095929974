import React, { useEffect, useState, useRef } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Container, Row, Col, Navbar, Dropdown, ListGroup, Modal, Form } from 'react-bootstrap';
import { io } from "socket.io-client";
import { Nav, Button } from "react-bootstrap";
import { Images } from '../../../assets/asset';
import { connect } from 'react-redux';
import { searchNft, beforeNFT } from '../../nfts/nfts.action'
import { setWalletAddress } from '../../wallet/wallet.action'
import { getNotificationsCount, getUnReadCount, beforeUser } from '../../user/user.action';
import { beforeCategory, getCategories } from '../../categories/categories.action'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faCopy, faSearch, faXmark } from '@fortawesome/free-solid-svg-icons'
import Ethereum from '../../../assets/images/arow-icon.png';
import Binance from '../../../assets/images/binancelogo.png';
import Fantom from '../../../assets/images/fantomlogo.png';
import Cronos from '../../../assets/images/cronoslogo.png';
import messageIcon from '../../../assets/images/message-icon.png';
import copyImage from '../../../assets/images/copy-icon.png';
import bellIcon from '../../../assets/images/bell-icon.png';
import userImg from '../../../assets/images/avatarplaceholder.jpg';
import metaImg from '../../../assets/images/meta-img.png';
import { ENV } from './../../../config/config';
import arowImage from '../../../assets/images/arow-icon.png'
import { useWeb3React } from '@web3-react/core';
import Notifications from "../../notifications/notifications";
import ChatsModal from "../../chat/ChatsModal";
import { getSettings, beforeSettings } from "../../home/footer/footer.action";
import { useDispatch, useSelector } from "react-redux";
import { getAddressWithChain, getWeb3, switchBlockChain } from '../../../utils/web3'
import { toast } from 'react-toastify';
import {
	multiChainBalance
} from "../../../utils/web3";
import "./header.css"
import finalPropsSelectorFactory from 'react-redux/es/connect/selectorFactory';
import collections from '../../collections/collections';
import MiniLoader from '../../full-page-loader/mini-loader/MiniLoader';
const { chainsConfigs, numberToChainId } = ENV;



const collectionPlaceholderImg = ENV.collectionFeaturedImg
const authorPlaceholderImg = ENV.userDefaultImg

const currenciesImages = {
	1: Ethereum, 5: Ethereum,
	4002: Fantom, 205: Fantom,
	338: Cronos, 25: Cronos,
	97: Binance, 56: Binance,
};

const chainText = {
	1: 'Ethereum', 5: 'Ethereum',
	4002: 'Fantom', 205: 'Fantom',
	338: 'Cronos', 25: 'Cronos',
	97: 'Binance', 56: 'Binance',
};

function Header(props) {
	const [fundModal, setFundModal] = useState(false);
	const [miniLoader, setMiniLoader] = useState(true)
	const socket = useRef();
	const fundClose = () => setFundModal(false);
	const fundShow = () => setFundModal(true);
	const navigate = useNavigate();
	const { pathname } = useLocation();
	const userId = ENV.getUserKeys('_id')?._id;
	const username = ENV.getUserKeys('username')?.username;

	const user = ENV.getUserKeys('_id username profileImage');

	const [nftData, setNftData] = useState(null);
	const [collectionData, setCollectionData] = useState(null)
	const [listCheck, setListCheck] = useState(false)
	const [dropCheck, setDropCheck] = useState(true)
	const [spinnerCheck, setSpinnerCheck] = useState(false)
	const [categories, setCategories] = useState(null)
	const [categoriesLoader, setCatLoader] = useState(true) // categories loader
	const [showConnectWalletModal, setShowConnectWalletModal] = useState(false)
	const [notiCount, setNotiCount] = useState(0)
	const [unReadCount, setUnReadCount] = useState(0)
	const [show, setShow] = useState(false);
	const [showOne, setShowOne] = useState(false);
	const [showTwo, setShowTwo] = useState(false);
	const [showThree, setShowThree] = useState(false);
	const [showFour, setShowFour] = useState(false);
	const [chainsBalance, setChainsBalance] = useState(null)
	const [totalAmountInUsd, setTotalAmountInUsd] = useState(null)
	const [sitesettings, setSiteSettings] = useState(null)
	const [notiUser, setNotiUser] = useState([]);
	const [keyword, setKeyword] = useState("")
	const [searchData, setSearchData] = useState({})
	const [check, setCheck] = useState(false)
	const [choosedNetwork, setChoosedNetwork] = useState(localStorage.getItem('chainId'));

	const handleClose = () => setShow(false);



	const handleShow = async () => {
		setShow(true)
		if (props.app) {
			let result = await multiChainBalance(props.app);
 			if (result && result?.totalAmounInUsd)
				setTotalAmountInUsd(result.totalAmounInUsd)

			if (result && result?.balance)
				setChainsBalance(result.balance)
		}
	};
	const handleCloseOne = () => setShowOne(false);
	const handleShowOne = () => setShowOne(true);
	const handleCloseTwo = () => setShowTwo(false);
	const handleShowTwo = () => setShowTwo(true);
	const handleCloseThree = () => setShowThree(false);
	const handleShowThree = () => setShowThree(true);
	const handleCloseFour = () => setShowFour(false);
	const handleShowFour = () => setShowFour(true);


	const { chainId } = useWeb3React();


	const dispatch = useDispatch();
	const settingsProps = useSelector((state) => state.settings);

	useEffect(() => {
		if (Number(chainId) !== choosedNetwork) {
			localStorage.setItem('chainId', chainId);
			setChoosedNetwork(chainId)
		}
	}, [chainId])


	useEffect(() => {
		dispatch(getSettings())
		props.getNotificationsCount()
		props.getUnReadCount()
	}, [])

	useEffect(() => {
		if (props.notiCountAuth) {
			setNotiCount(props.notiCount)
		} else {
			setNotiCount(0)
		}
	}, [props.notiCountAuth])

	useEffect(() => {
		if (props.unReadCountAuth) {
			setUnReadCount(props.unReadCount)
		} else {
			setUnReadCount(0)
		}
	}, [props.unReadCountAuth])

	useEffect(() => {
		if (userId) {
			socket.current = io(ENV.baseUrl, {
				transports: ["websocket"]
			});
			socket.current.emit("add-user", userId);
		}
	}, [userId]);

	useEffect(() => {
		if (socket.current) {
			socket.current.on("msg-recieve", (data) => {
				updatenotification(data, true)
				setUnReadCount(unReadCount + 1)
			});
		}
	}, []);

	const updatenotification = (data, action) => {
		if (action) {
			setNotiUser([...notiUser, data])
		} else {
			if (notiUser.length > 0) {
				let newData = notiUser.filter(item => item.from !== data.from);
				if (newData) {
					setNotiUser(newData)
				}
			}
		}
	}

	// when settings are retrieved
	useEffect(() => {
		if (settingsProps && settingsProps.settingsAuth) {
			setSiteSettings(settingsProps.settings);
			dispatch(beforeSettings())
		}
	}, [settingsProps.settingsAuth])

	let icons = {
		'ETH': Ethereum,
		'BNB': Binance,
		'CRO': Cronos,
		'FTM': Fantom
	}

	const disconnect = () => {
		// deactivate();
		localStorage.clear();
		setDropCheck(false); navigate('/');
	};
	useEffect(() => {
		props.getCategories()
	}, [])

	const formatAddress = (address) => {
		return address ? address.substr(0, 7) + '...' + address.substr(-4) : null;
	}


	useEffect(() => {
		if (props.nft.searchAuth) {
			const { nfts, collections } = props.nft.searchData
			setNftData(nfts)
			setCollectionData(collections)
			props.beforeNFT()
			setSpinnerCheck(false)
		}
	}, [props.nft.searchAuth])

	// when categories data is received
	useEffect(() => {
		if (props.category.getAuth) {
			const { categories } = props.category
			// const allCat = { active: true, name: 'all' }
			// setCategories([allCat, ...categories])
			setCategories(categories)
			props.beforeCategory()
			setCatLoader(false)
		}
	}, [props.category.getAuth])

	const rendList = () => {
		if (!listCheck) {
			return (
				<ListGroup className={listCheck ? `d-none` : ''} id="headerSearchList">
					{
						collectionData && collectionData.length ?
							<ListGroup.Item active>Collections</ListGroup.Item>
							: ''
					}
					{
						collectionData && collectionData.length ?
							collectionData.map((item, index) => {
								return (
									<Link to={`/collection/${item.url}`} key={index}><ListGroup.Item key={index}>{item.name}</ListGroup.Item></Link>
								)
							}) : ''
					}
					{
						nftData && nftData.length ?
							<ListGroup.Item active>Items</ListGroup.Item>
							: ''
					}
					{
						nftData && nftData.length ? nftData.map((item, index) => {
							return (
								<Link to={`/item-details/${window.btoa(item._id)}`} key={index}><ListGroup.Item key={index}>{item.name}</ListGroup.Item></Link>
							)
						}) : ''
					}
				</ListGroup>
			)
		}
	}

	const handleSwithcNetwork = async (chain) => {
		await switchBlockChain(chain)
	}
	const search = () => {
		if (keyword !== "")
			props.searchNft(keyword)
	}

	const copy = () => {
		navigator.clipboard.writeText(localStorage.getItem('connectedAddress'));
		toast.success("Address Copied To Clipboard.");
		handleClose()
	}

	useEffect(() => {
		if (props.nft.searchAuth) {
			setSearchData(props.nft.searchData)
			setCheck(true)
		}
	}, [props.nft.searchAuth])

	const handleOnChange = (event) => {
		setCheck(false)
		setKeyword(event.target.value)
	};

	const CloseSearchDiv = () => {
		setCheck(false)
	};

	const submitHandler = (e) => {
		e.preventDefault();
		search()
	}
	let chainIds = Object.keys(chainsConfigs);
	return (
		<>
			{/* {categoriesLoader && <FullPageLoader />} */}
			{/* <header id="header" className="home-header">
				<div className="header-bottom">
			{/* {categoriesLoader && <FullPageLoader />} */}
			{/* <header id="header" className="home-header">
				<div className="header-bottom">
					<Container className="position-relative">
						<Row className="align-items-center">
							<Col xl="2" lg="2" xs="7" className="navbar-search px-sm-2 px-1">
								<form onSubmit={(e) => { search(e) }}>
									<input type="search" id="site-search" placeholder="Search..." aria-label="Search through site content"
										onChange={(e) => {
											if (!e.target.value) {
												setListCheck(true)
											}
										}}
									/>
									{
										!spinnerCheck ?
											<span onClick={() => {
												if (document.querySelector('#site-search').value) {
													props.searchNft(document.querySelector('#site-search').value)
													setListCheck(false)
													setSpinnerCheck(true)
												}
											}} data-toggle="tooltip" title="Search">
												<i className="fa fa-search"></i>
											</span>
											:
											<div className={'spinner-border'} role="status">
												<span className="sr-only">Loading...</span>
											</div>
									}
								</form>
								{
									rendList()
								}
							</Col>
							
						</Row>
					</Container>
				</div>
			</header> */}
			<>
				<header id="header">
					<div className="nav-wrapper">
						<Navbar collapseOnSelect expand="lg">
							<Container fluid className="custom-container">
								<Navbar.Brand >
									<strong onClick={() => {
										window.location.pathname === '/' ? window.location.reload() : navigate('/')
									}}>
										< a className="navbar-brand text-light" to="/" >
											<span className="d-block logo">[ elumnt ]</span>
											<span className="logo-span">by Pixul</span>
										</a>
									</strong>
								</Navbar.Brand>
								<Navbar.Toggle aria-controls="responsive-navbar-nav">
									<FontAwesomeIcon icon={faBars} className="bars-icon" />
								</Navbar.Toggle>
								<Navbar.Collapse id="responsive-navbar-nav">
									<Nav className="nav-menu ms-auto ">
										<div className="header-rest d-flex align-items-center">
											<div className="d-flex align-items-center header-search-area">
												{/* srearch bar */}
												<div className="search-bar mt-3">
													<Form onSubmit={submitHandler}>
														<button className="dropdown-toggle custom-drop-down-toggle"
															type="button"
															id="dropdownMenuButton"
															// data-toggle="dropdown"
															aria-haspopup="true"
															aria-expanded="false">
														</button>
														<Form.Group className="mb-3 search-form-group" controlId="exampleForm.ControlInput1">
															<FontAwesomeIcon icon={faSearch} onClick={search} className="search-icon" />
															<Form.Control type="text" onChange={handleOnChange} placeholder="Search Collections, Artists, NFTs or Users" />
															{check &&
																<>
																	{keyword !== "" &&
																		<>
																			{searchData.nfts?.length > 0 || searchData.collections?.length > 0 || searchData.creators?.length > 0 ?
																				<div className="dropdown">
																					<div className="dropdown-menu custom-dropdown-menu-1 show" aria-labelledby="dropdownMenuButton">
																						<div className='search-close-icon'><FontAwesomeIcon icon={faXmark} onClick={CloseSearchDiv} /></div>
																						{searchData.nfts?.length > 0 ?
																							<>
																								<p className='dropdown-category-heading'>NFTs</p>
																								{searchData.nfts.map((item, index) => {
																									return (
																										<>
																											<div className='d-flex align-items-center mb-3 px-3' key={index}>
																												<div className='search-image'> <img src={item.image ? item.image : collectionPlaceholderImg} className="img-fluid" alt="image" /> </div>
																												<a className="dropdown-item" href={`/item-details/${window.btoa(item._id)}`}>{item.name}</a>
																											</div>

																										</>
																									)

																								})}
																							</>
																							:
																							null
																						}
																						{searchData.collections?.length > 0 ?
																							<>
																								{/* <li className="dropdown-divider"></li> */}
																								<p className='dropdown-category-heading'>Collections</p>
																								{searchData.collections.map((item, index) => {
																									return (
																										<>
																											<div className='d-flex align-items-center mb-3 px-3' key={index}>
																												<div className='search-image'><img src={item.logo ? item.logo : collectionPlaceholderImg} className="img-fluid" alt="image" /></div>
																												<a className="dropdown-item" href={`/collection/${item.url}`}>{item.name}</a>
																											</div >
																										</>

																									)
																								})}
																							</>
																							:
																							null
																						}
																						{
																							searchData.creators?.length > 0 ?
																								<>
																									<p className='dropdown-category-heading'>Authors</p>
																									{searchData.creators.map((item, index) => {
																										return (
																											<>
																												<div className='d-flex align-items-center mb-3 px-3' key={index}>
																													<div className='search-image'><img src={item.profileImage ? item.profileImage : authorPlaceholderImg} className="img-fluid" alt="image" /></div>
																													<a className="dropdown-item" href={`/artist/${item.username}`}>{item.username}</a>
																												</div >
																											</>
																										)
																									})}
																								</>
																								:
																								null
																						}
																					</div >
																				</div >
																				:
																				<div className="dropdown">
																					<div className="dropdown-menu custom-dropdown-menu-1 show text-center" aria-labelledby="dropdownMenuButton">
																						<p>No Search Found</p>
																					</div>
																				</div>
																			}

																		</>
																	}
																</>

															}

														</Form.Group >
													</Form >
												</div >
												<div className="d-flex align-items-center search-rest">
													{/* explore button*/}
													<button type="button" className='explore-button' onClick={() => handleShowFour()}>
														Explore
													</button>
													<Modal show={showFour} onHide={handleCloseFour} className="menu-modal explore-modal">
														<span className="modal-cross-icon"><FontAwesomeIcon icon={faXmark} className="cross-icon-in" onClick={handleCloseFour} /></span>
														<Modal.Body>
															<Nav className="nav-menu ">
																<li onClick={handleCloseFour}><Link className="nav-link active"
																	to="/explore-all"
																>All NFTs</Link></li>
																{
																	categories && categories.map((category, index) => {
																		return (
																			<li key={index} onClick={handleCloseFour}>
																				<Link className="nav-link active" to={`/category/${category.slug}`}>
																					{category.name}
																				</Link>
																			</li>
																		)
																	})
																}

																{/* <li><Link className="nav-link active" to="/">All NFTs</Link></li>
														<li><Link className="nav-link" to="/">All Collections</Link></li>
														<li><Link className="nav-link" to="/">Art</Link></li>
														<li><Link className="nav-link" to="/">PFP</Link></li>
														<li><Link className="nav-link" to="/">Collectibles</Link></li>
														<li><Link className="nav-link" to="/">Domain Names</Link></li>
														<li><Link className="nav-link" to="/">Music</Link></li>
														<li><Link className="nav-link" to="/">Utility</Link></li>
														<li><Link className="nav-link" to="/">Metaverse</Link></li>
														<li><Link className="nav-link" to="/">Photography</Link></li>
														<li><Link className="nav-link" to="/">Sports</Link></li>
														<li><Link className="nav-link" to="/">Trading Cards</Link></li> */}
															</Nav>
														</Modal.Body>
													</Modal>
													{/* create button*/}
													<button className='create-button'
														onClick={() => navigate('/choose-blockchain')}
													>Create</button>
													{/* notifications icon */}
													<button type="button" className='top-icon-2 notification-icon' onClick={() => handleShowThree()} >
														<img src={bellIcon} alt='' className='img-fluid' />
														{notiCount > 0
															&& <div className='notifiction-badge'>{notiCount}</div>
														}
													</button>
													<Notifications status={showThree} closeModal={() => handleCloseThree()} />
													{/* message icon */}
													<button type="button" className='top-icon top-icon-1 ms-2 notification-icon' onClick={() => handleShowOne()}>
														<img src={messageIcon} alt='' className='img-fluid' />
														{unReadCount > 0
															&& <div className='notifiction-badge'>{unReadCount}</div>
														}
														{/* <span className="value-span">12</span> */}
													</button>
													<ChatsModal notiUser={notiUser} status={showOne} closeModal={() => handleCloseOne()} />
												</div>
											</div >
											{(localStorage.getItem('encuse') && dropCheck) &&
												<>
													<button type="button" className='login-btn' onClick={() => handleShow()}>
														<i className="icon-wallet mr-1 mr-md-2" /><span className="wallet-address-in">{formatAddress(localStorage.getItem('connectedAddress'))}</span>
													</button>
													{/* <div className='pl-4'>
												<img
													src={currenciesImages[Number(choosedNetwork)] ? currenciesImages[Number(choosedNetwork)] : currenciesImages[Number(1)]}
													className='img-fluid' />
											</div> */}
													<Dropdown onSelect={handleSwithcNetwork}>
														<Dropdown.Toggle variant="none" id="dropdown-basic nav-dropdown" className='text-center'>
															<img
																src={currenciesImages[Number(choosedNetwork)] ? currenciesImages[Number(choosedNetwork)] : currenciesImages[Number(1)]}
																className='img-fluid' />
														</Dropdown.Toggle>
														<Dropdown.Menu container="body">
															{
																chainIds.map((chain, idx) => {
																	return <Dropdown.Item key={idx} eventKey={chain}>{chainsConfigs[parseInt(chain)].title}</Dropdown.Item>
																})
															}
														</Dropdown.Menu>
													</Dropdown>

													<Modal show={show} onHide={handleClose} className="menu-modal">
														<span className="modal-cross-icon"><FontAwesomeIcon icon={faXmark} className="cross-icon-in" onClick={handleClose} /></span>
														<Modal.Body>
															<div className="username d-flex align-items-center">
																<span className="username-img"><img src={user.profileImage ? user.profileImage : userImg} alt='' className='img-fluid' /></span>
																<span className="username-name">{user.username}</span>
															</div>
															<Nav className="nav-menu">
																<li onClick={handleClose}><Link className={`nav-link ${pathname === '/profile' ? 'active' : ''}`} to={`/artist/${username}`}>Profile</Link></li>
																<li onClick={handleClose}><Link className={`nav-link ${pathname === `/choose-blockchain` ? 'active' : ''}`} to="/choose-blockchain">Create</Link></li>
																<li onClick={handleClose}><Link className={`nav-link ${pathname === `/my-collections` ? 'active' : ''}`} to="/my-collections">My Collections</Link></li>
																<li onClick={handleClose}><Link className={`nav-link ${pathname === '/artist/' ? 'active' : ''}`} to={`/artist/${username}`}> My Items</Link></li>
																<li onClick={handleClose}><Link className={`nav-link ${pathname === '/trading-history' ? 'active' : ''}`} to="/trading-history">Trading History</Link></li>
 																<li onClick={handleClose}><span className={`nav-link`} onClick={disconnect}>Logout</span></li>
															</Nav>
															{


																totalAmountInUsd >=0 ? <div className="metamask-section">
																	<span className="meta-head">Quick wallet view</span>
																	<div className="meta-sec">
																		<div className="meta-sec-in d-flex justify-content-between align-items-center">
																			<div className="meta-data d-flex align-items-center">
																				<div className="meta-img">
																					<img src={metaImg} alt='' className='img-fluid' />
																				</div>
																				<div className="meta-content">
																					<span className="eth-tag">{chainText[Number(choosedNetwork)]} <span className="greenDot ms-3">.</span></span>
																					<span id="copy-address" className="wallet-address">{formatAddress(localStorage.getItem('connectedAddress'))}</span>
																				</div>
																			</div>
																			<button className="metabutton">
																				<img src={copyImage} alt=''
																					onClick={copy} className='img-fluid' />
																			</button>
																		</div>
																		{totalAmountInUsd && <div className="text-right">
																			<span className="funds-tag">Total Funds: <span>${totalAmountInUsd}</span></span>
																		</div>}
																		<div className='meta-sec-1'>
																			<div className="text-right">
																				<span className="usd-tag">USD</span>
																			</div>

																			<div className="meta-wallet">

																				{
																					chainsBalance && chainsBalance.map((chain, idx) => {
																						return <div key={idx} className="meta-currencies d-flex justify-content-between align-items-center">
																							<div className="meta-c-1 d-flex align-items-center">
																								<span className="meta-c-img"><img className='img-fluid' src={icons[chain?.symbol]} /></span>
																								<span className='meta-c-type ml-2'>{chain?.userBalance} {chain?.symbol}</span>
																							</div>
																							<div className="meta-c-2">
																								<span className="meta-c-price">${chain?.amountInUsd}</span>
																							</div>
																						</div>
																					})}

																				{/* <div className="meta-currencies d-flex justify-content-between align-items-center">
																			<div className="meta-c-1 d-flex align-items-center">
																				<span className="meta-c-img"><img className='img-fluid' src={Binance} /></span>
																				<span className='meta-c-type ml-2'>10 BNB</span>
																			</div>
																			<div className="meta-c-2">
																				<span className="meta-c-price">$10,210</span>
																			</div>
																		</div>
																		<div className="meta-currencies d-flex justify-content-between align-items-center">
																			<div className="meta-c-1 d-flex align-items-center">
																				<span className="meta-c-img"><img className='img-fluid' src={Fantom} /></span>
																				<span className='meta-c-type ml-2'>20981.95 FTM</span>
																			</div>
																			<div className="meta-c-2">
																				<span className="meta-c-price">$5,000</span>
																			</div>
																		</div>
																		<div className="meta-currencies d-flex justify-content-between align-items-center">
																			<div className="meta-c-1 d-flex align-items-center">
																				<span className="meta-c-img"><img className='img-fluid' src={Cronos} /></span>
																				<span className='meta-c-type ml-2'>89266.77 CRO</span>
																			</div>
																			<div className="meta-c-2">
																				<span className="meta-c-price">$10,000</span>
																			</div>
																		</div> */}
																			</div>
																		</div>
																	</div>

																</div> :
																	<div className='quick-wallet-placeholder'><MiniLoader /></div>
															}
															<div className="meta-buttons d-flex justify-content-between align-items-center">
																<button
																	className="metabutton" onClick={setFundModal} >Add Funds</button>
																<button
																	//  onClick="" 
																	className="metabutton" onClick={disconnect}>Disconnect</button>
															</div>
														</Modal.Body>
													</Modal>

												</>
											}
											{
												(!localStorage.getItem('encuse')) &&
												<Button className="login-btn" onClick={() => navigate('/login')}>Connect Wallet</Button>
											}


											<button type="button" className='menu-btn add-header-menu ' onClick={() => handleShowTwo()}>
												<span className='pl-3 '>+</span>
											</button>
											<Modal show={showTwo} onHide={handleCloseTwo} className="menu-modal">
												<span className="modal-cross-icon"><FontAwesomeIcon icon={faXmark} className="cross-icon-in" onClick={handleCloseTwo} /></span>
												<Modal.Body>
													<Nav className="nav-menu">
														<li onClick={handleCloseTwo}><Link className={`nav-link ${pathname === '/' ? 'active' : ''}`} to="/">Home</Link></li>
														<li onClick={handleCloseTwo}><Link className={`nav-link ${pathname === '/explore-all' ? 'active' : ''}`}
															to={"/explore-all"}
														>Explore</Link></li>
														<li onClick={handleCloseTwo}><Link className={`nav-link ${pathname === '/collections' ? 'active' : ''}`} to="/collections"> Collections</Link></li>
														<li onClick={handleCloseTwo}><Link className={`nav-link ${pathname === '/artists' ? 'active' : ''}`} to="/artists"> Artists</Link></li>
														<li onClick={handleCloseTwo}><Link className={`nav-link ${pathname === '/about' ? 'active' : ''}`} to="/about">About</Link></li>
														<li onClick={handleCloseTwo}><Link className={`nav-link ${pathname === '/how-it-works' ? 'active' : ''}`} to="/how-it-works">How it works</Link></li>
														<li onClick={handleCloseTwo}><Link className={`nav-link ${pathname === '/choose-blockchain' ? 'active' : ''}`} to="/choose-blockchain">Create</Link></li>
													</Nav>
													<ul className="gradient-menu">
														{sitesettings?.pixulToken &&
															<li><a target="_blank" className="gradient-link-1" href={`${sitesettings?.pixulToken}`}>Pixul Token</a></li>}
														<li><Link className="gradient-link-3" to="/help-center">Help Center</Link></li>
													</ul>
													{/* <Form className="modal-search-margin">
												<Form.Group>
													<Form.Control type="search" placeholder="Search Collections, Artists, NFTs or Users" />
												</Form.Group>
											</Form> */}
												</Modal.Body>
											</Modal>
										</div >
									</Nav>
								</Navbar.Collapse>


							</Container >
						</Navbar >
					</div >
				</header >
			</>
			<Modal
				size="md"
				aria-labelledby="contained-modal-title-vcenter"
				centered
				show={fundModal}
				onHide={fundClose}
				backdrop="static"
				keyboard={false}
			>
				<Modal.Header>
					<Modal.Title className='funds-title'></Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className='funds-privider'>
						<a target="_blank" href={ENV.transferFundUrl.ethFundTranfer} className='btn btn-primary'>Ethereum</a>
						<a target="_blank" href={ENV.transferFundUrl.fantomFundTranfer} className='btn btn-primary'>Binance</a>
						<a target="_blank" href={ENV.transferFundUrl.cronosFundTranfer} className='btn btn-primary'>Fantom</a>
						<a target="_blank" href={ENV.transferFundUrl.binanceFundTranfer} className='btn btn-primary'>Cronos</a>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary fund-close-btn" onClick={fundClose}>
						Close
					</Button>
				</Modal.Footer>
			</Modal>
			{
				props.wallet && props.wallet.walletError ? <Row>
					<Col md={12} className='p-0'>
						<div id="global-warning" className="global-warning">
							<div className="warning-text position-relative" data-toggle="tooltip" title="Message">
								<div className="close-icon">
									<p>{props.wallet.walletError}</p>
								</div>
							</div>
						</div>
					</Col>
				</Row> : ''
			}
		</>
	);
}

const mapStateToProps = state => ({
	wallet: state.wallet,
	nft: state.nft,
	category: state.category,
	app: state.app,
	error: state.error,
	notiCountAuth: state.user.notiCountAuth,
	notiCount: state.user.notiCount,
	unReadCountAuth: state.user.unReadCountAuth,
	unReadCount: state.user.unReadCount,
});

export default connect(mapStateToProps, { getNotificationsCount, getUnReadCount, beforeCategory, getCategories, setWalletAddress, searchNft, beforeNFT })(Header);