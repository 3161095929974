import React, { useState, useEffect } from "react";
import Web3 from "web3";
import { connect } from 'react-redux';
import Header from '../../components/home/header/Header';
import Breadcrumb from '../../components/breadcrumb/breadcrumb';
import Footer from '../../components/home/footer/Footer';
import ModalSearch from '../../components/modal-search/modal-search';
import ModalMenu from '../../components/modal-menu/modal-menu';
import Scrollup from '../../components/scroll-up/sroll-up';
import { beforeUser } from '../../components/user/user.action'
import { ENV } from '../../config/config'
import { useLocation } from 'react-router-dom'
import { useWeb3React } from "@web3-react/core";
import connectors from '../../components/wallet/connector';

const bannerImg = '/img/placeholder.jpg';


const Layout1 = (props) => {
    const [banner, setBanner] = useState(null)
    const location=useLocation();
    useEffect(() => {
        const defaultBannerScreen = [
            '/integration',
            '/contact',
            '/privacy-and-terms',
            '/how-it-works',
            '/help-center'
        ]
        const key = ENV.getUserKeys('_id');

        if (defaultBannerScreen.includes(props.location?.pathname)) {
            setBanner(null)
        }
        else if (key._id) {
            setBanner(null)
        }

    }, [])
    useEffect(() => {
        if (props.category.getCategory) {
            const { category } = props.category?.category || null
            if (banner !== category?.banner) {
                setBanner(category.banner)
            }
        }
    }, [props.category.getCategory])
    useEffect(() => {
        if (props.collection.getDetailAuth && props.collection.collection) {
            const { collection } = props.collection
            if (banner !== collection?.banner) {
                setBanner(collection.banner)
            }
        }
    }, [props.collection.getDetailAuth, props.collection.collection])

    useEffect(() => {
        if (props.user.individualUserAuth) {
            const user = props.user?.individualUser
            if (((location?.pathname).search('/profile') > -1 || (location?.pathname).search('/artist/') > -1) && banner !== user.bannerImage) {
                setBanner(user.bannerImage)
            }

        }
    }, [props.user.individualUserAuth])
    useEffect(() => {
        if (props.user.bannerAuth) {
            setBanner(props.user.banner)
            props.beforeUser()
        }
    }, [props.user.bannerAuth])
    
    //Web3-react -setup
    const { account, activate, library } = useWeb3React();
    useEffect(() => {
        const provider = window.localStorage.getItem("provider");
        if (provider) {
            const connector = connectors[provider].provider;
            activate(connector, undefined, true)
                .then(async (res) => {
                    const web3Provider = await connector.getProvider();
                    const web3 = new Web3(web3Provider);
                    window.walletPO = web3
                })
                .catch((error) => {
                    //error
                });
        }
    }, [])
    useEffect(() => {
        if (account) {
            localStorage.setItem('connectedAddress', account);
        }
    }, [account])

    useEffect(() => {
        if (library) {
            window.library = library;
        }
    }, [library])

    return (
        <div className="main">
            <Header />
            <Breadcrumb title={props.title} 
            banner={banner || bannerImg}
             />
            {props.children}
            <Footer />
            <ModalSearch />
            <ModalMenu />
            <Scrollup />
        </div>
    );
}
const mapStateToProps = state => ({
    error: state.error,
    collection: state.collection,
    user: state.user,
    category: state.category,
});

export default connect(mapStateToProps, { beforeUser })(Layout1);