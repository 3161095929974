import React, { useEffect, useState } from "react";
import { Container, Row, Col, InputGroup, Form, Button } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTwitter as twitter, faInstagram as instagram, faDiscord as discord,
  faYoutube as youtube, faMedium as medium, faGithub as github, faFacebook as facebook, faLinkedin as linkedin,
  faTelegram as telegram, faReddit as reddit
} from '@fortawesome/free-brands-svg-icons';
import { Link, useLocation } from 'react-router-dom'
import { getSettings, subscribe, beforeSettings } from "./footer.action";
import "../footer/footer.css";
import { connect } from "react-redux";
import produce from 'immer'

function Footer(props) {
  const footerList = [
    { id: 1, list: "Explore", url: "/explore-all" },
    { id: 2, list: "Collections", url: "/collections" },
    { id: 3, list: "Create NFT", url: "/choose-blockchain" },
    { id: 4, list: "How it works", url: "/how-it-works" },
    // { id: 5, list: "Resources", url: "/resources" },
    // { id: 6, list: "Help center", url: "/help-center" },
    { id: 7, list: "Get in touch", url: "/contact" },
  ]
  // const footerListOne = [
  //   { id: 1, list: "DeFi Protocol",url:'#' },
  //   { id: 2, list: "Pixul Token",url:'#' },
  //   { id: 3, list: "xPIXUL Token",url:'#' },
  // ]
  const socialIcons = [
    {
      name: twitter,
      className: "twitter"
    },
    {
      name: instagram,
      className: "instagram"
    },
    {
      name: youtube,
      className: "youtube"
    },
    {
      name: github,
      className: "github"
    },
    {
      name: facebook,
      className: "facebook"
    },
    {
      name: linkedin,
      className: "linkedin"
    },
    {
      name: discord,
      className: "discord"
    }
  ];
  const [settings, setSettings] = useState(null);
  const [footerListOne, setFooterListONe] = useState([
    { id: 1, list: "DeFi Protocol", url: 'https://pixul.app/' },
    { id: 2, list: "Pixul Token", url: 'https://etherscan.io/token/0x5718af2dd07cd76ab0606f66565275f6c29f132f' },
    { id: 3, list: "xPIXUL Token", url: 'https://pixul.app/xpixul' },
  ])
  const [email, setEmail] = useState("")
  const [emailError, setEmailError] = useState("")
  const { pathname } = useLocation();


  useEffect(() => {
    props.getSettings();
  }, [])

  useEffect(() => {
    if (props.settings.settingsAuth) {
      const updateFooterList = produce(footerListOne, draftState => {
        draftState[0].url = props.settings.settings?.defiProtocol
        draftState[1].url = props.settings.settings?.xPixulToken
        draftState[2].url = props.settings.settings?.pixulToken
      })
      setFooterListONe(updateFooterList)
      setSettings(props.settings.settings)
      props.beforeSettings()
    }
  }, [props.settings.settingsAuth])

  const subscribe = () => {
    if (email !== "" && emailValidate())
      props.subscribe({ email: email })
  }

  const submitHandler = (e) => {
    e.preventDefault();

    if (emailValidate)
      subscribe()
  }

  const handleOnChange = (event) => {
    setEmail(event.target.value)
    emailValidate()
  };

  useEffect(() => {
    if (props.settings.subscribeAuth)
      if (props.settings.subscribe)
        setEmail("")
    props.beforeSettings()

  }, [props.settings.subscribeAuth])

  const emailValidate = () => {
    let input = email ? email : '';
    let message = "";
    let isValid = true;

    if (typeof input !== "undefined") {

      var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
      if (!pattern.test(input)) {
        isValid = false;
        message = "Please enter valid email address.";
      }
    }

    setEmailError(message);

    return isValid;
  }


  return (
    <>
      <footer className="footer">
        <Container fluid className="custom-container">
          <Row className="footer-area">
            {pathname === "/" && <><Col md={6} className="mb-5">
              <h2>Get the lastest elumnt news</h2>
              <Form onSubmit={submitHandler} className='custum-header-input'>
                <InputGroup>
                  <Form.Control type="email" onChange={handleOnChange} value={email} aria-label="Recipient's username" aria-describedby="basic-addon2" />
                  <Button onClick={subscribe} className="sign-up" variant="outline-secondary" id="button-addon2">sign up</Button>
                </InputGroup>
                <div className="text-danger">{emailError}</div>
              </Form>
            </Col>
              <Col md={6} className="mb-5">
                <h2 className="text-center">Join elumnt community</h2>
                <ul className="d-flex justify-content-center align-items-center list-unstyled flex-wrap social-links m-0 ">
                  {settings && socialIcons.map((item, index) => {
                    if (settings[item.name.iconName])
                      return <li key={index}>
                        <a href={settings[item.name.iconName]} className={item.className} target="_blank`">
                          <FontAwesomeIcon icon={item.name} />
                        </a>
                      </li>
                    else return ""
                  })}
                </ul>
              </Col>
            </>
            }
            <Col md={6} className="mb-5">
              <h2>[ elumnt ]</h2>
              <p>One of the largest multi-chain digital marketplace for crypto collectibles and non-fungible tokens (NFTs). Discover new and exclusive digital items worldwide.</p>
            </Col>
            <Col md={3} className="mb-5">
              <h2>Marketplace</h2>
              <ul className="list-unstyled">
                {footerList.map((i, index) => (
                  <li key={index}>

                    <Link to={i.url}> {i.list}</Link>
                  </li>)

                )}
              </ul>
            </Col>
            <Col md={3} className="mb-5">
              <h2>Ecosystem</h2>
              <ul className="list-unstyled">
                {footerListOne.map((i, index) =>
                  <li key={index}>
                    <a target="_blank" className="gradient-link-1" href={`${i.url}`}>{i.list}</a>
                    {/* <Link to={i.url}>{i.list}</Link> */}
                  </li>
                )}
              </ul>
            </Col>
          </Row>
          <div className="copy-right"><span>© Pixul, LLC. All Rights Reserved</span></div>
        </Container>
      </footer>
    </>
  );
}

const mapStateToProps = (state) => ({
  settings: state.settings
})
export default connect(mapStateToProps, { getSettings, subscribe, beforeSettings })(Footer);
