import React, { Component, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Link, useParams, useNavigate } from 'react-router-dom'
import $ from 'jquery'
import {
  favoriteToggle,
  beforeNFT,
  getNFT,
  refreshMetadata,
  beforeMetadata,
  beforeCancelListing,
  cancelListing,
  beforeBuy,
  buyNFT,
  transferNft,
} from '../nfts/nfts.action'
import FullPageLoader from '../full-page-loader/full-page-loader'
import { ENV } from '../../config/config'

import {
  acceptOfferBidWeb3,
  makeOfferWeb3,
  placeBidWeb3,
  cancelOfferBidWeb3,
  checkBalance,
  cancelSellingWeb3,
  getChainId,
  transferNFTWeb3,
  buyNowWeb3,
  getPercentagesWeb3,
  switchBlockChain
} from "../../utils/web3";
import moment from 'moment'
import Countdown from 'react-countdown'
import SimpleReactValidator from 'simple-react-validator'
import { Modal, Table } from 'react-bootstrap'
import {
  beforeOffer,
  makeOffer,
  getOffers,
  deleteOffer,
  acceptOffer,
} from '../offers/offers.action'
import {
  beforeBid,
  placeBid,
  getBids,
  deleteBid,
  acceptBid,
} from '../bids/bids.action'
import {
  beforeListing,
  getListings,
} from '../listings/listings.action'
import { beforeHistory, getHistory } from '../history/history.action'
import { toast } from 'react-toastify'
import InfiniteScroll from 'react-infinite-scroll-component'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUndo, faEye, faHeart, faGlobe, faEllipsisVertical, faUser, faUsers, faXmark } from '@fortawesome/free-solid-svg-icons'
import { faDiscord, faTwitter } from '@fortawesome/free-brands-svg-icons'
import ReactTooltip from 'react-tooltip'
import { ipfsToUrl } from '../../utils/functions'
import { confirmAlert } from 'react-confirm-alert'
import miniCollectionImage from '../../assets/images/mini-collection-image.png'
import 'react-confirm-alert/src/react-confirm-alert.css';
import './item-detail.css'
// import detailImage from '../../assets/images/collection-details-lg.png'

import Ethereum from '../../assets/images/arow-icon.png';
import Binance from '../../assets/images/binancelogo.png';
import Fantom from '../../assets/images/fantomlogo.png';
import Cronos from '../../assets/images/cronoslogo.png';
import checked from '../../assets/images/tik.png';
import { axiosSyncPost } from '../../utils/functions';

import {
  faTwitter as twitter, faInstagram as insta, faDiscord as discord,
  faYoutube as youtube, faMedium as medium, faGithub as github,
  faTelegram as telegram, faReddit as reddit,
} from '@fortawesome/free-brands-svg-icons';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
const itemPlaceholderImg = ENV.collectionFeaturedImg
const { chainsConfigs, defaultChainId, numberToChainId } = ENV;

const {
  appName,
  userDefaultImg,
  collectionFeaturedImg,
  countDownRenderer,
  decimalNumberValidator,
  integerNumberValidator,
  tokenStandards,
} = ENV
const currencies = ENV.currencies.filter((elem) => elem.showInBuy === true)
const currenciesImages = { "ETH": Ethereum, "WBNB": Binance, "BNB": Binance, "FTM": Fantom, "CRO": Cronos };
const currenciesTexts = { 1: 'Ethereum', 2: 'Binance', 3: 'Fantom', 4: 'Cronos' };
const blockChainImages = { 1: Ethereum, 2: Binance, 3: Fantom, 4: Cronos };

const initData = {
  bidBtn: 'Place a Bid',
  offerBtn: 'Make Offer',
  sellBtn: 'Sell',
  transferBtn: 'Transfer',
  buyNow: 'Buy Now'
}

// expiry options
const expiryOptions = [
  { label: '5 Days', value: moment().add(5, 'days').format('DD/MM/YYYY') },
  { label: '7 Days', value: moment().add(7, 'days').format('DD/MM/YYYY') },
  { label: 'A Month', value: moment().add(1, 'months').format('DD/MM/YYYY') },
  { label: 'Custom Date', value: -1 },
]

// make an offer / bid config.
const config1 = {
  price: {
    currency: currencies && currencies.length ? currencies[0].value : 'WBNB',
    amount: '',
  },
  expiry: {
    date: expiryOptions[0].value,
    time: moment(new Date()).format('HH:mm'),
    type: 1, // 1 for time & 2 for datetime-local
    datetime: '', // for payload
  },
  cAddress: currencies && currencies.length ? currencies[0].address : '',
  quantity: 1
}

const ItemTypes = {
  1: "NFT Created",
  2: "Offer Made",
  3: "Bid Placed",
  4: "Ownership Transferred",
  5: "Ownership Transferred",
  6: "NFT Listed",
  7: "NFT Transferred",
  8: "Listing Cancelled"
};

const ItemDetails = (props) => {
  const [userId, setUserId] = useState(ENV.getUserKeys()?._id);
  const username = ENV.getUserKeys('username')?.username;
  const [userAddress, setUserAddress] = useState(ENV.getUserKeys('address')?.address) // user wallet address
  const [offers, setOffers] = useState([])
  const [offersPagination, setOffersPagination] = useState(null);
  const [moreCheck, setMoreCheck] = useState(true);
  const [bids, setBids] = useState([]);
  const [bidsPagination, setBidsPagination] = useState(null);
  const [listings, setListings] = useState([])
  const [listingsPagination, setListingsPagination] = useState(null);
  const [page, setPage] = useState(2);
  const [nftHistory, setNFTHistory] = useState([]);
  const [nftHistoryPagination, setNFTHistoryPagination] = useState(null);
  const [rendType, setRendType] = useState(5);
  const [errors, setErrors] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [formValid, setFormValid] = useState(true);
  const [loader, setLoader] = useState(true);
  const [tokenLoader, setTokenLoader] = useState(true);
  const [nft, setNFT] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [nftConfig, setNFTConfig] = useState(config1);
  const [nftId, setNFTId] = useState(null);
  const [minPriceError, setMinPriceError] = useState('');
  const [intiatedTransfering, setIntiatedTransfering] = useState(false);
  const [transferToAddress, setTransferToAddress] = useState('');
  const [transferToCopies, setTransferToCopies] = useState(null);
  const [chainId, setChainIdValue] = useState('');
  const [isFavourite, setIsFavourite] = useState(false);
  const [likesCount, setLikesCount] = useState(0);
  const [quantityModal, setQuantityModal] = useState(false)
  const [itemToBuy, setItemToBuy] = useState(null) // item to buy
  const [qtyToBuy, setQtyToBuy] = useState(0)
  const [quantityMsg, setQuantityMsg] = useState('')
  const [ownersModal, setOwnersModal] = useState(false)
  const [buyNowViaBtn, setBuyNowViaBtn] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [loadingMessageCheck, setLoadingMessageCheck] = useState(false)

  // const [owners, setOwners] = useState([])

  const params = useParams();
  const navigate = useNavigate();

  const validator = new SimpleReactValidator({
    autoForceUpdate: this,
    messages: {
      required: 'This field is required.', // will override all messages
    },
  })

  useEffect(() => {
    if (params.item) {
      setLoader(true)
      init(params)
    }
  }, [params.item]
  )

  useEffect(() => {
    if (props?.error?.cancelListingFailed) {
      setLoadingMessageCheck(false)
      setLoadingMessage('')
      setLoader(false)
      toast.error('Failed to cancel the listing')
      props.beforeCancelListing()
    }

    else if (props?.error?.buyNFTFailed) {
      setLoadingMessageCheck(false)
      setLoadingMessage('')
      setLoader(false)
      toast.error('Failed to cancel the listing')
      props.beforeBuy()
    }
  }, [props.error])

  useEffect(() => {
    if (props.transferedNFT) {
      init(props.params);
    }
  }, [props.transferedNFT])

  useEffect(() => {
    if (props.isFavourite) {
      setIsFavourite(props.isFavourite)
    }
    if (props.likesCount) {
      setLikesCount(props.likesCount)
    }
  }, [props.isFavourite, props.likesCount])

  useEffect(() => {
    if (props?.nft?.cancelListingAuth) {
      toast.success('Your listing has been cancelled successfully')
      props.beforeCancelListing()
      navigate('/explore-all')
    }
    if (props?.nft?.refreshAuth) {
      setLoadingMessageCheck(false)
      setLoadingMessage('')
      setLoader(false)
      props.beforeMetadata()
    }
    if (props?.nft?.buyAuth) {
      props.beforeBuy()
      navigate(`/artist/${username}`)
    }
    if (props?.nft?.nftsAuth && !props?.nft?.nftsData?.length) {
      //set creatorFee
      const nft = props.nft.nftsData;
      setNFT(nft);
      props.beforeNFT()
      setLoadingMessageCheck(false)
      setLoadingMessage('')
      setLoader(false);

      //call unique visit Counts endPoint
      if (nft?._id) {
        let payloadData = {
          nft: nft?._id,
        }
        axiosSyncPost('nfts/visit-count', payloadData);
      }
    }


  }, [props.nft])

  useEffect(() => {
    if (props?.offer?.createAuth) {
      setIsOpen(false)
      props.beforeOffer()
      window.location.reload()
    }

    if (props?.offer?.getAuth) {
      const { pagination } = props.offer
      props.beforeOffer()
      let offersData = props.offer.offers;
      if (offersData && offersData.length) {
        setLoadingMessageCheck(false)
        setLoadingMessage('')
        setLoader(false);
        if (pagination?.page > offersPagination?.page) {
          setOffers([...offers, ...offersData])
          pagination.pages > pagination.page ? setMoreCheck(true) : setMoreCheck(false);

        }
        else if (!offersPagination?.page) {
          setOffers(offersData)
          pagination.pages > pagination.page ? setMoreCheck(true) : setMoreCheck(false);

        } else {
          setMoreCheck(false);
        }
        setOffersPagination(pagination);
      }

      // if (offers.length) {
      //   setLoader(false);
      //   setOffers(offers);
      //   setOffersPagination(pagination);
      //   setMoreCheck(true);
      // } 
      else {
        setLoadingMessageCheck(false)
        setLoadingMessage('')
        setLoader(false);
        setOffersPagination(pagination);
        setMoreCheck(false);
      }
    }

    if (props?.offer?.deleteAuth) {
      props.beforeOffer()
      window.location.reload()
    }

    if (props?.offer?.acceptAuth) {
      setLoadingMessageCheck(false)
      setLoadingMessage('')
      setLoader(false)
      props.beforeOffer()
      navigate(`/collection/${nft.collection.url}`)
    }
  }, [props.offer])

  useEffect(() => {
    if (props?.bid?.createAuth) {
      setIsOpen(false)
      props.beforeBid()
      window.location.reload()
    }

    if (props?.bid?.getAuth) {
      const { pagination, highestBid } = props.bid
      let nftData = { ...nft };
      nftData.highestBid = highestBid
      let bidsData = props.bid.bids;

      props.beforeBid()

      if (bidsData && bidsData.length) {
        setLoadingMessageCheck(false)
        setLoadingMessage('')
        setLoader(false);
        if (pagination?.page > bidsPagination?.page) {
          setBids([...bids, ...bidsData])
          pagination.pages > pagination.page ? setMoreCheck(true) : setMoreCheck(false);

        }
        else if (!bidsPagination?.page) {
          setBids(bidsData)
          pagination.pages > pagination.page ? setMoreCheck(true) : setMoreCheck(false);

        } else {
          setMoreCheck(false);
        }
        setNFT(nftData);
        setBidsPagination(pagination);
      }
      // if (bids && bids.length) {
      //   setLoader(false)
      //   setBids(bids)
      //   setBidsPagination(pagination);
      //   setNFT(nftData);
      //   setMoreCheck(false)
      // }
      else {
        setLoadingMessageCheck(false)
        setLoadingMessage('')
        setLoader(false)
        setBidsPagination(pagination);
        setNFT(nftData);
        setMoreCheck(false)
      }
    }

    if (props?.bid?.deleteAuth) {
      props.beforeBid()
      window.location.reload()
    }

    if (props?.bid?.acceptAuth) {
      setLoadingMessageCheck(false)
      setLoadingMessage('')
      setLoader(false)
      props.beforeBid()
      navigate(`/collection/${nft.collection.url}`)
    }
  }, [props.bid])

  useEffect(() => {
    if (props.listings?.getAuth) {
      const { pagination } = props.listings
      const listingsData = props.listings.listings;

      props.beforeListing()

      if (listingsData?.length) {
        setItemToBuy(listings[0] || null)
        setLoadingMessageCheck(false)
        setLoadingMessage('')
        setLoader(false)
        setListings([...listings, ...listingsData])
        setListingsPagination(pagination)
        setMoreCheck(true)
      } else {
        setLoadingMessageCheck(false)
        setLoadingMessage('')
        setLoader(false)
        setListingsPagination(pagination)
        setMoreCheck(false)
      }
    }
  }, [props.listings])

  useEffect(() => {
    if (props?.nftHistory?.getAuth) {
      const { history, pagination } = props.nftHistory
      props.beforeHistory()
      if (history && history.length) {
        setLoadingMessageCheck(false)
        setLoadingMessage('')
        setLoader(false);
        if (pagination?.page > nftHistoryPagination?.page) {
          setNFTHistory([...nftHistory, ...history])
          pagination.pages > pagination.page ? setMoreCheck(true) : setMoreCheck(false);

        }
        else if (!nftHistoryPagination?.page) {
          setNFTHistory(history)
          pagination.pages > pagination.page ? setMoreCheck(true) : setMoreCheck(false);

        } else {
          setMoreCheck(false);
        }

        setNFTHistoryPagination(pagination);
      } else {
        setLoadingMessageCheck(false)
        setLoadingMessage('')
        setLoader(false);
        setNFTHistoryPagination(pagination);
        setMoreCheck(false);
      }
    }
  }, [props.nftHistory])

  const init = (params) => {
    window.scroll(0, 0)
    if (params && params.item) {
      const { item } = params
      const nftId = window.atob(item)
      setNFTId(nftId);
      if (nftId) {
        reset()
        props.getNFT(nftId)

        const bidQS = ENV.objectToQueryString({ nftId })
        props.getBids(bidQS)

        const offerQS = ENV.objectToQueryString({ nftId })
        props.getOffers(offerQS)

        const listingQS = ENV.objectToQueryString({ nftId })
        props.getListings(listingQS)

        const historyQS = ENV.objectToQueryString({ nftId })
        props.getHistory(historyQS)
      }
    } else {
      navigate('/explore-all')
    }
    setChainId();
  }

  const reset = () => {
    setUserId(ENV.getUserKeys()?._id);
    setOffers([]);
    setOffersPagination(null);
    setMoreCheck(true);
    setBids([])
    setBidsPagination(null);
    setListings([]);
    setListingsPagination(null);
    setPage(2);
    setNFTHistory([]);
    setNFTHistoryPagination(null);
    setRendType(5);
    setErrors('');
    setIsSubmitted(false);
    setFormValid(true);
    setLoader(true);
    setTokenLoader(true);
    setNFT(null);
    setIsOpen(false);
    setNFTConfig(config1);
    setNFTId(null);
    setMinPriceError('')
    setQuantityModal(false)
    setItemToBuy(null)
    setQtyToBuy(0)
    setQuantityMsg()
  }

  const setChainId = async () => {
    const chainId = await getChainId();
    setChainIdValue(chainId);
  }

  const transferNFT = async (e) => {
    e.preventDefault();
    setLoader(true);

    let _nftData = {
      tokenId: nft.tokenId,
      nftId: nft._id,
      address: nft.address,
      nft: nft.address,

      //optionally Add For Case of mintType==2
      copies: nft.transferToCopies,
      tokenStandard: nft.tokenStandard
    }
    // call transfer method
    let nftTransfer = await transferNFTWeb3(_nftData, transferToAddress);
    if (nftTransfer?.transactionHash) {
      toast.success("Onwership of NFT transferred successfully.");
      setIntiatedTransfering(false)
      const payload = {
        nftId: nft._id,
        newOwnerAddress: transferToAddress,
        txHash: nftTransfer.transactionHash,
        chainId: nftTransfer.chainId
      }
      props.transferNft(payload)
      setLoadingMessageCheck(false)
      setLoadingMessage('')
      setLoader(false)
    } else {
      toast.error('Failed to Transfer NFT')
      setLoadingMessageCheck(false)
      setLoadingMessage('')
      setLoader(false)
    }
  }

  const getPrice = (currency, amount, quantity = 1) => {
    currency = currency?.toUpperCase()

    // X to USD
    if (currency === 'BNB' && amount && props.app.rateAuth)
      return ENV.convertXtoY(amount, props.app.rate, quantity)
    else if (currency === 'ETH' && amount && props.app.ethRateAuth)
      return ENV.convertXtoY(amount, props.app.ethRate, quantity)
    else if (currency === 'FTM' && amount && props.app.fantomRateAuth)
      return ENV.convertXtoY(amount, props.app.fantomRate, quantity)
    else if (currency === 'CRO' && amount && props.app.croRateAuth)
      return ENV.convertXtoY(amount, props.app.croRate, quantity)

    // WX to USD
    else if (currency === 'WBNB' && amount && props.app.wbnbRateAuth)
      return ENV.convertXtoY(amount, props.app.wbnbRate, quantity)
    else if (currency === 'WETH' && amount && props.app.wethRateAuth)
      return ENV.convertXtoY(amount, props.app.wethRate, quantity)
    else if (currency === 'WFTM' && amount && props.app.wftmRateAuth)
      return ENV.convertXtoY(amount, props.app.wftmRate, quantity)
    else if (currency === 'WCRO' && amount && props.app.wcroRateAuth)
      return ENV.convertXtoY(amount, props.app.wcroRate, quantity)

    return '0.00'
  }

  const openModal = () => {
    if (userId) {
      setMinPriceError('')
      setQuantityMsg('')
      let tokenConfig = null

      if (currencies && currencies.length && nft.currency) {
        tokenConfig = currencies.find(
          (elem) => elem.value.toUpperCase() === nft.currency.toUpperCase(),
        )

        if (`${nft.currency.toUpperCase()}` === 'CRO') {
          tokenConfig = currencies.find(
            (elem) => elem.value.toUpperCase() === 'WCRO',
          )

        }
        if (`${nft.currency.toUpperCase()}` === 'FTM') {
          tokenConfig = currencies.find(
            (elem) => elem.value.toUpperCase() === 'WFTM',
          )

        }
        if (`${nft.currency.toUpperCase()}` === 'BNB') {
          tokenConfig = currencies.find(
            (elem) => elem.value.toUpperCase() === 'WBNB',
          )

        }
        if (`${nft.currency.toUpperCase()}` === 'ETH'
          //  || `${nft.currency.toUpperCase()}` === 'ETH'
        ) {
          tokenConfig = currencies.find(
            (elem) => elem.value.toUpperCase() === 'WETH',
          )

        }
        if (!tokenConfig)
          tokenConfig = currencies.find(
            (elem) => elem.value.toUpperCase() === 'WBNB',
          )
      }

      setIsSubmitted(false);
      setFormValid(true);
      setErrors('')
      setNFTConfig({
        price: {
          currency: tokenConfig?.value || 'WBNB',
          amount: '',
        },
        expiry: {
          date: expiryOptions[0].value,
          time: moment(new Date()).format('HH:mm'),
          type: 1, // 1 for time & 2 for datetime-local
          datetime: '', // for payload
        },
        cAddress: tokenConfig?.address || currencies[0].address,
        quantity: 1,
      })
      setItemToBuy(listings?.length ? listings[0] : null)
      setIsOpen(true)
    } else {
      navigate('/login')
    }
  }

  const closeModal = () => setIsOpen(false)

  const checkMinimuPrice = () => {
    // COMMENTED TEMPORARILY
    // let priceInUSD = getPrice(nftConfig.price.currency, nftConfig.price.amount)
    // let minimumPrice = getPrice(currency, currentPrice)

    if (parseFloat(nftConfig.price.amount) < parseFloat(nft.currentPrice)) {
      setMinPriceError(`Atleast enter ${nft.currentPrice} ${nft.currency}`)
      setFormValid(false);
      return false
    } else {
      setMinPriceError('');
      setFormValid(true);
    }

    // COMMENTED TEMPORARILY
    // if (parseFloat(priceInUSD) < parseFloat(minimumPrice)) {
    //     setState({
    //         ...state, 
    //         minPriceError: `At Least Enter ${nftConfig.price.currency === "WBNB" ? (minimumPrice / props.app.wbnbRate).toFixed(5) : (minimumPrice / props.app.annRate).toFixed(5)} amount`,
    //         formValid: false
    //     })
    //     return false;
    // }
    // else {
    //     setState({
    //         ...state, 
    //         minPriceError: ``,
    //         formValid: true
    //     })
    // }
  }

  const checkQuantity = () => {
    const qty = Number(nftConfig.quantity)
    let qtyMsg = ''

    if (!qty && qty !== 0)
      qtyMsg = 'This field is required.'
    else if (qty === 0)
      qtyMsg = 'The amount must be greater than 0'
    else if (qty < 0)
      qtyMsg = 'The amount must be a positive integer'
    else if (qty > nft.copies)
      qtyMsg = `The quantity cannot exceed ${nft.copies}`

    setQuantityMsg(qtyMsg)
    setFormValid(Boolean(qtyMsg))
    return
  }

  const onChange = (e) => {
    const { name, value } = e.target
    let data = nftConfig

    const keys = name.split('.') // nftConfig, price, currency
    if (keys && keys[2]) data[keys[1]][keys[2]] = value
    else if (keys && keys[1]) data[keys[1]] = value

    if (keys && keys[1] === 'price' && keys[2] === 'amount' && value) {
      data[keys[1]]['amount'] = Number(value)
      checkMinimuPrice()
    }

    if (keys && keys[1] === 'expiry' && keys[2] === 'date' && value)
      data[keys[1]]['type'] = Number(value) === -1 ? 2 : 1

    // set datetime for days / months
    if (data.expiry.type === 1)
      data.expiry.datetime = moment(
        data.expiry.date + ' ' + data.expiry.time,
        'DD/MM/YYYY HH:mm',
      )
    // set datetime for custom date
    else if (data.expiry.type === 2) {
      if (Number(value) === -1) {
        data.expiry.time = ''
      } else {
        let dateTime = value.split('T')
        const customDate = dateTime[0]
          .split('-')
          .reverse()
          .join()
          .replaceAll(',', '/')
        data.expiry.datetime = moment(
          customDate + ' ' + dateTime[1],
          'DD/MM/YYYY HH:mm',
        )
      }
    }
    if (e.target.id === 'currency') {
      checkMinimuPrice()
      data.cAddress = e.target.selectedOptions[0].getAttribute('caddress')
    }

    if (nft.copies > 1)
      checkQuantity()

    setNFTConfig(
      {
        ...data,
      }
    );
  }

  // submit when a bid is placed or when an offer is made
  const submit = async () => {
    if (userId) {
      let formValid = true;

      // let startTimeMsg = ''
      // let endTimeMsg = ''

      // const startTime = moment(fixedPriceConfig.listingSchedule.startTime, 'HH:mm')
      // const endTime = moment(fixedPriceConfig.listingSchedule.endTime, 'HH:mm')
      // let isEndDateBeforeOrSame = moment(fixedPriceConfig.listingSchedule.endDate.format('YYYY-MM-DD')).isSameOrBefore(moment(new Date()).format('YYYY-MM-DD'))

      // if (startTime.isBefore(moment(new Date(), "HH:mm"))) {
      // 	startTimeMsg = 'Please provide valid Start time'
      // }
      // if (isEndDateBeforeOrSame && endTime.isBefore(moment(new Date(), "HH:mm"))) {
      // 	endTimeMsg = 'Please provide valid End time'
      // }

      // return;
      if (!validator.allValid() || (quantityMsg && Number(nft.sellingMethod) === 1) || Number(nftConfig.price.amount) === 0)  //formValid = false
        formValid = false

      setIsSubmitted(true)
      setFormValid(formValid);

      if (formValid) {
        setLoader(true)
        const payload = {
          ownerId: itemToBuy?.seller?._id, // nft.owner._id,
          expiryDate: nftConfig.expiry.datetime,
          price: nftConfig.price,
          nftId: nft._id,
          collectionId: nft.collection?._id,
          quantity: nftConfig.quantity,
          listingId: itemToBuy?._id,
          listingQty: itemToBuy?.quantity
        }

        let _nftData = {
          tokenId: nft.tokenId || 0,
          price: nftConfig.price.amount,
          cAddress: nftConfig.cAddress,
          nftId: nft.autoNftId,
          currency: nftConfig.price.currency,
        }

        // here we check Chain
        // point to the right Network
        let nftChainId = numberToChainId[nft.blockChain]
        await switchBlockChain(nftChainId);

        let balanceOf = await checkBalance(_nftData.cAddress)

        if (parseFloat(balanceOf) < nftConfig.price.amount) {
          toast.error(
            'Your account does not have sufficient amount of the selected token',
          )
          setLoadingMessageCheck(false)
          setLoadingMessage('')
          setLoader(false)
          return
        }
        // if selling method is 2 then go for bid
        if (nft.sellingMethod === 2) {
          // place a bid
          let bidResponse = await placeBidWeb3(_nftData)
          if (bidResponse) {
            payload.signature = bidResponse
            props.placeBid(payload)
          } else {
            toast.error('Failed to place a bid')
            setLoadingMessageCheck(false)
            setLoadingMessage('')
            return setLoader(false)
          }
        } else if (nft.sellingMethod === 1) {
          // make an offer
          let offerResponse = await makeOfferWeb3(_nftData)
          if (offerResponse) {
            payload.signature = offerResponse
            props.makeOffer(payload)
          } else {
            toast.error('Failed to make an offer')
            setLoadingMessageCheck(false)
            setLoadingMessage('')
            return setLoader(false)
          }
        }
      } else {
        validator.showMessages()
        validator.purgeFields()
        setErrors('Please fill all required fields in valid format.')
        setFormValid(false)
        window.scroll(0, 0)
      }
    } else
      navigate('/login')
  }

  const handleFavouriteClick = (e) => {
    const { item } = params
    const nftId = window.atob(item)
    const data = {
      nftId: nftId
    }
    props.favoriteToggle(data)
  }

  const deleteOffer = async (offerId) => {
    setLoader(true)

    let _nftData = {
      tokenId: nft.tokenId,
      nftId: nft.autoNftId,
    }
    let cancelResponse = await cancelOfferBidWeb3(_nftData)
    if (cancelResponse) {
      toast.success('Your offer has been cancelled successfully')
      props.deleteOffer(offerId)
    } else {
      toast.error('Failed to cancel the offer')
      setLoadingMessageCheck(false)
      setLoadingMessage('')
      setLoader(false)
    }
  }

  const deleteBid = async (bidId) => {
    setLoader(true)
    let _nftData = {
      tokenId: nft.tokenId,
      nftId: nft.autoNftId,
    }
    let cancelResponse = await cancelOfferBidWeb3(_nftData)
    if (cancelResponse) {
      toast.success('Your bid has been cancelled successfully')
      props.deleteBid(bidId)
    } else {
      toast.error('Failed to cancel the bid')
      setLoadingMessageCheck(false)
      setLoadingMessage('')
      setLoader(false)
    }
  }
  const formatAddress = (address) => {
    return address ? address.substr(0, 7) + '...' + address.substr(-4) : null
  }

  const acceptOffer = async (offerId, offerByAddress, price) => {
    setLoader(true)
    let _nftData = {
      metadata: nft.metaData || '',
      tokenId: nft.tokenId || 0,
      newOwner: offerByAddress,
      nft: nft.address,
      amount: price,
      collectionId: nft.collection.autoColId || 0,
      percent: parseInt(nft.serviceFeePercent) * 100 || 0,  // servicefee*100
      royalty: parseInt(nft.royalty) * 100 || 0,  //nft.roylaity*100
      copies: nft.copies,
      creator: nft.creator?.address,
      ...(nft.mintType === 2 && { totalQuantity: nft.totalQuantity }),
      ...(nft.mintType === 2 && { quantity: qtyToBuy || 1 }),
    }

    // here we check Chain
    let nftChainId = numberToChainId[nft.blockChain]
    await switchBlockChain(nftChainId);

    let acceptOfferResponse = await acceptOfferBidWeb3(_nftData, nft.tokenStandard)
    if (acceptOfferResponse && acceptOfferResponse.txHash) {
      toast.success(
        'Offer is accepted successfully, and ownership is transferred.',
      )
      props.acceptOffer({ offerId, ...acceptOfferResponse })
    } else {
      toast.error('Failed to accept the offer')
      setLoadingMessageCheck(false)
      setLoadingMessage('')
      setLoader(false)
    }
  }

  const acceptBid = async (bidId, offerByAddress, price) => {
    setLoader(true)
    let _nftData = {
      metadata: nft.metaData,
      tokenId: nft.tokenId || 0,
      newOwner: offerByAddress,
      nft: nft.address,
      amount: price,
      collectionId: nft.collection.autoColId || 0,
      percent: parseInt(nft.serviceFeePercent) * 100 || 0,  // servicefee*100 serviceFeePercent
      creator: nft.creator?.address,
      ...(nft.mintType === 2 && { totalQuantity: 1 }),
      ...(nft.mintType === 2 && { quantity: 1 })
    }

    // here we check Chain
    let nftChainId = numberToChainId[nft.blockChain]
    await switchBlockChain(nftChainId);

    let acceptBidResponse = await acceptOfferBidWeb3(_nftData, nft.tokenStandard)
    if (acceptBidResponse && acceptBidResponse.txHash) {
      toast.success(
        'Bid is accepted successfully, and ownership is transferred.',
      )
      props.acceptBid({ bidId, ...acceptBidResponse })
    } else {
      toast.error('Failed to accept the bid')
      setLoadingMessageCheck(false)
      setLoadingMessage('')
      setLoader(false);
    }
  }

  const fetchData = () => {
    let nftId = nft?._id
    if (nftId) {
      if (rendType === 1) {
        const bidQS = ENV.objectToQueryString({
          nftId: nftId,
          page: page,
        })
        props.getBids(bidQS)
      } else if (rendType === 2) {
        const qs = ENV.objectToQueryString({
          nftId: nftId,
          page: page,
        })
        props.getOffers(qs)
      } else if (rendType === 3) {
        const qs = ENV.objectToQueryString({
          nftId,
          page,
        })
        props.getListings(qs)
      } else if (rendType === 4) {
        const qs = ENV.objectToQueryString({
          nftId: nftId,
          page: page,
        })
        props.getHistory(qs)
      }
    }
    let pageValue = page + 1
    setPage(pageValue)
  }

  const rendContent = () => {
    if (rendType === 1) {
      return (
        <div className="tab-pane show active" id="bids">
          {bids && bids.length > 0 ? (
            <InfiniteScroll
              dataLength={bids.length}
              next={fetchData}
              hasMore={moreCheck}
            // loader={<h4>Loading...</h4>}
            >

              <div className=" custom-tab-table  single-tab-list d-flex align-items-start w-100">
                <table className="table table-borderless">
                  <thead>
                    <tr>
                      <th scope="col">From</th>
                      <th scope="col">Offer</th>
                      {/* <th scope="col">Floor Difference</th> */}
                      <th scope="col">Status</th>
                      <th scope="col">Date</th>
                      <th scope="col">Action</th>

                    </tr>
                  </thead>
                  <tbody>
                    {bids.map((item, idx) => {
                      const today = moment(new Date(), 'YYYY-MM-DD HH:mm:ss:SSS')
                      const expiryDate = moment(
                        new Date(item.expiryDate),
                        'YYYY-MM-DD HH:mm:ss:SSS',
                      )
                      const isPast = expiryDate.isBefore(today)

                      return (
                        <tr key={`bid_${idx}`}>
                          <td className='d-flex align-items-center'>
                            <img
                              className="avatar-sm mr-2"
                              src={item.bidBy.profilePhoto ? item.bidBy.profilePhoto : item.owner.profileImage}
                              alt=""
                            />
                            <Link to={`/artist/${item.bidBy.username}`}>
                              @{item.bidBy._id === userId ? 'you' : item.bidBy.username}
                            </Link>
                          </td>
                          <td><strong>
                            {item.price.amount} {item.price.currency}
                          </strong>{' '}</td>
                          <td>Active</td>
                          <td>{moment(item.createdAt).fromNow()} </td>
                          <div className="d-flex">
                            {userId === item.bidBy._id && (
                              <div
                                className="ml-auto"
                                onClick={() => deleteBid(item._id)}
                                data-toggle="tooltip"
                                title="Delete"
                              >
                                <i
                                  className="cursor-pointer fa fa-times text-danger"
                                  aria-hidden="true"
                                />
                              </div>
                            )}
                            {userId === item.ownerId && !isPast && (
                              <div
                                className="ml-auto"
                                onClick={() =>
                                  acceptBid(
                                    item._id,
                                    item.bidBy.address,
                                    parseFloat((item.price.amount * item.quantity).toFixed(10)),
                                  )
                                }
                                data-toggle="tooltip"
                                title="Accept"
                              >
                                <i
                                  className="cursor-pointer fa fa-check text-success"
                                  aria-hidden="true"
                                />
                              </div>
                            )}
                            {item.txHash && (
                              <a
                                className="ml-3"
                                target="_blank"
                                rel="noreferrer"
                                href={`${chainsConfigs[item.chainId].explorer || chainsConfigs[defaultChainId].explorer}tx/${item.txHash}`}
                                data-toggle="tooltip"
                                title="Transaction Details"
                              >
                                <i
                                  className="cursor-pointer fa fa-info text-info"
                                  aria-hidden="true"
                                />
                              </a>
                            )}
                          </div>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>

            </InfiniteScroll>
          ) : (
            <div className=" no-bids-found mt-5"><p>No Bids found</p></div>
          )}
          {/* <ul className="list-unstyled">
            {bids && bids.length > 0 ? (
              <InfiniteScroll
                dataLength={bids.length}
                next={fetchData}
                hasMore={moreCheck}
                loader={<h4>Loading...</h4>}
              >
                {bids.map((item, idx) => {
                  const today = moment(new Date(), 'YYYY-MM-DD HH:mm:ss:SSS')
                  const expiryDate = moment(
                    new Date(item.expiryDate),
                    'YYYY-MM-DD HH:mm:ss:SSS',
                  )
                  const isPast = expiryDate.isBefore(today)

                  return (
                    <li
                      key={`bid_${idx}`}
                      className="single-tab-list d-flex justify-content-between align-items-start w-100"
                    >
                      <img
                        className="avatar-sm rounded-circle mr-3"
                        src={item.bidBy.profilePhoto}
                        alt=""
                      />
                      <div className="d-flex justify-content-between flex-fill">
                        <p className="m-0 mr-3 flex-fill">
                          Bid listed for{' '}
                          <strong>
                            {item.price.amount} {item.price.currency}
                          </strong>{' '}
                          {moment(item.createdAt).fromNow()} <br />
                          by{' '}
                          <Link to={`/artist/${item.bidBy._id}`}>
                            @{item.bidBy.username}
                          </Link>
                        </p>
                        <div className="d-flex">
                          {userId === item.bidBy._id && (
                            <div
                              className="ml-auto"
                              onClick={() => deleteBid(item._id)}
                              data-toggle="tooltip"
                              title="Delete"
                            >
                              <i
                                className="cursor-pointer fa fa-times text-danger"
                                aria-hidden="true"
                              />
                            </div>
                          )}
                          {userId === nft.owner?._id && !isPast && (
                            <div
                              className="ml-auto"
                              onClick={() =>
                                acceptBid(
                                  item._id,
                                  item.bidBy.address,
                                  item.price.amount,
                                  ENV.tokenNameToValue[item.price.currency]
                                    ? ENV.tokenNameToValue[item.price.currency]
                                    : 2,
                                )
                              }
                              data-toggle="tooltip"
                              title="Accept"
                            >
                              <i
                                className="cursor-pointer fa fa-check text-success"
                                aria-hidden="true"
                              />
                            </div>
                          )}
                          {item.txHash && (
                            <a
                              className="ml-3"
                              target="_blank"
                              rel="noreferrer"
                              href={`${explorerURL[item.chainId] || explorerURL.default}/tx/${item.txHash}`}
                              data-toggle="tooltip"
                              title="Transaction Details"
                            >
                              <i
                                className="cursor-pointer fa fa-info text-info"
                                aria-hidden="true"
                              />
                            </a>
                          )}
                        </div>
                      </div>
                    </li>
                  )
                })}
              </InfiniteScroll>
            ) : (
              <div className="my-4">No Bids found</div>
            )}
          </ul> */}
        </div>
      )
    } else if (rendType === 2) {
      return (
        <div className="tab-pane show active" id="offers">

          {offers && offers.length > 0 ? (
            <InfiniteScroll
              dataLength={offers.length}
              next={fetchData}
              hasMore={moreCheck}
            // loader={<h4>Loading...</h4>}
            >

              <div className=" custom-tab-table  single-tab-list d-flex align-items-start w-100">
                <table className="table table-borderless">
                  <thead>
                    <tr>
                      <th scope="col">From</th>
                      <th scope="col">Offer</th>
                      <th scope="col">Quantity</th>
                      {/* <th scope="col">Floor Difference</th> */}
                      <th scope="col">Status</th>
                      <th scope="col">Date</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  {offers.map((item, idx) => {
                    const today = moment(new Date(), 'YYYY-MM-DD HH:mm:ss:SSS')
                    const expiryDate = moment(
                      new Date(item.expiryDate),
                      'YYYY-MM-DD HH:mm:ss:SSS',
                    )
                    const isPast = expiryDate.isBefore(today)

                    return (
                      <tbody key={`offer_${idx}`}>
                        <tr key={`offer_${idx}`}>
                          <td className='d-flex align-items-center'>
                            <img className="avatar-sm  mr-2" src={item.offerBy.profilePhoto ? item.offerBy.profilePhoto : userDefaultImg} alt="" />
                            <Link to={`/artist/${item.offerBy.username}`}>
                              @{item.offerBy._id === userId ? 'you' : item.offerBy.username}
                            </Link>
                          </td>
                          <td>
                            <strong>
                              {item.price.amount} {item.price.currency}
                            </strong>{' '}
                            {/* <p className="m-0 mr-3 flex-fill">
                                  Offer listed for{' '}
                                
                                  {moment(item.createdAt).fromNow()} <br />
                                  by{' '}
                                  <Link to={`/artist/${item.offerBy._id}`}>
                                    @{item.offerBy.username}
                                  </Link>
                                </p> */}
                          </td>
                          <td>{item.quantity}</td>
                          <td>Active</td>
                          <td>
                            {moment(item.createdAt).fromNow()}
                          </td>
                          {/* <td>6/20/22</td> */}
                          <div className="d-flex">
                            {userId === item.offerBy._id && (
                              <div
                                className="ml-auto"
                                onClick={() => deleteOffer(item._id)}
                                data-toggle="tooltip"
                                title="Remove"
                              >
                                <i
                                  className="cursor-pointer fa fa-times text-danger"
                                  aria-hidden="true"
                                />
                              </div>
                            )}
                            {userId === item.ownerId && !isPast && (
                              <div
                                className="ml-auto"
                                onClick={() =>
                                  acceptOffer(
                                    item._id,
                                    item.offerBy.address,
                                    parseFloat((item.price.amount * item.quantity).toFixed(10))
                                  )
                                }
                                data-toggle="tooltip"
                                title="Accept"
                              >
                                <i
                                  className="cursor-pointer fa fa-check text-success"
                                  aria-hidden="true"
                                />
                              </div>
                            )}
                            {item.txHash && (
                              <a
                                className="ml-3"
                                target="_blank"
                                rel="noreferrer"
                                href={`${chainsConfigs[item.chainId].explorer || chainsConfigs[defaultChainId].explorer}tx/${item.txHash}`}
                                data-toggle="tooltip"
                                title="Transaction Details"
                              >
                                <i
                                  className="cursor-pointer fa fa-info text-info"
                                  aria-hidden="true"
                                />
                              </a>
                            )}
                          </div>
                        </tr>
                      </tbody>
                    )
                  })}
                </table >
              </div >


            </InfiniteScroll >
          ) : (
            <div className="mt-5 no-bids-found "><p>No offers found</p></div>
          )}

        </div >
      )
    } else if (rendType === 3) {
      return (
        <div className="tab-pane show active" id="listings">
          <ul className="list-unstyled">
            {listings && listings.length > 0 ? (
              <>
                <InfiniteScroll
                  dataLength={listings.length}
                  next={fetchData}
                  hasMore={moreCheck}
                  // loader={<h4 className='mt-3'>Loading...</h4>}
                  className=" listing-infinite-scroll"
                >
                  <li className="single-tab-list offer-img d-flex align-items-center w-100 m-0">
                    <div className='table-card'>
                      <div className='table-responsive'>
                        <Table className=' ranking-table table'>
                          <thead>
                            <tr>
                              <th className='td-up'>{nft.tokenStandard === 2 && 'Unit '}Price</th>
                              <th className='td-uup'>USD {nft.tokenStandard === 2 && 'Unit '}Price</th>
                              {
                                nft.tokenStandard === 2 &&
                                <th className='td-quantity'>Quantity</th>
                              }
                              <th className='td-expiration'>Expiration</th>
                              <th className='td-from'>From</th>
                              <th className='td-action text-center'>Action</th>
                            </tr>
                          </thead>
                          <tbody>

                            {listings.map((item, idx) => {
                              return (
                                <tr key={`listing_${idx}`}>
                                  <td className='td-up'>
                                    <p className='up-div'>
                                      {/* <img alt={`l-token_${idx}_icon`} width="4" height="4" src={item.currency === 'BNB' ? bnbIcon : ''} /> */}
                                      <b className='ms-2 currency-price'>{item.currentPrice}</b>&nbsp;<span className='currency-span ms-2'>{item.currency}</span>
                                    </p>
                                  </td>
                                  <td className='td-uup'>
                                    ${item.priceInUSD}
                                  </td>
                                  {
                                    nft.tokenStandard === 2 &&
                                    <td className='td-quantity'>
                                      <span>{item.quantity}</span>
                                    </td>
                                  }
                                  <td className='td-expiration'>
                                    <p>{moment(item.auctionEndDate).fromNow()}</p>
                                  </td>
                                  <td className='td-from'>
                                    <span>{item.seller ? (<Link to={`/artist/${item.seller.username}`}>{item.seller._id === userId ? 'you' : item.seller.username}</Link>) : '----'}</span>
                                  </td>
                                  {item.sellingMethod !== 2 && <td className='td-action text-center'>
                                    {

                                      item.seller._id === userId ?
                                        <span className="ms-auto"
                                          data-effect="float"
                                          data-tip="Cancel"
                                          onClick={() => cancelListing(item)}
                                        >
                                          <i className="cursor-pointer fa fa-times text-danger" aria-hidden="true" />
                                        </span>
                                        :
                                        <span className="ms-auto"
                                          data-effect="float"
                                          data-tip="Buy"
                                          onClick={() => toggleQuantityModal(item?._id)}
                                        >
                                          <i className="cursor-pointer fa fa-tag text-info" aria-hidden="true" />
                                        </span>
                                    }
                                  </td>}
                                </tr>
                              )
                            })}
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </li>
                </InfiniteScroll>
              </>
            ) : (
              <div className="text-white">
                <span className="no-data-table">No listings yet</span>
              </div>
            )}
          </ul>
        </div>
      )
    } else if (rendType === 4) {
      return (
        <div className="tab-pane show active history-tab-pane" id="history">
          <div className=" custom-tab-table  single-tab-list">
            {nftHistory && nftHistory.length > 0 ? (
              <InfiniteScroll dataLength={nftHistory.length} next={fetchData} hasMore={moreCheck}
              //  loader={<h4>Loading...</h4>}
              >
                <div className='responsive-table'>
                  <table className="table table-borderless history-table">
                    <thead>
                      <tr>
                        <th scope="col">From</th>
                        <th scope="col">Action</th>
                        <th scope="col">Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {nftHistory.map((item, idx) => {
                        return (
                          <tr key={`history_${idx}`}>
                            <td className='d-flex align-items-center '>
                              <img className="avatar-sm  mr-2" src={item.user?.profilePhoto || miniCollectionImage} alt="" />
                              <Link to={`/artist/${item.user.username}`}>
                                {item.user.username}
                              </Link>
                            </td>
                            <td>{ItemTypes[item.type]} {item.price ? `for ${item.price} ${item.currency}` : ''}</td>
                            <td>{moment(Date.parse(item.createdAt)).format("dddd, MMMM Do YYYY, h:mm:ss a")}</td>
                            {
                              // item.type === 1 ? <p className="m-0 transaction-tag">Created {moment(item.createdAt).fromNow()} by <Link to={`/artist/${item.user._id}`} className="d-inline">{item.user.username}</Link>{item.price ? ", " : ''} {item.price ? "having price " : ''}<strong>{item.price ? item.price : ''}{item.price ? ' ' + item.currency : ''}</strong></p> :
                              //   item.type === 2 ? <p className="m-0 transaction-tag">Offer made {moment(item.createdAt).fromNow()} by <Link to={`/artist/${item.user._id}`} className="d-inline">{item.user.username}</Link>{item.price ? ", " : ''} {item.price ? "having price " : ''}<strong>{item.price ? item.price : ''}{item.price ? ' ' + item.currency : ''}</strong></p> :
                              //     item.type === 3 ? <p className="m-0 transaction-tag">Bid placed {moment(item.createdAt).fromNow()} by <Link to={`/artist/${item.user._id}`} className="d-inline">{item.user.username}</Link>{item.price ? ", " : ''} {item.price ? "having price " : ''}<strong>{item.price ? item.price : ''}{item.price ? ' ' + item.currency : ''}</strong></p> :
                              //       item.type === 4 ? <p className="m-0 transaction-tag">Ownership transferred {moment(item.createdAt).fromNow()} from <Link to={`/artist/${item.user._id}`} className="d-inline-block align-top">{item.user.username}</Link> to <Link className="d-inline-block align-top" to={item.toUserId ? `/artist/${item.toUserId}` : '#'}>{item.toUserName ? item.toUserName : '----'}</Link>{item.price ? ", " : ''} {item.price ? "for price " : ''}<strong>{item.price ? item.price : ''}{item.price ? ' ' + item.currency : ''}</strong></p> :
                              //         item.type === 5 ? <p className="m-0 transaction-tag">Ownership transferred {moment(item.createdAt).fromNow()} from <Link to={`/artist/${item.user._id}`} className="d-inline-block align-top">{item.user.username}</Link> to <Link className="d-inline-block align-top" to={item.toUserId ? `/artist/${item.toUserId}` : '#'}>{item.toUserName ? item.toUserName : '----'}</Link>{item.price ? ", " : ''} {item.price ? "for price " : ''}<strong>{item.price ? item.price : ''}{item.price ? ' ' + item.currency : ''}</strong></p> :
                              //           item.type === 6 ? <p className="m-0 transaction-tag">Listed {moment(item.createdAt).fromNow()} by <Link to={`/artist/${item.user._id}`} className="d-inline">{item.user.username}</Link>{item.price ? ", " : ''} {item.price ? "for price " : ''}<strong>{item.price ? item.price : ''}{item.price ? ' ' + ENV.currency[chainId] : ''}</strong></p> :
                              //             item.type === 7 ? <p className="m-0 transaction-tag">NFT transferred {moment(item.createdAt).fromNow()} by <Link to={`/artist/${item.user._id}`} className="d-inline">{item.user.username}</Link>{item.price ? ", " : ''} {item.price ? "for price " : ''}<strong>{item.price ? item.price : ''}{item.price ? ' ' + ENV.currency[chainId] : ''}</strong></p> :
                              //               item.type === 8 ? <p className="m-0 transaction-tag">Listing cancelled {moment(item.createdAt).fromNow()} by <Link to={`/artist/${item.user._id}`} className="d-inline">{item.user.username}</Link></p> : ''
                            }
                            {item.txHash && (
                              <a
                                className="transaction-detail"
                                target="_blank"
                                rel="noreferrer"
                                href={`${chainsConfigs[item.chainId].explorer || chainsConfigs[defaultChainId].explorer}tx/${item.txHash}`}
                                data-toggle="tooltip"
                                title="Transaction Details"
                              >
                                <i
                                  className="cursor-pointer fa fa-info text-info"
                                  aria-hidden="true"
                                />
                              </a>
                            )}
                          </tr>
                        )
                      })}



                    </tbody>
                  </table>
                </div>
              </InfiniteScroll>
            ) : (
              <div className="mt-5 no-bids-found"><p>No history found</p></div>
            )}
          </div>


        </div>
      )
    } else if (rendType === 5) {
      return (
        <div className="tab-pane  show active" id="details">
          <div className='owner-information'>
            <p>{nft?.collection?.name}</p>
            {/* <p className='descriptionn'>{nft?.collection?.name} </p> */}
            <div className='details-external-links mb-4'>
              <ul className='list-unstyled d-flex'>
                {nft?.collection?.instagram && <li><a href=''><FontAwesomeIcon icon={insta} /></a></li>}
                {nft?.collection?.discord && <li><a href=''><FontAwesomeIcon icon={discord} /></a></li>}
                {nft?.collection?.medium && <li><a href=''><FontAwesomeIcon icon={medium} /></a></li>}
                {nft?.collection?.telegram && <li><a href=''><FontAwesomeIcon icon={telegram} /></a></li>}

                {/* <li><a href=''><FontAwesomeIcon icon={faTwitter} /></a></li> */}
                {/* <li><FontAwesomeIcon icon={faEllipsisVertical} /></li> */}
              </ul>
            </div>
          </div>
          {nft?.sellingMethod && <div className='categories'>
            <p>Blockchain</p>
            <div className='d-flex align-items-center categories-image'>
              <img
                // src={arrowImage}
                src={blockChainImages[nft?.blockChain]}
                className="img-fluid" alt="image" />


              <span className='pl-3'>{currenciesTexts[Number(nft?.blockChain)]}</span>
            </div>
          </div>}
          <div className='detail-info'>
            <div className='d-flex align-items-center justify-content-between'>
              <h5>Contract Address</h5>
              <p className='pink-color'>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`${chainsConfigs[chainId]?.explorer || chainsConfigs[defaultChainId]?.explorer}address/${nft.collection?.address
                    ? nft.collection?.address
                    : chainsConfigs[chainId]?.marketplaceAddress
                    }`}
                >
                  {formatAddress(
                    nft.collection?.address
                      ? nft.collection?.address
                      : chainsConfigs[chainId]?.marketplaceAddress,
                  )}
                </a>
              </p>
            </div>
            {
              nft?.tokenId &&
              <div className='d-flex align-items-center justify-content-between'>
                <h5>Token ID</h5>
                <p>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={`${chainsConfigs[numberToChainId[nft.blockChain]].explorer}token/${nft.address}?a=${nft?.tokenId}`}
                  >
                    {nft?.tokenId || 0}
                  </a>
                </p>
              </div>
            }

            <div className='d-flex align-items-center justify-content-between'>
              <h5>Token Standard</h5>
              <p>
                {Number(nft.mintType) === 1 ? tokenStandards.TS1 : tokenStandards.TS2}
              </p>
            </div>
            <div className='d-flex align-items-center justify-content-between'>
              <h5>Metadata</h5>
              {nft?.tokenId ? <p>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`${nft?.metaData}`}
                >
                  decentralized
                </a>
              </p> :
                <p>
                  centralized
                </p>
              }
            </div>
            {nft.creatorFee >= 0 && <div className='d-flex align-items-center justify-content-between'>
              <h5>Creator Fees</h5>
              <p>{nft?.creatorFee}%</p>
            </div>}
          </div>
          {isOwner && nft?.showUnBlockContent && <div>
            <p>Unlockable Content</p>
            <div>
              {nft.unBlockContent}
            </div>
          </div>}
        </div>
      )
    } else if (rendType === 6) {
      return (
        <div className="tab-pane show active properties-tab" id="attr">
          <h5>Properties</h5>
          <div className=" d-flex justify-content-start justify-content-sm-start  justify-content-lg-start  justify-content-md-start flex-wrap">

            {nft?.attributes?.length > 0 ? (
              nft.attributes.map((attr, index) => {
                return (
                  <div key={`attr_${index}`} className="card no-hover">
                    <div className="single-seller d-flex align-items-start">
                      <div className="seller-info justify-content-start align-items-start flex-fill">
                        <h5 className="seller my-0">{attr._id.trait_type}</h5>
                        <span className='properties-value'>{attr._id.value}</span>
                        <span className='properties-percentage'>
                          {parseFloat(
                            ((attr.total / nft.totalColNFTs) * 100).toFixed(2),
                          )}
                          % rarity
                        </span>
                      </div>
                    </div>
                  </div>
                )
              })
            ) : (
              <div className="mt-5 no-bids-found"><p>No properties found</p></div>
            )}
          </div>
        </div>
      )
    } else if (rendType === 7) {
      return (
        <div className="tab-pane show active properties-tab" id="attr">
          <div className="mt-3 d-flex align-items-center justify-content-between flex-wrap overflow-responsive">
            {nft?.relatedNFTs?.length > 0
              ?
              nft?.relatedNFTs?.map((item, idx) =>
                <div key={`related_nfts_${idx}`} className='related-card d-flex align-items-center'>
                  <div className='related-card-image'><Link to={`/item-details/${window.btoa(item._id)}`}><img src={item?.image ? item?.image : itemPlaceholderImg} className="" alt="image" /></Link></div>
                  <div className='content'>
                    <Link to={`/item-details/${window.btoa(item._id)}`}><h4>{item?.name}</h4></Link>

                    {item?.sellingConfig?.price &&
                      <>
                        <img src={currenciesImages[item?.sellingConfig?.price?.currency]} className="" alt="image" />
                        <span>{item?.sellingConfig?.price?.amount} {item?.sellingConfig?.price?.currency}</span>
                      </>
                    }
                  </div>
                </div>

              )
              : (
                <div className="mt-5 no-bids-found"><p>No related NFTs found</p></div>
              )}
          </div>
        </div>
      )
    }
  }

  const refreshMetadata = () => {
    const { tokenId, address, _id } = nft
    const payload = {
      tokenId,
      address,
      nftId: _id,
    }
    setLoader(true)
    props.refreshMetadata(payload)
  }

  const toggleQuantityModal = (listingId = null) => {
    if (userId) {
      const qtyModal = !quantityModal
      setQuantityModal(qtyModal)
      setItemToBuy(null)
      setIsSubmitted(false)
      setErrors('')

      if (qtyModal && listingId) {
        const itemToBuy = listings.find((elem) => elem._id === listingId)
        if (itemToBuy) {
          setItemToBuy(itemToBuy)
          setQtyToBuy(1)
        }
      }
    }
    else
      navigate('/login')
  }

  const cancelListing = (listing = null) => {
    if (listing)
      confirmAlert({
        title: 'Are you sure you want to cancel your listing?',
        message: `Cancelling your listing will unpublish this sale from ${appName}.`,
        buttons: [
          {
            label: 'Never mind',
            class: 'never-mindd',
          },
          {
            label: 'Cancel listing',
            class: 'cancel-listing',
            onClick: async () => {
              setLoader(true)
              const _nftData = {
                tokenId: nft.tokenId,
                price: listing.currentPrice.toString(),
                currency: listing.currency,
                nft: nft.address,
                nftId: nft.autoNftId,
              }
              const cancelResponse = await cancelSellingWeb3(_nftData, nft._id)

              if (cancelResponse) {
                props.cancelListing(nft._id, nft.sellingMethod, cancelResponse, chainId, listing._id)
              } else
                setLoadingMessageCheck(false)
              setLoadingMessage('')
              return setLoader(false)
            }
          }
        ]
      })
  }

  const onTextualChange = (e) => {
    const { name, value } = e.target

    if (name === "qtyToBuy") {
      if (parseInt(value) === 0 || (Number(value) && (Number(value) > Number(itemToBuy?.quantity) || Number(value) < 1)))
        return

      setQtyToBuy(value)
    }
  }

  useEffect(() => {
    if (buyNowViaBtn && itemToBuy && qtyToBuy) {
      buyNFT()
      setBuyNowViaBtn(false)
    }
  }, [buyNowViaBtn, itemToBuy, qtyToBuy])

  // method to buy NFT directly either from Buy Now, Buy with PayPal or Buy through list
  const directBuy = (setItem = true) => {
    setLoadingMessageCheck(true)
    setLoadingMessage('Your NFT is almost on the way! Please stay on this screen while you confirm the transactions on your wallet. Once confirmed, Please wait while the network confirms your transaction.')
    if (userId) {
      setLoader(true)
      setBuyNowViaBtn(setItem)

      if (setItem) {
        const itemToBuy = listings[0] // set item to buy as default list
        if (itemToBuy) {
          setItemToBuy(itemToBuy)
          setQtyToBuy(1)
        }
      }
      else
        buyNFT()
    }
    else
      navigate('/login')
  }

  const buyNFT = async () => {
    if (!qtyToBuy) {
      setIsSubmitted(true)
      setErrors('Quantity is required')
      setLoadingMessageCheck(false)
      setLoadingMessage('')
      setLoader(false)
      return
    }

    if (!itemToBuy) {
      setIsSubmitted(true)
      setErrors('Item to buy is required')
      setLoadingMessageCheck(false)
      setLoadingMessage('')
      setLoader(false)
      return
    }

    setLoader(true)

    const price = parseFloat((itemToBuy.currentPrice * (qtyToBuy || 0)).toFixed(10))
    const currency = itemToBuy.currency

    // here we check Chain
    let nftChainId = numberToChainId[nft.blockChain]
    await switchBlockChain(nftChainId);

    // const supportedCurrencies = { "WCRO": 2, "WFTM": 2, "WETH": 1, "WBNB": 1, "CRO": 2, "FTM": 2, "ETH": 1, "BNB": 1 }
    const supportedCurrencies = { "WCRO": 2, "WFTM": 2, "WETH": 2, "WBNB": 2, "CRO": 1, "FTM": 1, "ETH": 1, "BNB": 1 }

    const tokenConfig = currencies.find(
      (elem) => elem.value.toUpperCase() === currency,
    )

    let nftData = {
      metadata: nft.metaData || '',
      tokenId: nft.tokenId || 0,
      owner: itemToBuy?.seller?.address,
      nft: nft.address,
      amount: price || 0,
      collectionId: nft.collection?.autoColId || 0,
      percent: parseInt(nft.serviceFeePercent) * 100 || 0,  // servicefee*100 serviceFeePercent
      royalty: parseInt(nft.royalty) * 100 || 0,  //nft.roylaity*100
      // payThrough,
      currency: supportedCurrencies[currency],
      cAddress: tokenConfig?.address || '',
      creator: nft.creator.address
    }

    if (nft.tokenStandard === 2) {
      nftData = {
        ...nftData,
        quantity: qtyToBuy,
        totalQuantity: nft.totalQuantity,
        nft: nft.address
      }
    }

    let res = await buyNowWeb3(nftData, nft.tokenStandard);

    if (res.success) {
      res.data.nftId = nft._id

      let payload = {
        ...res.data,
        nftId: nft._id,
        collectionId: nft.collection?._id,
        newOwnerAddress: userAddress,
        userId: itemToBuy.seller._id,
        buyerId: userId,
        price: {
          amount: price || 0,
          currency,
        },
        paymentMethod: 1,
        listingId: itemToBuy._id,
        quantity: qtyToBuy,
        listingQty: itemToBuy?.quantity
      }
      props.buyNFT(payload)
    } else {
      setLoadingMessageCheck(false)
      setLoadingMessage('')
      setLoader(false)
      toast.error('Failed to buy an NFT')
    }
  }

  const currentDate = moment(new Date(), 'YYYY-MM-DD HH:mm:ss:SSS')
  const nftStartDate = moment(
    new Date(nft?.auctionStartDate),
    'YYYY-MM-DD HH:mm:ss:SSS',
  )
  const nftEndDate = moment(
    new Date(nft?.auctionEndDate),
    'YYYY-MM-DD HH:mm:ss:SSS',
  )
  const isAuctionStarted = nftStartDate.isBefore(currentDate)
  const isAuctionPast = nftEndDate.isBefore(currentDate)

  const withCopies = nft?.copies > 1
  const multipleOwners = nft?.owners?.total > 1
  const owners = nft?.owners?.list || []
  const isOwner = nft?.owners?.isOwner  // flag to know either if viewer is owner or not

  // useEffect(async () => {
  //   if (nft && props.app.annRate) {
  //     let amounts = await annAndBnbConversins(nft)
  //     setAmounts(amounts)
  //   }
  // }, [nft, props.app.annRate])
  // const annAndBnbConversins = async (nft) => {
  //   if (!nft?.sellingConfig?.price)
  //     return {[nft.currency]: nft.currentPrice }

  //   const {currency, amount} = nft.sellingConfig.price;
  //   if (currency === "CRO")
  //     return {[currency]: amount }

  //   let priceAndCurrency = {
  //     "ANN": null,
  //     "BNB": null
  //   }
  //   if (currency === "ANN") {
  //     var ANNtoBNB = await ENV.convertANNToBNB(1, props.app.annRate, props.app.rate)
  //     priceAndCurrency.ANN = amount;
  //     priceAndCurrency.BNB = ANNtoBNB;

  //   }
  //   if (currency === "BNB") {
  //     var ANNtoBNB = await ENV.convertANNToBNB(1, props.app.annRate, props.app.rate)
  //     priceAndCurrency.ANN = ANNtoBNB;
  //     priceAndCurrency.BNB = amount;
  //   }
  //   return priceAndCurrency
  // }

  return (
    <>
      <section className="item-details-area padding-wrapper pb-0">
        {loader ? (
          loadingMessageCheck ? <FullPageLoader loadingMessage={loadingMessage} /> : <FullPageLoader />
        ) : (
          <>
            {nft && (
              <div className="custom-container briefly-explain">
                {console.log("REnd Type", rendType)}
                <div className="row justify-content-between">
                  <div className="col-12 col-lg-7">
                    <div className="item-info">
                      <div className="item-thumb text-center">
                        <img className='img-fluid' src={nft.imageLocal ? nft.imageLocal : userDefaultImg} alt="img" />
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-5">
                    <div className='detail-area'>
                      <div className='d-inline views-detail'>
                        <span><FontAwesomeIcon icon={faEye} />{nft?.totalVisits} {nft?.totalVisits == 1 ? "view" : "views"}</span>
                        <span className='likes'>
                          {isFavourite ? <FontAwesomeIcon icon="fa-heart" onClick={handleFavouriteClick} style={{ "color": "red" }} /> : <FontAwesomeIcon icon="fa-heart" onClick={handleFavouriteClick} />}
                          {likesCount} {nft?.isFavourite == 1 ? "like" : "likes"}</span>
                        <h2>{nft?.name}</h2>
                        <p>{nft?.description} </p>

                        {/* Owner(s) */}
                        {
                          multipleOwners ?
                            <div className="owner d-flex align-items-center">
                              <div className='cursor-pointer owner-main' onClick={() => setOwnersModal(true)}>
                                <FontAwesomeIcon className="" icon={faUsers} />
                                <span className=" ms-2">{`${nft.owners?.total} owner${nft.owners?.total > 1 ? 's' : ''}`}</span>
                              </div>
                              {
                                nft.owners?.ownedByYou > 0 &&
                                <div className='owner-main ml-3'>
                                  <FontAwesomeIcon className="" icon={faUser} />
                                  <span className="ms-2">{`${nft.owners?.ownedByYou}`} owned by <Link to={`/artist/${username}`}>you</Link></span>
                                </div>
                              }
                            </div>
                            :
                            <div className="owner d-flex align-items-center mb-2">
                              <p className="d-flex flex-row text-white mb-0 owner-name">{owners[0]?.copies > 1 ? `${owners[0]?.copies} o` : 'O'}wned by&nbsp;
                                <Link
                                  className="owner-meta owner-name"
                                  to={`/artist/${owners[0]?.owner?.username}`}
                                >
                                  {owners[0]?.owner?._id === userId ? 'you' : owners[0]?.owner?.username}
                                </Link>
                              </p>
                            </div>
                        }
                        {/* Item Info List */}
                        {/* <div className="item-info-list mt-4">
                          {
                            nft.currentPrice &&
                            <ul className="list-unstyled">
                              <li className="price d-flex justify-content-between align-items-center">
                                {
                                  nft.tokenStandard === 2 && listings.length > 0 && listings[0].quantity > 1 && nft.sellingMethod === 1 ?
                                    <span className='d-flex align-items-center'>
                                      <span className="current-item-price"></span>
                                      x{listings[0].quantity}
                                      &nbsp;
                                      Current Price <span className='current-price-item mx-2' data-tip={listings[0].currentPrice}>{listings[0].currentPrice}</span> {listings[0].currency}
                                    </span>
                                    :
                                    <span className='d-flex align-items-center'>
                                      Current Price <span className='current-price-item mx-2' data-tip={nft.currentPrice}>{nft.currentPrice}</span> {nft.currency}
                                    </span>
                                }
                                <ReactTooltip />
                                <span >
                                  ${nft.currentPrice
                                    ? nft.currency === 'BNB' &&
                                      props.app.rateAuth
                                      ? ENV.convertXtoY(
                                        nft.currentPrice,
                                        props.app.rate,
                                      )
                                      :
                                      nft.currency === 'ETH' &&
                                        props.app.ethRateAuth
                                        ? ENV.convertXtoY(
                                          nft.currentPrice,
                                          props.app.ethRate,
                                        ) :
                                        nft.currency === 'FTM' &&
                                          props.app.fantomRateAuth
                                          ? ENV.convertXtoY(
                                            nft.currentPrice,
                                            props.app.fantomRate,
                                          )
                                          : nft.currency === 'CRO' &&
                                            props.app.croRateAuth
                                            ? ENV.convertXtoY(
                                              nft.currentPrice,
                                              props.app.croRate,
                                            )
                                            : '0.00'
                                    : '0.00'}
                                </span>
                              </li>
                            </ul>
                          }
                        </div> */}
                      </div >
                      <div className="d-flex collection-creator justify-content-between ">
                        <div className='creator mb-2'>
                          <h6>Creator</h6>
                          {nft?.collection?.royalty && <h6>Creator - {nft?.collection?.royalty}% royalties</h6>}
                          <div className='mini-collection-image'>
                            <Link to={`/artist/${nft.creator?.username}`}>
                              <img className='mini-coolection-img'
                                src={
                                  nft.creator?.profilePhoto ||
                                  userDefaultImg
                                }
                                alt="Creator Avatar"
                              />
                              <span>{nft?.creator?.username}</span>
                              {nft?.creator?.adminVerified && <span className='item-banner-check ml-1'><img className="img-fluid" src={checked} /></span>}
                            </Link>
                          </div>
                        </div>
                        <div className='collection mb-2'>
                          <h6>Collection</h6>
                          <div className='mini-collection-image'>
                            <Link to={`/collection/${nft.collection?.url}`}>
                              <img
                              className='mini-coolection-img'
                                src={
                                  nft.collection?.image ||
                                  collectionFeaturedImg
                                }
                                alt="img"
                              />
                              <span>{nft?.collection?.name} {""}</span>
                              {nft?.collection?.isVerified && <span className='item-banner-check  ml-1'><img className="img-fluid" src={checked} /></span>}

                            </Link>

                          </div>
                        </div>
                      </div>
                      {
                        nft.sellingMethod == 2 && <div className='remaining-time'>
                          {nft.auctionEndDate &&
                            new Date() <
                            Date.parse(nft.auctionEndDate) + 10000 && (
                              <div className="card no-hover countdown-times">
                                <p>Sale ends {moment(Date.parse(nft.auctionEndDate)).format("dddd, MMMM Do YYYY, h:mm:ss a")}
                                </p>
                                <span>Time Remaining</span>
                                <Countdown
                                  date={Date.parse(nft.auctionEndDate) + 10000}
                                  renderer={countDownRenderer}
                                />
                              </div>
                            )}
                        </div>
                      }
                      {
                        nft?.sellingMethod &&
                        <div className='current-price'>
                          <p>Current Price</p>
                          <div className='d-flex price align-items-center mb-3'>
                            <img
                              // src={arrowImage}
                              src={blockChainImages[nft?.blockChain]}
                              className="img-fluid" alt="image" />
                            {/* <img src={arrowImage} className="img-fluid" alt="image" /> */}
                            {/* <div><span className='amount'>0.019</span></div> */}
                            {console.log("nft?.sellingConfig", nft?.sellingConfig.price)}
                            {
                              nft?.sellingMethod === 1 ?
                                <span className='pl-3 pr-3 flex-grow-1'>{nft?.sellingConfig?.price?.amount}</span> :
                                <span className='pl-3 '>{nft?.sellingConfig?.startPrice?.amount}</span>
                            }
                            <div>
                              <span>
                                {' '}
                                ${''}
                                {' '}
                                {
                                  nft?.sellingMethod === 1 ?
                                    getPrice(
                                      nft?.sellingConfig?.price?.currency,
                                      nft?.sellingConfig?.price?.amount,
                                      nft?.sellingConfig?.quantity
                                    )
                                    :
                                    getPrice(
                                      nft?.sellingConfig?.startPrice?.currency,
                                      nft?.sellingConfig?.startPrice?.amount,
                                      nft?.sellingConfig?.quantity
                                    )
                                }
                              </span>
                              {/* <span className='usd'>($23.79 USD)</span> */}
                            </div>
                          </div>
                        </div>
                      }

                      {/* Sell Button */}
                      {
                        nft.showSell &&
                        <Link className="btn btn-danger offer mr-3"
                          to={`/sell-item/${window.btoa(nft._id)}`}
                        >
                          <span className="d-block transition">{initData.sellBtn}</span>
                        </Link>
                      }
                      {/* Cancel Listing Button */}
                      {
                        isOwner && !nft.showSell && nft.status === 2 &&
                        <button
                          className="btn btn-danger offer"
                          onClick={() => cancelListing(listings?.length > 0 && listings[0])}
                        >
                          <span className="d-block transition">Cancel Listing</span>
                        </button>
                      }
                      {
                        (listings[0]?.seller?._id !== userId && nft?.status === 2 && isAuctionStarted && !isAuctionPast) && (
                          <>
                            <button className="btn btn-danger offer " onClick={openModal}>
                              <span className="d-block transition">
                                {nft.sellingMethod === 2
                                  ? initData.bidBtn
                                  : initData.offerBtn}
                              </span>
                            </button>
                            {
                              nft.sellingMethod === 1 && (
                                <button id="but_now_btn"
                                  className="btn btn-danger offer"
                                  onClick={() => directBuy()}
                                >
                                  {initData.buyNow}  ({(nft.sellingConfig.price.amount)} {nft.sellingConfig.price.currency})
                                </button>
                              )
                            }
                          </>
                        )
                      }
                      {
                        // isOwner &&
                        // <>
                        //   {
                        //     nft.tokenStandard === 2 &&
                        //     <form id="transferNFTForm" onSubmit={transferNFT}>
                        //       <div>
                        //         <input type="text" id="transfer-nft-address" name="transfer-nft-address" value={transferToAddress} onChange={({ target: { value } }) => setTransferToAddress(value)} required className='' placeholder="Trasfer to..." aria-label="Trasfer to..." />
                        //       </div>
                        //       <div>
                        //         <input type="text" id="transfer-copies" name="transfer-copies" value={transferToCopies} onChange={({ target: { value } }) => setTransferToCopies(value)} required className='' placeholder="No. of Copies..." aria-label="No. of Copies..." />
                        //       </div>
                        //       <button
                        //         className="btn btn-danger offer ml-2 mr-2"
                        //         type="submit" form="transferNFTForm"
                        //         onClick={!intiatedTransfering ? () => setIntiatedTransfering(true) : (null)}
                        //       >
                        //         Transfer
                        //       </button>

                        //     </form>
                        //   }

                        // </>
                      }
                      <ul
                        className="netstorm-tab nav nav-tabs mt-4"
                        id="nav-tab"
                      >
                        <li>
                          <a
                            className={
                              rendType === 5 ? 'active' : ''
                            }
                            id="details-tab"
                            data-toggle="pill"
                            href="#details"
                            onClick={() => {
                              setRendType(5)
                            }}
                          >
                            <h5 className="m-0">Details</h5>
                          </a>
                        </li>
                        <li>
                          <a
                            className={
                              rendType === 6 ? 'active' : ''
                            }
                            id="attr-tab"
                            data-toggle="pill"
                            href="#attr"
                            onClick={() => {
                              setRendType(6)
                            }}
                          >
                            <h5 className="m-0">Properties</h5>
                          </a>
                        </li>
                        <li>
                          <a
                            className={
                              rendType === 4 ? 'active' : ''
                            }
                            id="history-tab"
                            data-toggle="pill"
                            href="#history"
                            onClick={() => {
                              setRendType(4)
                              setPage(2)
                              setMoreCheck(true);
                            }}
                          >
                            <h5 className="m-0">History</h5>
                          </a>
                        </li>
                        <li>
                          <a
                            className={
                              rendType === 3 ? 'active' : ''
                            }
                            id="history-tab"
                            data-toggle="pill"
                            href="#history"
                            onClick={() => {
                              setRendType(3)
                              setPage(2)
                              setMoreCheck(true);
                            }}
                          >
                            <h5 className="m-0">Listings</h5>
                          </a>
                        </li>
                        <li>
                          <a
                            className={
                              rendType === 2 ? 'active' : ''
                            }
                            id="offers-tab"
                            data-toggle="pill"
                            href="#offers"
                            onClick={() => {
                              setRendType(2)
                              setPage(2)
                              setMoreCheck(true)
                            }}
                          >
                            <h5 className="m-0">Offers</h5>
                          </a>
                        </li>
                        <li>
                          <a
                            className={
                              rendType === 1 ? 'active' : ''
                            }
                            id="bids-tab"
                            data-toggle="pill"
                            href="#bids"
                            onClick={() => {
                              setRendType(1)
                              setPage(2)
                              setMoreCheck(true)
                            }}
                          >
                            <h5 className="m-0">Bids</h5>
                          </a>
                        </li>
                        <li>
                          <a
                            className={
                              rendType === 7 ? 'active' : ''
                            }
                            id="bids-tab"
                            data-toggle="pill"
                            href="#bids"
                            onClick={() => {
                              setRendType(7)
                            }}
                          >
                            <h5 className="m-0">Related</h5>
                          </a>
                        </li>
                      </ul>
                      {/* Tab Content */}
                      <div className="tab-content" id="nav-tabContent">
                        {rendContent()}
                      </div>
                      <div className="content mt-5 mt-lg-0 ">
                        <div className="d-flex justify-content-start align-items-center mb-4">
                          {/* <h3 className="m-0">{nft.name}</h3> */}
                          {nft.isCustom && (
                            <span
                              className="btn-refresh cursor-pointer"
                              onClick={() => refreshMetadata()}
                              data-effect="float"
                              data-tip="Refresh Metadata"
                            >
                              <FontAwesomeIcon icon={faUndo} />
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div >
              </div >
            )
            }
          </>
        )}
      </section >
      {/* place a bid / make an offer modal */}
      {
        nft &&
        <div className="modal-wrapper">
          <Modal
            centered
            size="lg"
            className="offer-modal"
            show={isOpen}
            onHide={closeModal}
          >
            <div className='offer-modal-style'>
        
            <Modal.Header className="text-center modal-title-wrapper">
              <Modal.Title>
                {nft.sellingMethod === 2 ? 'Place A Bid' : 'Make An Offer'}
              </Modal.Title>
            </Modal.Header>
            {isSubmitted && errors && (
              <Modal.Body className="row pt-2 pb-0">
                <div className="col-12">
                  <span id="nft-err" className="form-error-msg text-danger">
                    {errors}
                  </span>
                </div>
              </Modal.Body>
            )}
            {
              itemToBuy?.quantity > 1 &&
              <Modal.Body>
                <div className="price-wrapper d-flex">
                  <div className="price-text position-relative">
                    <div className="mb-2 absolute-wrapper">
                      <b>Quantity <span className='text-danger'>*</span></b>
                    </div>
                    <div className="bid-main-tag">
                      <input
                        type="text"
                        placeholder="Quantity"
                        className="amount-btn"
                        name="nftConfig.quantity"
                        style={{ borderRadius: '4px' }}
                        onChange={(e) => onChange(e)}
                        onKeyDown={(e) => integerNumberValidator(e)}
                        defaultValue={nftConfig.quantity}
                        required
                      />
                      <span className="text-danger message d-block mt-2">
                        {validator.message(
                          'amount',
                          nftConfig.quantity,
                          'required',
                        )}
                      </span>
                    </div>
                    {
                      nftConfig.quantity && quantityMsg && (
                        <p className="text-danger">{quantityMsg}</p>
                      )
                    }
                  </div>
                </div>
              </Modal.Body>
            }
            <Modal.Body>
              <div className="price-wrapper d-flex">
                <div className="price-text position-relative">
                  <div className="text-white mb-2 absolute-wrapper">
                    <b>Price <span className='text-danger'>*</span></b>
                  </div>
                  <div
                    className="text-right mb-2 cursor-pointer"
                    data-effect="float"
                    data-tip="Price"
                  >
                    {/* <i className="fas fa-exclamation-circle ml-2" /> */}
                  </div>
                  <div className="d-flex bid-main-tag">
                    <select
                      className="form-control"
                      id="currency"
                      name="nftConfig.price.currency"
                      value={nftConfig.price.currency}
                      disabled={+true}
                    >
                      {currencies &&
                        currencies.map((currency, index) => {
                          return (
                            <option
                              key={index}
                              value={currency.symbol}
                              caddress={currency.address}
                            >
                              {currency.symbol}
                            </option>
                          )
                        })}
                    </select>
                    <div className="bid-amount">
                      <input
                        type="text"
                        placeholder="Amount"
                        className="amount-btn"
                        name="nftConfig.price.amount"
                        style={{ borderRadius: '4px' }}
                        onChange={(e) => onChange(e)}
                        onKeyDown={(e) => decimalNumberValidator(e)}
                        defaultValue={nftConfig.price.amount}
                        required
                      />
                      <div className="text-right mb-2 dollar-wrapper">
                        {
                          itemToBuy?.quantity > 1 &&
                          <span>
                            Total Offer Amount : {nftConfig.quantity * nftConfig.price.amount}&nbsp;{nftConfig.price.currency}&nbsp;{'('}
                          </span>
                        }
                        ${
                          getPrice(
                            nftConfig.price.currency,
                            nftConfig.price.amount,
                            nftConfig.quantity
                          )
                        }
                        {
                          withCopies &&
                          <span>{')'}</span>
                        }
                      </div>
                      <span className="text-danger message d-block mt-2">
                        {validator.message(
                          'amount',
                          nftConfig.price.amount,
                          'required',
                        )}
                      </span>
                    </div>
                  </div>
                  {minPriceError && (
                    <p className="text-danger">{minPriceError}</p>
                  )}
                </div>
              </div>
            </Modal.Body>
            <Modal.Body className="text-danger">
              {validator.message(
                'amount',
                nftConfig.price.amount,
                'required',
              )}
            </Modal.Body>
            <Modal.Body
              className=""
              style={{
                flexDirection: 'column',
                display: 'block',
                height: 'auto',
              }}
            >
              <div className="offer-expiration-wrapper text-white mb-2">
                <b>Expiration <span className='text-danger'>*</span></b>
              </div>
              <div>
                <div className="d-flex">
                  <select
                    className="form-control"
                    id="expiryDate"
                    name="nftConfig.expiry.date"
                    onChange={(e) => onChange(e)}
                  >
                    {expiryOptions &&
                      expiryOptions.map((expiry, index) => {
                        return (
                          <option key={index} value={expiry.value}>
                            {expiry.label}
                          </option>
                        )
                      })}
                  </select>

                  <div className="mr-auto budle-wrapper">
                    <input
                      type={
                        nftConfig.expiry.type === 1
                          ? 'time'
                          : 'datetime-local'
                      }
                      min={new Date().toISOString().split('T')[0]}
                      name="nftConfig.expiry.time"
                      value={nftConfig.expiry.time}
                      onChange={(e) => onChange(e)}
                      required
                    />
                  </div>
                  <span className="text-danger message">
                    {validator.message(
                      'expiryTime',
                      nftConfig.expiry.time,
                      'required',
                    )}
                  </span>
                </div>
              </div>
            </Modal.Body>
            <Modal.Body className="justify-content-center align-items-center">
              <div className="d-flex justify-content-around">
                <button
                  className="btn btn-danger offer mr-3 mt-5 mb-3"
                  disabled={+loader}
                  onClick={() => submit()}
                >
                  {nft.sellingMethod === 2
                    ? initData.bidBtn
                    : initData.offerBtn}
                </button>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button
                className="btn banner-btn btn-danger offer"
                onClick={closeModal}
              >
                Close
              </button>
            </Modal.Footer>


            </div>
          </Modal>
        </div>
      }
      {/* owners NFT modal */}
      {
        ownersModal &&
        <div className="modal-wrapper">
          <Modal
            centered
            size="lg"
            className="report-modal owner-modal"
            show={ownersModal}
            onHide={() => setOwnersModal(!ownersModal)}
          >
            <Modal.Header className="text-center  modal-title-wrapper contest-modal">
              <Modal.Title>
                Owned By
                <span className="cross-icon"><FontAwesomeIcon icon={faXmark} className="cross-icon-in" onClick={() => setOwnersModal(!ownersModal)} /></span>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>


              {
                <ul className='list-unstyled'>
                  {
                    nft.owners?.list?.length > 0 ?
                      nft.owners?.list.map((item, idx) => {
                        return (
                          <div className='responsive-owner-modal'>
                            <li className='mb-4' key={`owner-${idx}`}>
                              <div className="owner-meta  mb-2">
                                <div className='owner-data d-flex align-items-center justify-content-between '>
                                  <Link className="owner " to={`/artist/${item.owner.username}`} >
                                    <img src={userDefaultImg} className="img-fluid" alt={`owner-photo-${idx}`} />
                                  </Link>
                                  <div className='owner-detail d-flex ml-3'>
                                    <span>
                                      <Link className=" " to={`/artist/${item.owner.username}`}>
                                        {item.owner.username}
                                      </Link>
                                    </span>
                                    <span className='ml-3' data-effect="float" data-tip={item.owner.address}>{item.owner.address}</span>
                                    <ReactTooltip className='owner-tooltip' />
                                  </div>
                                  <div className='total-items ml-3'>
                                    <span className=' align-items-center'>
                                      <span className='copies-total'>{item.copies}</span>
                                      <span className="items-tag">Item{item.copies > 1 && 's'}</span>
                                    </span>
                                  </div>
                                </div>

                              </div>
                            </li>
                          </div>
                        )
                      })
                      :
                      <li>No Owners Found</li>
                  }
                </ul>

              }


            </Modal.Body>
            <Modal.Footer>
              <button
                className="btn btn-danger delete-btn"
                onClick={() => setOwnersModal(!ownersModal)}
              >
                Close
              </button>
            </Modal.Footer>
          </Modal>
        </div>
      }
      {/* buy NFT / quantity modal */}
      {
        quantityModal && nft && itemToBuy &&
        <div className="modal-wrapper">
          <Modal
            centered
            size="lg"
            className="quantity-modal"
            show={quantityModal}
            onHide={toggleQuantityModal}
          >
            <Modal.Header className="text-center modal-title-wrapper contest-modal">
              <Modal.Title>
                Complete Checkout
                <span className="cross-icon"><FontAwesomeIcon icon={faXmark} className="cross-icon-in" onClick={toggleQuantityModal} /></span>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className=" pt-2 pb-0">
              {
                isSubmitted && errors && (
                  <Modal.Body className=" pt-0 px-0">

                    <span id="nft-err" className="form-error-msg text-danger">
                      {errors}
                    </span>
                  </Modal.Body>
                )
              }
              <div className='table-card m-1'>
                <div className='table-responsive'>
                  <Table className='table table-dark ranking-table complete-checkout-table'>
                    <thead>
                      <tr>
                        <th className='td-cc-item'>Item</th>
                        <th className='td-cc-price'>Price</th>
                        {
                          itemToBuy?.quantity > 1 &&
                          <>
                            <th className='td-cc-quantity'>Quantity</th>
                            <th className='td-cc-total'>Total</th>
                          </>
                        }
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className='td-up td-cc-item'>
                          <div className='up-div'>
                            <LazyLoadImage id="buy-nft-image" className='td-up-img' src={ipfsToUrl(nft?.image)} alt="buy NFT img" />
                            <div className="d-flex flex-column td-up-content">
                              <b className='currency-price'>{nft?.name}</b>
                              <Link
                                className="seller"
                                to={`/collection/${nft.collection?.url}`}
                                data-effect="float"
                                data-tip={nft.collection?.name}
                              >{nft.collection?.name}</Link>
                            </div>
                          </div>
                        </td>
                        <td className='td-up td-cc-price'>
                          <div className='up-div'>
                            {/* COMMENTED TEMP */}
                            {/* <LazyLoadImage alt="1-_icon" src={itemToBuy.currency === 'BNB' ? bnbIcon : itemToBuy.currency === 'MYNT' ? myntIcon : ''} /> */}
                            <b className='ms-2 currency-price'>{itemToBuy.currentPrice}</b>
                            <span className='ms-2'>{`($${itemToBuy.priceInUSD})`}</span>
                          </div>
                        </td>
                        {
                          withCopies &&
                          <>
                            <td className='td-quantity td-cc-quantity'>
                              <div className="form-group">
                                <input type="text" className="" name="qtyToBuy" placeholder={itemToBuy?.quantity} onChange={(e) => onTextualChange(e)} value={qtyToBuy} onKeyDown={(e) => integerNumberValidator(e)} />
                                <span className="text-danger message">
                                  {validator.message('qtyToBuy', qtyToBuy, 'required')}
                                </span>
                              </div>
                            </td>
                            <td className='td-up td-cc-total'>
                              <div className='up-div'>
                                {/* COMMENTED TEMP */}
                                {/* <LazyLoadImage alt="token_icon" src={itemToBuy.currency === 'BNB' ? bnbIcon : itemToBuy.currency === 'MYNT' ? myntIcon : ''} /> */}
                                <b className='ms-2 currency-price'>{parseFloat((itemToBuy.currentPrice * (qtyToBuy || 0)).toFixed(10))}</b>
                                <span className='ms-2'>{`($${!qtyToBuy || !itemToBuy.priceInUSD ? '0.00' : parseFloat((itemToBuy.priceInUSD * (qtyToBuy)).toFixed(10))})`}
                                </span>
                              </div>
                            </td>
                          </>
                        }
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </div>

            </Modal.Body>
            <Modal.Footer>

              <button className="btn btn-danger offer" onClick={() => directBuy(false)}>
                <span className="d-block transition">Buy</span>
              </button>
            </Modal.Footer>
          </Modal>
        </div>
      }
    </>
  )

}

const mapStateToProps = (state) => ({
  nft: state.nft,
  error: state.error,
  offer: state.offer,
  bid: state.bid,
  listings: state.listings,
  app: state.app,
  nftHistory: state.nftHistory,
  transferedNFT: state.nft.transferedNFT,
  isFavourite: state.nft.isFavourite,
  likesCount: state.nft.likesCount
});

export default connect(mapStateToProps, {
  favoriteToggle,
  beforeNFT,
  getNFT,
  beforeOffer,
  makeOffer,
  getOffers,
  deleteOffer,
  beforeBid,
  placeBid,
  getBids,
  deleteBid,
  acceptOffer,
  acceptBid,
  getListings,
  beforeHistory,
  getHistory,
  refreshMetadata,
  beforeMetadata,
  beforeCancelListing,
  beforeBuy,
  buyNFT,
  cancelListing,
  beforeListing,
  transferNft
})(ItemDetails)